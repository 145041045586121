import React from 'react';
import { Button, PopoverTrigger } from '@chakra-ui/react';

const CustomPopoverTrigger = ({ isOpen, children }) => {
  return (
    isOpen ? (
      <>
        <PopoverTrigger>
          <Button sx={{ display: 'hidden', width: 0, height: 0 }}>
            .
          </Button>
        </PopoverTrigger>
        {children}
      </>
    )
      : (
        <PopoverTrigger>
          {children}
        </PopoverTrigger>
      )
  );
};

export default CustomPopoverTrigger;
