import apiService from '../apiService';

export const updatesAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllUpdates: builder.query({
      query: (paramsObj) => ({
        url: 'updates/v1',
        params: paramsObj,
      }),
      providesTags: ['updates'],
    }),
    fetchNotifications: builder.query({
      query: (paramsObj) => ({
        url: 'updates/v1/notifications',
        params: paramsObj,
      }),
      providesTags: ['all-notifications'],
    }),
    createNotification: builder.mutation({
      query: (newNotificationData) => ({
        url: 'updates/v1/notifications',
        method: 'POST',
        body: newNotificationData,
      }),
      invalidatesTags: ['all-notifications'],
    }),
    deleteNotification: builder.mutation({
      query: (notification_id) => ({
        url: `updates/v1/notifications/${notification_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['all-notifications'],
    }),
    fetchSubscriptions: builder.query({
      query: () => ({
        url: 'updates/v1/notifications/subscriptions',
      }),
      providesTags: ['subscriptions'],
    }),
    updateSubscriptions: builder.mutation({
      query: (paramsObj) => ({
        url: 'updates/v1/notifications/subscriptions',
        method: 'POST',
        params: paramsObj,
      }),
      invalidatesTags: ['subscriptions'],
    }),
  }),
});

export const {
  useFetchAllUpdatesQuery,
  useFetchNotificationsQuery,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useFetchSubscriptionsQuery,
  useUpdateSubscriptionsMutation,
} = updatesAPI;
