import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import {
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {
  Heading,
  Flex,
  VStack,
} from '@chakra-ui/react';
import customTheme from '../styles/chakra_ui_theme';
import { useGetUserQuery, useUpdateUserMutation } from '../services/apis/usersAPI';
import ExchangeLogo from '../assets/TheExchange-Logo_Vertical_Full Color.svg';
import { PushPinIcon } from '../icons';

const TourHeadline = (props) => {
  const { text } = props;
  return (
    <Heading
      as="h1"
      style={{ ...customTheme.styles.global.h3, marginTop: '8px', marginBottom: '12px' }}
      color={customTheme.colors.blue['800']}
    >
      {text}
    </Heading>
  );
};

const TourText = (props) => {
  const { text, textAlign = 'center' } = props;
  return (
    <p
      style={{
        color: customTheme.colors.blue['800'],
        textAlign,
      }}
    >
      {text}
    </p>
  );
};

const TourPin = () => (
  <PushPinIcon
    fontSize="small"
    sx={{
      color: customTheme.colors.blue[500],
      marginRight: '4px',
    }}
  />
);

const customStyles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: customTheme.colors.blue['500'],
    textColor: customTheme.colors.blue['800'],
    overlayColor: 'rgba(0, 0, 0, 0.4)',
  },
  buttonSkip: {
    position: 'absolute',
    bottom: '15px',
    right: '120px',
  },
};

const SiteTour = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const startSiteTour = searchParams.get('startSiteTour');
  const stepOneURL = `${location.pathname}${location.search}`;
  const { data: userData, isLoading } = useGetUserQuery();
  const [runJoyRide, setRunJoyRide] = useState(false);
  const [updateUser] = useUpdateUserMutation();

  const [run, setRun] = useState(startSiteTour);

  useEffect(() => {
    const isLatestGuidelinesAccepted = userData?.data?.latest_guidelines_status === 'accepted';
    if (userData?.data?.email
      && !userData?.data?.site_tour_completed
      && isLatestGuidelinesAccepted) {
      setRunJoyRide(true);
    }
  }, [userData]);

  const steps = [
    {
      target: '#root',
      disableBeacon: true,
      placement: 'center',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <Heading
            as="h1"
            style={{ ...customTheme.styles.global.h3, marginTop: '8px', marginBottom: '12px' }}
            color={customTheme.colors.blue['800']}
          >
            Welcome to the
          </Heading>
          <Flex justify="center" mb="8">
            <img
              alt="Logo"
              src={ExchangeLogo}
              style={{
                height: 'auto', width: '100%', display: 'block',
              }}
            />
          </Flex>
          <TourText text="Learn more about our online community." />
        </div>
      ),
    },
    {
      target: '#root',
      disableBeacon: true,
      placement: 'center',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <TourHeadline text="Explore your dashboard" />
          <VStack spacing="0.5em">
            <TourText text="Customize this space with your most used apps, and relevant groups and trainings. You can “pin” your favorites to this dashboard to make sure you see them here first. As you pin groups and trainings, the latest activity for each will appear here." />
            <Flex>
              <TourPin />
              <TourText
                text="Click the pin icon next to an app, group, or training to pin or unpin it."
                textAlign="left"
              />
            </Flex>
          </VStack>
        </div>
      ),
    },
    {
      target: '#LeftNavBar',
      disableBeacon: true,
      placement: 'right-start',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <TourHeadline text="Navigate the community using the convenient side menu" />
          <VStack spacing="0.5em">
            <TourText text="Find forum groups, trainings, and more using the icons located in the left side menu. Click the base of the side menu to reveal labels for each icon." />
          </VStack>
        </div>
      ),
    },
    {
      target: '#root',
      disableBeacon: true,
      placement: 'center',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <TourHeadline text="Join the Discussion" />
          <VStack spacing="0.5em">
            <TourText text="Have a question or a new idea to share? Start a conversation with your fellow educators!" />
            <TourText text="Anyone can read and reply to a group discussion. This is a great way to share ideas with your community." />
          </VStack>
        </div>
      ),
    },
    {
      target: '#MyGroups',
      disableBeacon: true,
      placement: 'center',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <VStack spacing="0.5em">
            <TourText text="At the top of your dashboard, you’ll find the Groups you’ve pinned, along with a section for
            Group Updates, where you can easily track new comments on the discussions you’re following."
            />
          </VStack>
        </div>
      ),
    },
    {
      target: '#AllGroups',
      disableBeacon: true,
      placement: 'right',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <VStack spacing="0.5em">
            <TourText text="Groups that you have not pinned appear here. Browse to find an interesting discussion, or use the
            search icon in the navigation bar to find a specific group. When you find a discussion you want to follow, don’t
            forget to pin it so it’s easy to find later! Just click the pin icon."
            />
          </VStack>
        </div>
      ),
    },
    {
      target: '#root',
      disableBeacon: true,
      placement: 'center',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <TourHeadline text="Engage with Exchange Trainings" />
          <VStack spacing="0.5em">
            <TourText text="Find a training that interests you and pin it so you can find the training – and any related updates –
            at the top of your dashboard."
            />
          </VStack>
        </div>
      ),
    },
    {
      target: '#root',
      disableBeacon: true,
      placement: 'center',
      content: (
        <div style={{ marginBottom: '16px' }}>
          <TourHeadline text="Lead the way with a better approach to data" />
          <TourText text="You’re now ready to make an impact today with The Texas Education Exchange. " />
          <TourText text="You can come back to this tour at any time by selecting “Site Tour” in the menu on the left under Extras. " />
        </div>
      ),
    },
  ];

  const clearSearchParams = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('startSiteTour');
    params.delete('refreshTour');
    setSearchParams(params);
  };

  const handleCallback = (siteTourCallbackData) => {
    const {
      action,
      step,
      index,
      type,
    } = siteTourCallbackData;
    const paramsObj = { site_tour_completed: true };

    if (action === 'close') {
      setRun(false);
      clearSearchParams();
      const user_id = userData?.data?._id;
      if (user_id) {
        updateUser({ user_id, paramsObj });
      }
      navigate('/dashboard');
    } else if (type === 'tour:end') {
      const user_id = userData?.data?._id;
      clearSearchParams();
      if (user_id) {
        updateUser({ user_id, paramsObj });
      }
      navigate('/dashboard');
    } else if (type === 'step:after' && index === 0) {
      navigate(stepOneURL);
    } else if (type === 'step:before' && index === 1) {
      navigate(stepOneURL);
    } else if (index === 3) {
      navigate(`/forum${location.search}`);
    } else if (type === 'step:after' && step.target === '#AllGroups') {
      navigate(`/training${location.search}`);
    }
  };

  return (
    <Joyride
      continuous
      run={(run || runJoyRide) && !isLoading}
      callback={handleCallback}
      disableScrolling
      disableOverlayClose
      disableScrollParentFix
      /*
         Hitting back will crash the app sometimes because Joyride will look for the element
         before it has mounted, and it doesn't handle that. The kludge to fix it is just to
         not have the back button. -JJ
      */
      hideCloseButton
      hideBackButton
      showProgress
      showSkipButton
      steps={steps}
      styles={customStyles}
    />
  );
};

export default SiteTour;
