import React from 'react';
import {
  Box,
  Text,
  Flex,
  Stack,
  useTheme,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { getFormattedNotificationTime } from '../../utils/helpers';
import CreateNotificationModal from '../Modals/CreateNotificationModal';
import DeleteModal from '../Modals/DeleteModal';

const NotificationDetail = (props) => {
  const { text1, text2 } = props;
  return (
    <Text variant="author" fontSize="0.875rem">
      <b>
        {text1}
        :
      </b>
      {' '}
      {text2}
    </Text>
  );
};

const NotificationCard = ({
  message,
  status,
  channel,
  scheduled_at,
  description,
  notif_id,
}) => {
  const theme = useTheme();
  const borderColor = status === 'sent' ? theme.colors.orange[500] : theme.colors.green[500];
  const statusText = status === 'sent' ? 'Sent' : 'To Be Sent';

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        borderLeft: `6px solid ${borderColor}`,
        background: '#fff',
      }}
      p={4}
      pl={6}
      mb={4}
    >
      <Flex justifyContent="center" gap={6}>
        <Stack gap="0.5rem" flexGrow={1}>
          {channel === 'email' && <Text fontWeight="700">{parse(message || 'No message')}</Text>}
          <Text className="notifDescription">{parse(channel === 'email' ? description : message || 'No message')}</Text>
        </Stack>
        <Stack gap="0.5rem" style={{ minWidth: '200px' }}>
          <NotificationDetail
            text1={statusText}
            text2={getFormattedNotificationTime(scheduled_at)}
          />
          <NotificationDetail
            text1="Channel"
            text2={channel}
          />
        </Stack>
      </Flex>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={4}>
        {status !== 'sent' && (
        <DeleteModal
          itemId={notif_id}
          deleteType="notification"
          customBtnStyle={{
            width: '108px',
            marginRight: '0.5rem',
            color: `${theme.colors.red[600]}`,
            border: `1px solid ${theme.colors.red[600]}`,
          }}
          size="sm"
          variant="none"
        />
        )}
        <CreateNotificationModal
          notifBtnText="Duplicate"
          notifChannel={channel}
          notifDescription={description}
          notifTitle={message}
          customBtnStyle={{
            color: theme.colors.blue[500],
            backgroundColor: 'white',
            fontWeight: 600,
            fontSize: '1rem',
          }}
        />
      </Box>
    </Box>
  );
};

export default NotificationCard;
