import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { Text, VStack, useTheme } from '@chakra-ui/react';
import moment from 'moment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const MomentDatePicker = ({
  label, value, onChange, minDate, maxDate, style = {
    '& input': {
      height: '12px',
      width: '150px',
    },
  },
}) => {
  const muiTheme = createTheme();
  const theme = useTheme();
  return (
    <VStack align="start" pr={4}>
      <Text
        style={{ ...theme.styles.global.body }}
      >
        {label}

      </Text>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={muiTheme}>
          <DatePicker
            value={value}
            onChange={onChange}
            minDate={minDate && moment(minDate)}
            maxDate={maxDate && moment(maxDate)}
            sx={style}
            slotProps={{
              popper: {
                sx: { zIndex: 1500 },
              },
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </VStack>
  );
};

export default MomentDatePicker;
