/* eslint-disable no-unused-vars */
import React from 'react';
import {
  VStack, useTheme, Box, Flex, Text, HStack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import pluralize from 'pluralize';
import moment from 'moment';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import { ThumbUpOffAltIcon, ThumbUpAltIcon } from '../../icons/index';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import { useReactToEventMutation } from '../../services/apis/eventsAPI';
import { joinTruthyStrings } from '../../utils/helpers';
import ReactionIconComponent from '../ElementaryComponents/ReactionIconComponent';

const EventsCard = ({
  eventsData = [], noDataMessage,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const accessibilityKeyHandler = useAccessibleNavigation();
  const displayedEventsCount = eventsData?.length;

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;

  const [reactToEvent] = useReactToEventMutation();

  return (
    eventsData.length === 0 ? <DataUnavailable display_message={noDataMessage} />
      : (
        <VStack
          style={{
            ...theme.styles.global.container,
          }}
          minHeight="4rem"
          padding="1rem"
          alignSelf="flex-end"
          spacing={2}
          alignItems="flex-start"
        >
          {
            eventsData?.map((eventData, index) => {
              const {
                author_body = {},
                author_id,
                start_date,
                end_date,
                location = {},
                title,
                _id,
                like_count,
                pin,
                pin_id = '',
                tags,
                update,
                reactions,
                created_at,
                event_host,
              } = eventData;

              const formatTimeDifference = (timestamp) => {
                const now = moment();
                const then = moment(timestamp);
                const diffMinutes = then.diff(now, 'minutes');
                const diffHours = then.diff(now, 'hours');
                const diffDays = then.diff(now, 'days');
                const diffWeeks = then.diff(now, 'weeks');
                const diffMonths = then.diff(now, 'months');
                const diffYears = then.diff(now, 'years');

                if (diffYears > 0) return `in ${diffYears} ${pluralize('year', diffYears)}`;
                if (diffMonths > 0) return `in ${diffMonths} ${pluralize('month', diffMonths)}`;
                if (diffWeeks > 0) return `in ${diffWeeks} ${pluralize('week', diffWeeks)}`;
                if (diffDays > 0) return `in ${diffDays} ${pluralize('day', diffDays)}`;
                if (diffHours > 0) return `in ${diffHours} ${pluralize('hour', diffHours)}`;
                if (diffMinutes > 0) return `in ${diffMinutes} minutes`;
                if (diffYears < 0) return `${Math.abs(diffYears)} ${pluralize('year', diffYears)} ago`;
                if (diffMonths < 0) return `${Math.abs(diffMonths)} ${pluralize('month', diffMonths)} ago`;
                if (diffWeeks < 0) return `${Math.abs(diffWeeks)} ${pluralize('week', diffWeeks)} ago`;
                if (diffDays < 0) return `${Math.abs(diffDays)} ${pluralize('day', diffDays)} ago`;
                if (diffHours < 0) return `${Math.abs(diffHours)} ${pluralize('hour', diffHours)} ago`;
                if (diffMinutes < 0) return `${Math.abs(diffMinutes)} ${pluralize('minute', diffMinutes)} ago`;
                return ('now');
              };

              const formattedTimeUntil = formatTimeDifference(start_date);

              const alreadyLiked = reactions?.like?.users.includes(loggedInUserId);

              const handleEventReaction = (event) => {
                event.stopPropagation();
                try {
                  reactToEvent({ event_id: _id, user_id: loggedInUserId, reaction_type: 'like' }).unwrap();
                } catch (err) {
                  console.error('Error reacting to event:', err);
                }
              };

              const currentYear = moment().year();

              const isCurrentYear = moment(start_date).year() === currentYear;

              const formattedStartDate = isCurrentYear ? moment(start_date).format('MMM Do, h:mmA') : moment(start_date).format('MMM Do, YYYY, h:mmA');

              const getEventDuration = (startDate, endDate) => {
                const start = moment(startDate);
                const end = moment(endDate);
                const duration = moment.duration(end.diff(start));

                const days = duration.days();
                const hours = duration.hours();
                const minutes = duration.minutes();

                let eventDuration = '';
                if (days) {
                  eventDuration = `${days}d`;
                  if (hours) {
                    eventDuration += ` ${hours}${pluralize('hr', hours)}`;
                  }
                } else if (hours) {
                  eventDuration = `${hours}${pluralize('hr', hours)}`;
                  if (minutes) {
                    eventDuration += ` ${minutes}${pluralize('min', minutes)}`;
                  }
                } else if (minutes) {
                  eventDuration = `${minutes}${pluralize('min', minutes)}`;
                }

                return eventDuration.trim();
              };

              const eventDuration = getEventDuration(start_date, end_date);

              const { city = '', state = '' } = location;

              const eventCardStyle = index !== displayedEventsCount - 1 ? { borderBottom: '1px solid #CED8DD', marginBottom: '1rem', marginTop: 0 } : { marginTop: 0 };

              let authorName = joinTruthyStrings({
                strings: [author_body.first_name, author_body.last_name],
                connectingString: ' ',
              });
              if (!authorName) authorName = author_id;

              //   const { first_name, last_name } = author_body;
              // Create Pin Body
              const createPinBodyObj = {
                child_object_id: _id,
                child_object_type: 'event',
              };

              const navigatePath = `/events/${_id}`;
              //   const navigatePath = '/events';

              return (
                <Box
                  tabIndex={0}
                  role="link"
                  key={_id}
                  style={eventCardStyle}
                  p="0.5rem"
                  width="100%"
                  mt={0}
                  cursor="pointer"
                  onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
                  onClick={() => navigate(navigatePath)}
                >
                  <Flex justify="space-between" width="100%">
                    <Box>
                      <Text
                        style={{
                          ...theme.styles.global.h4,
                          display: 'inline-block',
                          fontWeight: '700',
                        }}
                        color="gray.800"
                      >
                        {parse(title)}
                      </Text>
                    </Box>
                    <Flex w="20em" justify="space-between" alignItems="center" justifyContent="flex-end">
                      <Text
                        style={{
                          ...theme.styles.global.h4,
                          display: 'inline-block',
                          fontWeight: '700',
                        }}
                        mr="2"
                      >
                        {formattedTimeUntil}

                      </Text>
                      <ReactionIconComponent
                        alreadyLiked={alreadyLiked}
                        handleClick={handleEventReaction}
                      />
                      <TogglePinIcon
                        is_pinned={pin}
                        create_pin_body_obj={createPinBodyObj}
                        pin_id={pin_id}
                      />
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    width="100%"
                    gap="2.5rem"
                  >
                    {/* <Text variant="author" fontSize="14px">
                      Author&nbsp;
                      <strong>
                        {[
                          first_name,
                          last_name,
                        ].filter((n) => n).join(' ')}
                      </strong>
                    </Text> */}
                    <Text
                      style={{ ...theme.styles.global.body }}
                    >
                      {joinTruthyStrings({ strings: [formattedStartDate, eventDuration] })}
                    </Text>
                    { (parse(city) || parse(state)) && (
                    <Text
                      style={{ ...theme.styles.global.body }}
                    >
                      {joinTruthyStrings({ strings: [parse(city), parse(state)] })}
                    </Text>
                    )}
                    { event_host && (
                    <HStack>
                      <Text
                        style={{ ...theme.styles.global.body }}
                      >
                        Host/Sponsor:
                        {' '}
                      </Text>
                      <Text
                        style={{ ...theme.styles.global.body, fontWeight: '400' }}
                      >
                        {parse(event_host)}
                      </Text>
                    </HStack>
                    )}
                  </Flex>
                  <Flex
                    width="100%"
                    alignItems="center"
                    gap="2.5rem"
                    mt="2"
                  >
                    <Text style={{
                      ...theme.styles.global.body2,
                      fontSize: 14,
                      fontFamily: 'archivo narrow',
                    }}
                    >
                      {`Created by ${parse(authorName)}`}

                    </Text>
                    <Text variant="details">
                      {pluralize('like', like_count, true)}
                    </Text>

                    <DisplayTags tags={tags} />
                    {update?.user_name && (
                    <UserAvatar
                      name={update?.user_name}
                      user_profile_pic=""
                      avatarSize="XS"
                    />
                    )}
                    {update?.user_name && (
                    <>
                      <Text fontWeight="700" mr="0.2rem">
                        {update.user_name}
                      </Text>
                      {update.action}
                      {' '}
                      {`“${update.content}“`}
                      <Text ml="0.5rem">{created_at}</Text>
                    </>
                    )}
                  </Flex>
                </Box>
              );
            })
      }
        </VStack>
      )
  );
};

export default EventsCard;
