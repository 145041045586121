import { extendTheme } from '@chakra-ui/react';
import {
  baseTheme,
} from '@texas-education-exchange/edx-portal-shared';

const createCustomTheme = () => {
  /*
     The themable components can be found in
     https://github.com/chakra-ui/chakra-ui/blob/bd3d0fd2f444be2ba23764d7c86906cb488fb409/packages/components/anatomy/src/components.ts#L71
  */

  /**
   * Color accessibility notes.
   *
   * Blue
   * 600: #1C3DAA // AAA for any size
   * 500: '#3D5EFC', // AA for any size, AAA for 18px+ or 14px+ bold
   * 400: '#4299FF', // here and below can only be used for decorative elements
   *
   * Red
   * 700: '#9C2405', // This is the only red allowed for small text or AAA
   * 600: '#DD3827', // AA for only 18px+ or 14px+ bold text
   * 500: '#FF3D00', // AA for only 18px+ or 14px+ bold text
   *
   * Gray
   * 500: '#455763', // AAA here and up
   * 400: '#849299', // AA for only 18px+ or 14px+ bold text
   * 300: '#CED8DD', // here and below can only be used for decorative elements
  */

  const buttonStyles = {
    // Importing the base Button styles here breaks a lot of things, so don't do it.
    variants: {
      ...baseTheme.components.Button.variants,
      solid: {
        ...baseTheme.components.Button.variants.solid,
        backgroundColor: 'white',
      },
      outline: {
        ...baseTheme.components.Button.variants.outline,
        borderColor: 'blue.500',
        color: 'blue.500',
      },
      primary: {
        ...baseTheme.components.Button.variants.primary,
        fontWeight: 600,
        color: '#fff',
        backgroundColor: 'blue.500',
      },
    },
  };

  const selectStyles = {
    ...baseTheme.components.Select,
    baseStyle: {
      ...baseTheme.components.Select.baseStyle,
      field: {
        ...baseTheme.components.Select.field,
        background: 'none',
      },
    },
    variants: {
      ...baseTheme.components.Select.variants,
      outline: {
        ...baseTheme.components.Select.variants.outline,
        field: {
          ...baseTheme.components.Select.variants.outline.field,
          borderColor: 'gray.300',
          _hover: {
            borderColor: 'gray.600',
          },
        },
      },
    },
  };

  const inputStyles = {
    ...baseTheme.components.Input,
    variants: {
      ...baseTheme.components.Input.variants,
      outline: {
        ...baseTheme.components.Input.variants.outline,
        field: {
          ...baseTheme.components.Input.variants.outline.field,
          background: '#fff',
          borderColor: 'gray.300',
          _hover: {
            borderColor: 'gray.600',
          },
        },
      },
    },
  };

  const checkboxStyles = {
    ...baseTheme.components.Checkbox,
    baseStyle: {
      ...baseTheme.components.Checkbox.baseStyle,
      control: {
        ...baseTheme.components.Checkbox.baseStyle.control,
        borderColor: 'gray.400',
      },
      noOutline: {
        border: 'none',
        color: 'primary.300',

      },
    },
  };

  const formStyles = {
    ...baseTheme.components.Form,
    baseStyle: {
      ...baseTheme.components.Form.baseStyle,
      container: {
        ...baseTheme.components.Form.baseStyle?.container,
        borderColor: 'gray.400',
        label: {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          lineHeight: '1.2',
          color: 'gray.800',
          fontSize: '14px',
          fontWeight: '700',
          marginBottom: '0.5rem',
        },
        input: {
          ...baseTheme.components.Form.baseStyle?.container?.input,
          borderRadius: '0.25rem',
          margin: 0,
        },
        textarea: {
          ...baseTheme.components.Form.baseStyle?.container?.textarea,
          borderRadius: '0.25rem',
        },
      },
      requiredIndicator: {
        ...baseTheme.components.Form.baseStyle.requiredIndicator,
        color: 'red.700',
      },
      helperText: {
        ...baseTheme.components.Form.baseStyle.helperText,
        fontFamily: 'Poppins',
        fontStyle: 'italic',
        lineHeight: '1.2',
        color: 'gray.700',
        fontSize: '12px',
        fontWeight: '400',
        marginBottom: '0.5rem',
      },
    },
  };

  const formErrorStyles = {
    ...baseTheme.components.FormError,
    baseStyle: {
      ...baseTheme.components.FormError.baseStyle,
      text: {
        ...baseTheme.components.FormError.text,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        lineHeight: '1.2',
        color: 'red.700',
        fontSize: '12px',
        fontWeight: '700',
        marginBottom: '0.5rem',
      },
    },
  };

  const textStyles = {
    ...baseTheme.components.Text,
    baseStyle: {
      ...baseTheme.components.Text.baseStyle,
      fontWeight: 'normal',
      color: 'gray.800',
      lineHeight: '1.2',
    },
    variants: {
      ...baseTheme.components.Text.variants,
      italic: {
        textAlign: 'right',
        marginRight: '1rem',
        fontStyle: 'italic',
        color: 'gray.600',
      },
      author: {
        color: 'gray.600',
        fontFamily: 'Archivo Narrow',
      },
      details: {
        fontStyle: 'italic',
        color: 'gray.600',
        fontFamily: 'Archivo Narrow',
      },
      formLabel: {
        ...baseTheme.components.Text.variants.formLabel,
        fontWeight: '700',
        fontSize: '14px',
      },
      post: {
        color: 'gray.800',
        a: {
          color: 'blue.600',
          fontWeight: 700,
        },
      },
    },
  };

  const alertStyles = {
    ...baseTheme.components.Alert,
    baseStyle: (props) => ({
      ...baseTheme.components.Alert.baseStyle,
      container: {
        backgroundColor: props.status === 'info' ? '#C2E5F0' : 'undefined',
      },
    }),
  };

  const styles = {
    ...baseTheme.styles,
    global: {
      ...baseTheme.styles.global,
      'html, body': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        lineHeight: 'tall',
        color: 'gray.800',
      },
      h1: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: '42px',
      },
      h2: {
        fontFamily: 'Poppins',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '32px',
      },
      h3: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '28px',
      },
      h4: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
      },
      h5: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
      },
      h6: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'capitalize',
      },
      p: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
      },
      body: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '22px',
      },
      body2: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
      },
      tags: {
        fontFamily: 'Archivo Narrow',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
      },
      container: {
        backgroundColor: '#FFF',
        w: '100%',
        boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.300',
      },
      '#LeftNavBar': {
        '> div > div:nth-of-type(1)': {
          padding: '0 0.5rem', // Allowing the number counter to render properly
        },
        '> div > div > div:nth-of-type(1)': {
          gap: '0.625rem',
          justifyContent: 'space-between',
        },
        '> div > div >  div > div:last-of-type > div > div > button > div': {
          alignSelf: 'center', // Allowing Extra icon to render centrally
        },
      },
      // Styles needed for quill formatting
      '.ql-indent-1': {
        marginLeft: '1em',
      },
      '.ql-indent-2': {
        marginLeft: '2em',
      },
      '.ql-indent-3': {
        marginLeft: '3em',
      },
      '.ql-indent-4': {
        marginLeft: '4em',
      },
      '.ql-indent-5': {
        marginLeft: '5em',
      },
      '.ql-indent-6': {
        marginLeft: '6em',
      },
      '.ql-indent-7': {
        marginLeft: '7em',
      },
      '.ql-size-small': {
        fontSize: '13px',
      },
      '.ql-size-large': {
        fontSize: '24px',
      },
      '.ql-size-huge': {
        fontSize: '32px',
      },
    },
    components: {
      popOverStyle: {
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15), 0px 10px 16px 2px rgba(0, 0, 0, 0.2)',
        w: '100%',
        borderRadius: '4px',
      },
      modalStyle: {
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15), 0px 10px 16px 2px rgba(0, 0, 0, 0.2)',
        w: '100%',
        borderRadius: '4px',
      },
    },
  };

  return extendTheme({
    ...baseTheme,
    styles,
    components: {
      ...baseTheme.components,
      Button: buttonStyles,
      Text: textStyles,
      Alert: alertStyles,
      Select: selectStyles,
      Input: inputStyles,
      Checkbox: checkboxStyles,
      Form: formStyles,
      FormError: formErrorStyles,
    },
  });
};

const customTheme = createCustomTheme();

export default customTheme;
