import React, { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import GuidelinesModal from '../components/Modals/GuidelinesModal';

const CommunityGuidelinesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen(); // Automatically open the modal when this component is rendered
  }, [onOpen]);

  return (
    <div>
      <GuidelinesModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        guidelines_accepted={false}
      />
    </div>
  );
};

export default CommunityGuidelinesPage;
