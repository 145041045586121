import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  otherAppsOnExchange: [],
  pinnedApps: [],
  yourOtherApps: [],
};

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setPinnedApps: (state, action) => {
      state.pinnedApps = action.payload;
    },
    setYourOtherApps: (state, action) => {
      state.yourOtherApps = action.payload;
    },
    setOtherAppsOnExchange: (state, action) => {
      state.otherAppsOnExchange = action.payload;
    },
  },
});

export const {
  setPinnedApps, setYourOtherApps, setOtherAppsOnExchange,
} = appsSlice.actions;

export default appsSlice.reducer;
