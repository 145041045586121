import _ from 'lodash';
/* eslint-disable no-underscore-dangle */
const moveObjectById = (sourceArray, destinationArray, objectId) => {
  const updatedSourceArray = sourceArray.filter((obj) => obj._id !== objectId);
  const objectToMove = sourceArray.find((obj) => obj._id === objectId);

  if (objectToMove) {
    const objectToMoveCopy = _.cloneDeep(objectToMove);
    if (objectToMove.pin_id) {
      delete objectToMoveCopy.pin_id;
      objectToMoveCopy.pin = false;
      return [updatedSourceArray, [...destinationArray, objectToMoveCopy]];
    }
    objectToMoveCopy.pin = true;
    objectToMoveCopy.pin_id = '';

    return [updatedSourceArray, [...destinationArray, objectToMoveCopy]];
  }
  return [sourceArray, destinationArray];
};

const sortArrOfObject = (originalArray, sortOrder, sortBy) => {
  if (sortOrder === 'asc') {
    const sorted = originalArray.sort((objA, objB) => {
      const val_a = objA[sortBy].toLowerCase();
      const val_b = objB[sortBy].toLowerCase();

      if (val_a < val_b) {
        return -1;
      }
      if (val_a > val_b) {
        return 1;
      }
      return 0;
    });
    return sorted;
  } if (sortOrder === 'desc') {
    const sorted = originalArray.sort((objA, objB) => {
      const val_a = objA[sortBy].toLowerCase();
      const val_b = objB[sortBy].toLowerCase();

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    });

    return sorted;
  }
  return originalArray;
};

export { moveObjectById, sortArrOfObject };
