import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import {
  Flex,
  FormControl,
  FormHelperText,
} from '@chakra-ui/react';

const toolbarOptionsOne = [
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link'],
  ['clean'],
];
const toolbarOptionsTwo = [
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }],
];

/* The code below was adapted from
   https://github.com/quilljs/quill/issues/262#issuecomment-948890432
   --JJ
*/
const Link = Quill.import('formats/link');

// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(
      (protocol) => {
        return sanitizedUrl.startsWith(protocol);
      },
    );

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`;
  }
}

Quill.register(CustomLinkSanitizer, true);

const QuillTextEditor = ({
  quillText,
  setQuillText,
  customStyle = {},
  toolBarType = 1,
  placeholder = '',
  setQuillCharCount,
  quillCharCount,
  maxCharCount = 560,
}) => {
  const formats = ['background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block'];
  const quillRef = useRef(null);

  // Add className="change-focus" to the next focusable element after quill editor
  const handleTabPress = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      document.getElementsByClassName('change-focus')[0].focus();
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const currentTextLength = editor.getText().length - 1;
      setQuillCharCount(currentTextLength);
      const editorRoot = editor.root;
      editorRoot.addEventListener('keydown', handleTabPress);

      const addAriaLabels = () => {
        const buttonConfig = [
          { selector: '.ql-bold', label: 'Bold' },
          { selector: '.ql-italic', label: 'Italic' },
          { selector: '.ql-underline', label: 'Underline' },
          { selector: '.ql-strike', label: 'Strikethrough' },
          { selector: '.ql-blockquote', label: 'Block Quote' },
          { selector: '.ql-code-block', label: 'Code Block' },
          { selector: '.ql-indent[value="+1"]', label: 'Increase Indent' },
          { selector: '.ql-indent[value="-1"]', label: 'Decrease Indent' },
          { selector: '.ql-list[value="ordered"]', label: 'Ordered List' },
          { selector: '.ql-list[value="bullet"]', label: 'Bullet List' },
          { selector: '.ql-link', label: 'Insert Link' },
          { selector: '.ql-clean', label: 'Remove Formatting' },
        ];

        buttonConfig.forEach(({ selector, label }) => {
          const button = document.querySelector(selector);
          if (button) {
            button.setAttribute('aria-label', label);
          }
        });

        const pickerLabels = [
          { selector: '.ql-picker-label', label: 'Font Size' },
          { selector: '.ql-color .ql-picker-label', label: 'Text Color' },
          { selector: '.ql-background .ql-picker-label', label: 'Background Color' },
        ];

        pickerLabels.forEach(({ selector, label }) => {
          const picker = document.querySelector(selector);
          if (picker) {
            picker.setAttribute('aria-label', label);
          }
        });

        const pickerItems = [
          { selector: '.ql-size .ql-picker-item[data-value="small"]', label: 'Small' },
          { selector: '.ql-size .ql-picker-item[data-value="normal"]', label: 'Normal' },
          { selector: '.ql-size .ql-picker-item[data-value="large"]', label: 'Large' },
          { selector: '.ql-size .ql-picker-item[data-value="huge"]', label: 'Huge' },
          { selector: '.ql-color .ql-picker-item[data-value="#e60000"]', label: 'Red' },
          { selector: '.ql-color .ql-picker-item[data-value="#ff9900"]', label: 'Orange' },
          { selector: '.ql-color .ql-picker-item[data-value="#ffff00"]', label: 'Yellow' },
          { selector: '.ql-color .ql-picker-item[data-value="#008a00"]', label: 'Green' },
          { selector: '.ql-color .ql-picker-item[data-value="#0066cc"]', label: 'Blue' },
          { selector: '.ql-color .ql-picker-item[data-value="#9933ff"]', label: 'Purple' },
          { selector: '.ql-color .ql-picker-item[data-value="#ffffff"]', label: 'White' },
          { selector: '.ql-color .ql-picker-item[data-value="#facccc"]', label: 'Light Red' },
          { selector: '.ql-color .ql-picker-item[data-value="#ffebcc"]', label: 'Light Orange' },
          { selector: '.ql-color .ql-picker-item[data-value="#ffffcc"]', label: 'Light Yellow' },
          { selector: '.ql-color .ql-picker-item[data-value="#cce8cc"]', label: 'Light Green' },
          { selector: '.ql-color .ql-picker-item[data-value="#cce0f5"]', label: 'Light Blue' },
          { selector: '.ql-color .ql-picker-item[data-value="#ebd6ff"]', label: 'Light Purple' },
          { selector: '.ql-color .ql-picker-item[data-value="#bbbbbb"]', label: 'Light Gray' },
          { selector: '.ql-color .ql-picker-item[data-value="#f06666"]', label: 'Bright Red' },
          { selector: '.ql-color .ql-picker-item[data-value="#ffc266"]', label: 'Bright Orange' },
          { selector: '.ql-color .ql-picker-item[data-value="#ffff66"]', label: 'Bright Yellow' },
          { selector: '.ql-color .ql-picker-item[data-value="#66b966"]', label: 'Bright Green' },
          { selector: '.ql-color .ql-picker-item[data-value="#66a3e0"]', label: 'Bright Blue' },
          { selector: '.ql-color .ql-picker-item[data-value="#c285ff"]', label: 'Bright Purple' },
          { selector: '.ql-color .ql-picker-item[data-value="#888888"]', label: 'Gray' },
          { selector: '.ql-color .ql-picker-item[data-value="#a10000"]', label: 'Dark Red' },
          { selector: '.ql-color .ql-picker-item[data-value="#b26b00"]', label: 'Dark Orange' },
          { selector: '.ql-color .ql-picker-item[data-value="#b2b200"]', label: 'Dark Yellow' },
          { selector: '.ql-color .ql-picker-item[data-value="#006100"]', label: 'Dark Green' },
          { selector: '.ql-color .ql-picker-item[data-value="#0047b2"]', label: 'Dark Blue' },
          { selector: '.ql-color .ql-picker-item[data-value="#6b24b2"]', label: 'Dark Purple' },
          { selector: '.ql-color .ql-picker-item[data-value="#444444"]', label: 'Dark Gray' },
          { selector: '.ql-color .ql-picker-item[data-value="#5c0000"]', label: 'Darker Red' },
          { selector: '.ql-color .ql-picker-item[data-value="#663d00"]', label: 'Darker Orange' },
          { selector: '.ql-color .ql-picker-item[data-value="#666600"]', label: 'Darker Yellow' },
          { selector: '.ql-color .ql-picker-item[data-value="#003700"]', label: 'Darker Green' },
          { selector: '.ql-color .ql-picker-item[data-value="#002966"]', label: 'Darker Blue' },
          { selector: '.ql-color .ql-picker-item[data-value="#3d1466"]', label: 'Darker Purple' },
          { selector: '.ql-picker-item.ql-selected', label: 'Selected Item' }, // For selected items
          { selector: '.ql-selected.ql-primary.ql-picker-item', label: 'Primary Selected Item' }, // For primary selected items
        ];

        pickerItems.forEach(({ selector, label }) => {
          const item = document.querySelector(selector);
          if (item) {
            item.setAttribute('aria-label', label);
          }
        });
      };

      addAriaLabels();

      const observer = new MutationObserver(addAriaLabels);
      const toolbar = document.querySelector('.ql-toolbar');
      if (toolbar) {
        observer.observe(toolbar, { childList: true, subtree: true });
      }

      return () => {
        editorRoot.removeEventListener('keydown', handleTabPress);
        observer.disconnect();
      };
    }
  }, [setQuillCharCount]);

  // Removes images from the delta object of the quill editor.
  function removeImagesFromDelta(delta) {
    if (!delta || typeof delta !== 'object') {
      return delta;
    }

    if (Array.isArray(delta.ops)) {
      delta.ops.forEach((item, index, object) => {
        // If we have a image in the delta item remove it.
        if (item.insert.image) {
          object.splice(index, 1);
        }
      });
    }
    return delta;
  }

  const onQuillChange = async (html, delta, source, editor) => {
    const currentDelta = editor.getContents();
    const currentDeltaCount = currentDelta.length();
    const deltaRemovedImages = await removeImagesFromDelta(currentDelta);
    if (deltaRemovedImages.length() !== currentDeltaCount) {
      const quillRefEditor = quillRef.current.getEditor();
      quillRefEditor.setContents(deltaRemovedImages);
    }
    const currentTextLength = editor.getText().length - 1;
    setQuillCharCount(currentTextLength);
    setQuillText(html, delta, source, editor);
  };

  return (
    <Flex direction="column" gap="0.5rem">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={quillText}
        formats={formats}
        placeholder={placeholder}
        onChange={onQuillChange}
        modules={{ toolbar: toolBarType === 1 ? toolbarOptionsOne : toolbarOptionsTwo }}
        style={{
          ...customStyle,
        }}
      />
      <FormControl sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormHelperText variant="details">
          Only http, https, mailto, or tel links are allowed.
        </FormHelperText>
        {maxCharCount != null ? (
          <FormHelperText variant="details" color={`${quillCharCount <= maxCharCount ? 'black' : 'red.600'}`}>
            {maxCharCount - quillCharCount}
            {' '}
            chars remaining
          </FormHelperText>
        ) : (' ')}
      </FormControl>
    </Flex>
  );
};

export default QuillTextEditor;
