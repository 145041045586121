export const AVATARSIZES = {
  XS: '24px',
  SM: '32px',
  MD: '40px',
  LG: '48px',
  XL: '80px',
};

export const AVATARTEXTSIZES = {
  XS: '14px',
  SM: '16px',
  MD: '20px',
  LG: '28px',
  XL: '60px',
};
