// eslint-disable-next-line import/prefer-default-export
export const quillClassToStyle = {
  'ql-size-small': 'font-size: 0.75em;',
  'ql-size-large': 'font-size: 1.5em;',
  'ql-size-huge': 'font-size: 2.5em;',
  'ql-align-center': 'text-align: center;',
  'ql-align-justify': 'text-align: justify;',
  'ql-align-right': 'text-align: right;',
  'ql-align-left': 'text-align: left;',
  'ql-indent-1': 'padding-left: 3em;',
  'ql-indent-2': 'padding-left: 6em;',
  'ql-indent-3': 'padding-left: 9em;',
  'ql-indent-4': 'padding-left: 12em;',
  'ql-indent-5': 'padding-left: 15em;',
  'ql-indent-6': 'padding-left: 18em;',
  'ql-indent-7': 'padding-left: 21em;',
  'ql-indent-8': 'padding-left: 24em;',
  'ql-font-serif': 'font-family: serif;',
  'ql-font-monospace': 'font-family: monospace;',
  'ql-direction-rtl': 'direction: rtl;',
  'ql-bold': 'font-weight: bold;',
  'ql-italic': 'font-style: italic;',
  'ql-underline': 'text-decoration: underline;',
  'ql-strike': 'text-decoration: line-through;',
  'ql-header-1': 'font-size: 2em; font-weight: bold;',
  'ql-header-2': 'font-size: 1.5em; font-weight: bold;',
  'ql-header-3': 'font-size: 1.17em; font-weight: bold;',
  'ql-header-4': 'font-size: 1em; font-weight: bold;',
  'ql-header-5': 'font-size: 0.83em; font-weight: bold;',
  'ql-header-6': 'font-size: 0.67em; font-weight: bold;',
  'ql-list-ordered': 'list-style-type: decimal;',
  'ql-list-bullet': 'list-style-type: disc;',
  'ql-code-block': 'background-color: #f5f5f5; padding: 10px; border-radius: 5px; font-family: monospace;',
  'ql-blockquote': 'border-left: 4px solid #ccc; margin: 1.5em 10px; padding: 0.5em 10px;',
  'ql-link': 'color: blue; text-decoration: underline;',
};
