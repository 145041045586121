/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import {
  VStack, HStack, useTheme, Box, Spacer,
} from '@chakra-ui/react';
import SectionHeader from './SectionHeader';
import UpdateCard from '../Cards/UpdateCard';
import SeeAllNavLink from '../NavMenu/SeeAllNavLink';
import SectionFooter from '../NavMenu/SectionFooter';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import UpdateCardDashboard from '../Cards/UpdateCardDashboard';
import GuidelinesNotAccepted from '../ElementaryComponents/GuidelinesNotAccepted';

const Updates = ({
  sectionTitle,
  seeAllLink = true,
  currentPage,
  setCurrentPage,
  selectedShowNumber,
  setSelectedShowNumber,
  updatesData,
  isFetchingUpdates,
  isFetchingUpdatesError,
  fetchingUpdatesError,
  noDataMessage,
  pagination = true,
  guidelines_accepted,
}) => {
  const theme = useTheme();
  const is_dashboard_updates = sectionTitle === 'Updates';

  const getUpdatesData = () => {
    if (isFetchingUpdates) { return <StatusAlert status="loading" alert_title="Fetching Updates!" />; }
    if (isFetchingUpdatesError) {
      if (fetchingUpdatesError?.status === 500) {
        window.location.reload();
        return <StatusAlert status="error" error={fetchingUpdatesError} />;
      }
    }
    if (Array.isArray(updatesData?.data) && updatesData?.data?.length === 0) {
      return <DataUnavailable display_message={noDataMessage} />;
    }
    if (!Array.isArray(updatesData?.data)) {
      return <DataUnavailable display_message={noDataMessage} />;
    }

    return (
      <VStack
        style={{
          ...theme.styles.global.container,
          minHeight: '4rem',
          padding: '1rem',
          alignSelf: 'flex-end',
        }}
        spacing="0.5rem"
      >
        {updatesData?.data?.map((updatesObj, index) => {
          const { _id: update_id } = updatesObj;
          return sectionTitle !== 'Updates' ? (
            <UpdateCard
              updatesObj={updatesObj}
              key={update_id}
              lastIndex={index === updatesData.meta.total_updates - 1}
              sectionTitle={sectionTitle}
            />
          ) : (
            <UpdateCardDashboard
              updatesObj={updatesObj}
              key={update_id}
              lastIndex={index === updatesData.meta.total_updates - 1}
              sectionTitle={sectionTitle}
            />
          );
        })}
        <HStack width="full">
          {pagination && (
            <SectionFooter
              selectedShowNumber={selectedShowNumber}
              setSelectedShowNumber={setSelectedShowNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalCount={updatesData?.meta?.total_pages}
              isSidebar
            />
          )}
          <Spacer />
          {seeAllLink && (
            <SeeAllNavLink
              navigate_to="/all-updates"
              display_message="See All"
            />
          )}
        </HStack>
      </VStack>
    );
  };

  return (
    <Box>
      <SectionHeader sectionTitle={sectionTitle} searchIcon={false} />
      {is_dashboard_updates ? (
        guidelines_accepted ? (
          getUpdatesData()
        ) : (
          <GuidelinesNotAccepted
            display_message={[
              'If you ', ' ',
              { btn_text: ' agree to the user guidelines ' },
              'you can access the forum and training pages. Once you do, updates on items you’ve pinned will appear here.',
            ]}
            btn_type="text"
          />
        )
      ) : (
        guidelines_accepted ? getUpdatesData() : (
          <GuidelinesNotAccepted
            display_message={[
              ' ',
              { btn_text: ' Agree to the user guidelines ' },
              'to see updates on your pinned trainings.',
            ]}
            btn_type="text"
          />
        )
      )}
    </Box>
  );
};

export default Updates;
