import apiService from '../apiService';

export const appsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllApps: builder.query({
      query: () => ({
        url: 'apps/v1',
      }),
      providesTags: ['apps'],
    }),
    getAppOrder: builder.query({
      query: (params) => ({
        url: 'apps/v1/app_order',
        params,
      }),
      providesTags: ['apps'],
    }),
    setAppOrder: builder.mutation({
      query: ({ order_type, body }) => ({
        url: `apps/v1/app_order?order_type=${order_type}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['apps'],
    }),
  }),
});

export const {
  useFetchAllAppsQuery,
  useGetAppOrderQuery,
  useSetAppOrderMutation,
} = appsAPI;
