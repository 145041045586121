import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import { ThumbUpOffAltIcon, ThumbUpAltIcon } from '../../icons/index';

const ReactionIcon = ({ isLiked = false, isDisabled = false }) => {
  const theme = useTheme();
  return (
    <Box>
      {isLiked ? (
        <ThumbUpAltIcon
          style={{
            color: theme.colors.blue[500],
          }}
        />
      ) : (
        <ThumbUpOffAltIcon
          style={{
            color: theme.colors.gray[800],
          }}
          disabled={isDisabled}
        />
      )}
    </Box>
  );
};

export default ReactionIcon;
