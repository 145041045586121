import React from 'react';
import {
  useTheme, Text, Flex, useBreakpointValue,
} from '@chakra-ui/react';
import AppLauncherCard from './AppLauncherCard';
import AccessibleClickBox from '../ElementaryComponents/AccessibleClickBox';

const AppCard = ({
  appDetailsObj,
  is_pinned,
  my_apps = true,
  guidelines_accepted,
}) => {
  const theme = useTheme();
  const {
    title,
    app_url,
    light_icon_url,
    open_in_new_window,
  } = appDetailsObj;

  const otherAppStyles = {};
  if (!my_apps) otherAppStyles.backgroundColor = '#efefef';
  const cardHeight = useBreakpointValue(
    {
      base: '7.5rem',
      sm: '5rem',
      md: '7.5rem',
    },
  );
  // we make is slightly wider than the height to hide the rounded corners of the image. -JJ
  const iconWidth = useBreakpointValue(
    {
      base: '8rem',
      sm: '5.5rem',
      md: '8rem',
    },
  );

  return (
    <Flex
      style={{
        ...theme.styles.global.container,
        ...otherAppStyles,
        borderRadius: '8px',
      }}
      height={cardHeight}
      mb="1rem"
      align="center"
    >
      <AccessibleClickBox
        open_in_new_window={open_in_new_window}
        url={my_apps ? app_url : ''}
        boxProps={{
          width: iconWidth,
          height: cardHeight,
          flexShrink: 0,
          style: {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            backgroundImage: `url(${light_icon_url})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            opacity: `${my_apps ? 1 : 0.6}`,
          },
          role: 'img',
          'aria-label': `Icon for the ${title} app`,
        }}
      />
      <Flex
        flexGrow={1}
        height="100%"
        color="secondary.100"
        fontFamily="Poppins"
        fontSize="1rem"
        lineHeight={1}
        fontStyle="normal"
        pr="0.75rem"
        align="center"
      >
        <AccessibleClickBox
          open_in_new_window={open_in_new_window}
          url={my_apps ? app_url : ''}
          boxProps={{
            style: {
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              height: '100%',
              paddingLeft: '16px',
            },
          }}
        >
          <Text
            {...theme.styles.global.h4}
            pr="0.25rem"
            wordBreak="normal"
            fontSize="1rem"
          >
            {title}
          </Text>
        </AccessibleClickBox>
        <AppLauncherCard
          is_pinned={is_pinned}
          appDetailsObj={appDetailsObj}
          my_apps={my_apps}
          guidelines_accepted={guidelines_accepted}
        />
      </Flex>
    </Flex>
  );
};

export default AppCard;
