import {
  Flex, Select, Heading, useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowDropDownIcon } from '../../icons';

const NotificationSectionHeader = ({ sectionTitle, setSortOrder, sortOrder }) => {
  const theme = useTheme();
  return (
    <Flex mb={2}>
      <Heading
        flexGrow={1}
        as="h3"
        size="lg"
        style={{ ...theme.styles.global.h3 }}
        color="black"
      >
        {sectionTitle}
      </Heading>
      <Select
        tabIndex={0}
        aria-label="sort by created at"
        width="max-content"
        icon={<ArrowDropDownIcon />}
        sx={{ border: 'none' }}
        cursor="pointer"
        value={sortOrder}
        pr="0"
        size="sm"
        color="gray.600"
        onChange={(e) => setSortOrder(e.target.value)}
      >
        <option value="desc" tabIndex={0}>
          Newest
        </option>
        <option value="asc" tabIndex={0}>
          Oldest
        </option>
      </Select>
    </Flex>
  );
};

export default NotificationSectionHeader;
