/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Flex, Box, Text, useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import { getRelativeTime, joinTruthyStrings } from '../../utils/helpers';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';

const UpdateCardDashboard = ({ updatesObj = {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    child_object_id = null,
    replies_count = 0,
    content,
    created_at,
    action_type,
    child_object_type,
    child_object_body = {},
    child_author_body = {},
    parent_object_body = {},
    parent_object_id = '',
    parent_object_type = '',
  } = updatesObj;

  let authorBody = {};
  if (child_object_id) {
    authorBody = updatesObj.child_author_body;
  } else {
    authorBody = updatesObj.author_body;
  }
  const { user_profile_pic = '', first_name = '', last_name = '' } = authorBody || {};
  const {
    title, _id, group_id = '', post_id = '', course_id = '',
  } = child_object_body;

  let authorName = joinTruthyStrings({
    strings: [authorBody.first_name, authorBody.last_name],
    connectingString: ' ',
  });
  if (!authorName) authorName = authorBody._id;

  const updateType = {
    comment: 'in',
    post: 'in',
    update: 'for',
  };

  let updateNavigationPath;

  if (child_object_type === 'group') {
    updateNavigationPath = `/forum/${_id}`;
  } else if (child_object_type === 'post') {
    updateNavigationPath = `/forum/${parent_object_id}/${_id}`;
  } else if (child_object_type === 'comment') {
    updateNavigationPath = `/forum/${group_id}/${parent_object_id}/${_id}`;
  } else if (child_object_type === 'course') {
    updateNavigationPath = `/training/${_id}`;
  }

  const accessibilityKeyHandler = (event) => {
    if ((event.keyCode === 13 || event.keyCode === 32) && (action_type !== 'deleted')) {
      navigate(updateNavigationPath);
      event.preventDefault();
    }
  };

  return (
    <Flex
      style={{
        width: '100%',
      }}
      p={2}
      borderBottomWidth="1px"
      borderColor="gray.300"
      gap="0.75rem"
    >
      <Box>
        <ProfileCardPopOver
          memberProfile={authorBody}
          userAvatar={(
            <UserAvatar
              name={authorName}
              user_profile_pic={user_profile_pic}
            />
          )}
        />

      </Box>
      <Box
        flexFlow={1}
        role="link"
        tabIndex={0}
        onKeyDown={accessibilityKeyHandler}
        onClick={() => {
          if (action_type !== 'deleted') {
            navigate(updateNavigationPath);
          }
        }}
        style={{ cursor: action_type === 'deleted' ? 'not-allowed' : 'pointer' }}
      >
        <Flex mb="0.5rem" style={{ ...theme.styles.global.body, display: 'inline-block', fontWeight: 'normal' }} color="gray.600">
          <Text as="span" style={{ fontWeight: 700, display: 'inline-block' }}>
            {authorName}
          </Text>
          {' '}
          {action_type || 'created'}
          {' '}
          a
          {' '}
          <Text as="span" style={{ fontWeight: 700, display: 'inline-block' }}>
            {child_object_type}
          </Text>
          {' '}
          <Text as="span" width="fit-content" className="quote_char">
            &ldquo;
            {child_object_type === 'comment' ? parse(title) : parse(title)}
            &ldquo;
          </Text>
          {' '}
          {
            parent_object_id && (
              <>
                <Text as="span" display="inline-flex" width="fit-content">
                  {' '}
                  {updateType[child_object_type]}
                  {' '}
                  the
                </Text>
                {' '}
                <Text as="span" style={{ fontWeight: 700, display: 'inline-block' }}>
                  {`${parse(parent_object_body.title)}`}
                </Text>
                {' '}
                {parent_object_type}
              </>
            )
          }

        </Flex>
      </Box>

      <Box
        minW="3rem"
        ml="auto"
        style={{
          textAlign: 'right', ...theme.styles.global.body, fontWeight: 'normal', color: 'gray.600',
        }}
      >
        {
          getRelativeTime(created_at)
        }
      </Box>
    </Flex>
  );
};

export default UpdateCardDashboard;
