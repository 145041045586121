import React from 'react';
import {
  VStack, useTheme, Box, Flex, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import { joinTruthyStrings } from '../../utils/helpers';

const Courses = ({ coursesData = [], noDataMessage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const accessibilityKeyHandler = useAccessibleNavigation();
  const displayedCoursesCount = coursesData?.length;

  return (
    coursesData.length === 0 ? <DataUnavailable display_message={noDataMessage} />
      : (
        <VStack
          style={{
            ...theme.styles.global.container,
          }}
          minHeight="4rem"
          padding="1rem"
          alignSelf="flex-end"
          spacing={2}
          alignItems="flex-start"
        >
          {
            coursesData?.map((courseData, index) => {
              const {
                author_body = {},
                title,
                _id,
                like_count,
                pin,
                pin_id = '',
                tags,
                update,
                created_at,
              } = courseData;
              const courseCardStyle = index !== displayedCoursesCount - 1 ? { borderBottom: '1px solid #CED8DD', marginBottom: '1rem', marginTop: 0 } : { marginTop: 0 };

              const {
                first_name = '',
                last_name = '',
              } = author_body;

              let authorName = joinTruthyStrings({
                strings: [first_name, last_name],
                connectingString: ' ',
              });
              if (!authorName) authorName = author_body?._id;

              // Create Pin Body
              const createPinBodyObj = {
                child_object_id: _id,
                child_object_type: 'course',
              };

              const navigatePath = `/training/${_id}`;

              return (
                <Box
                  tabIndex={0}
                  role="link"
                  key={_id}
                  style={courseCardStyle}
                  p="0.5rem"
                  width="100%"
                  mt={0}
                  cursor="pointer"
                  onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
                  onClick={() => navigate(navigatePath)}
                >
                  <Flex justify="space-between" width="100%">
                    <Box>
                      <Text
                        style={{
                          ...theme.styles.global.h4,
                          display: 'inline-block',
                          fontWeight: '700',
                        }}
                        color="gray.800"
                      >
                        {parse(title)}
                      </Text>
                    </Box>
                    <Flex w="4rem" justify="flex-end" alignItems="center">
                      <TogglePinIcon
                        is_pinned={pin}
                        create_pin_body_obj={createPinBodyObj}
                        pin_id={pin_id}
                      />
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    width="100%"
                    gap="1rem"
                  >
                    <Text variant="author" fontSize="14px">
                      Author&nbsp;
                      <strong>
                        {authorName}
                      </strong>
                    </Text>

                    <Text variant="details">
                      {pluralize('like', like_count, true)}
                    </Text>

                    <DisplayTags tags={tags} />
                  </Flex>
                  <Flex
                    width="100%"
                    alignItems="center"
                    gap="0.5rem"
                    style={{ ...theme.styles.global.body2, fontSize: '14px' }}
                  >
                    {update?.user_name && (
                    <UserAvatar
                      name={update?.user_name}
                      user_profile_pic=""
                      avatarSize="XS"
                    />
                    )}
                    {update?.user_name && (
                    <>
                      <Text fontWeight="700" mr="0.2rem">
                        {update.user_name}
                      </Text>
                      {update.action}
                      {' '}
                      {`“${update.content}“`}
                      <Text ml="0.5rem">{created_at}</Text>
                    </>
                    )}
                  </Flex>
                </Box>
              );
            })
      }
        </VStack>
      )
  );
};

export default Courses;
