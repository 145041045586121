import React from 'react';
import { useAuth } from '@texas-education-exchange/edx-portal-shared';
import { Navigate } from 'react-router-dom';
import routes from '../core/routes';
import { useGetUserQuery } from '../services/apis/usersAPI';

const CallbackRouter = () => {
  const auth = useAuth();
  const { isLoading } = useGetUserQuery(
    undefined,
    { skip: !(auth.isAuthenticated) },
  );

  if (isLoading) {
    return <h1>Loading</h1>;
  }
  const currentLocation = localStorage.getItem('currentLocation') ?? routes.dashboard.url;

  if (auth.isAuthenticated) {
    return <Navigate to={currentLocation} replace />;
  }

  return null;
};

export default CallbackRouter;
