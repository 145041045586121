import React from 'react';
import { Flex, useTheme } from '@chakra-ui/react';

const DisplayTags = ({ tags = [] }) => {
  const theme = useTheme();
  return (
    <Flex
      flexWrap="wrap"
      gap="0.5rem"
    >
      {tags?.map((tag) => {
        return (
          <Flex
            key={tag}
            color="blue.900"
            bg="orange.100"
            p="0px 8px"
            borderRadius="4px"
            style={{ ...theme.styles.global.tags, alignItems: 'center' }}
          >
            {tag}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default DisplayTags;
