import apiService from '../apiService';

export const commentsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    searchByText: builder.query({
      query: (paramsObj) => ({
        url: '/search/v1/all',
        params: paramsObj,
      }),
      providesTags: ['search-by-text'],
      invalidatesTags: ['search-by-tag'],
    }),
    searchByTags: builder.query({
      query: (paramsObj) => ({
        url: '/search/v1/tags',
        params: paramsObj,
      }),
      providesTags: ['search-by-tag'],
      invalidatesTags: ['search-by-text'],
    }),
    searchUsers: builder.query({
      query: (paramsObj) => ({
        url: '/search/v1/users',
        params: paramsObj,
      }),
      providesTags: ['search-users'],
    }),
  }),
});

export const {
  useSearchByTextQuery,
  useSearchByTagsQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
} = commentsAPI;
