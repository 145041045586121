const mockReports = [
  {
    id: 1,
    startDate: '01-01-2023',
    endDate: '01-01-2023',
    type: 'Aggregate',
    author: 'Dustin Long',
    authorId: '523ea141-283f-43b6-896d-b04b00cb3dd0',
    createdAt: new Date().toLocaleString(),
    csvContent: 'Date,Users Active,1st Logins,Posts,Replies,Likes,Messages\n10/12/23,235,4,34,34,3,3\n10/13/23,257,5,1,34,53,26\n10/14/23,37,2,54,4,432,4653\n10/15/2023,357,1,235,5,64,43\n10/16/2023,56,6,43,2,87,43\n10/17/2023,45,3,4,1,9,34\n10/18/2023,23,33,0,6,6,26\n10/19/2023,75,555,23,3,75,87\n10/20/2023,632,32,643,33,453,4\n10/21/2023,23,4,231,555,34,52\n10/22/2023,235,4,34,34,3,3\n10/13/23,257,5,1,34,53,26\n10/14/23,37,2,54,4,432,4653\n10/15/2023,357,1,235,5,64,43\n10/16/2023,56,6,43,2,87,43\n10/17/2023,45,3,4,1,9,34\n10/18/2023,23,33,0,6,6,26\n10/19/2023,75,555,23,3,75,87\n',

  },
  {
    id: 2,
    startDate: '01-01-2023',
    endDate: '01-01-2023',
    type: 'Individual',
    author: 'Dan Jenkins',
    createdAt: new Date().toLocaleString(),
    csvContent: 'Date,Value\n01-01-2023,100\n01-02-2023,105\n',

  },
  {
    id: 3,
    startDate: '08-04-2023',
    endDate: '08-12-2023',
    type: 'Individual',
    author: 'Bill Reeves',
    createdAt: new Date().toLocaleString(),
    csvContent: 'Date,Value\n01-01-2023,100\n01-02-2023,105\n',

  },
];

export const mockCreateReport = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newReport = { ...data, id: Date.now(), createdAt: new Date().toLocaleString() };
      mockReports.push(newReport);
      resolve(newReport);
    }, 1000);
  });
};

export const mockFetchReports = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([...mockReports]);
    }, 1000);
  });
};

export const mockDeleteReport = (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const index = mockReports.findIndex((report) => report.id === id);
      if (index > -1) {
        mockReports.splice(index, 1);
      }
      resolve();
    }, 1000);
  });
};
