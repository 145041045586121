/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box, Flex, useTheme, Grid, Text,
} from '@chakra-ui/react';
import {
  useLocation, useParams, NavLink,
} from 'react-router-dom';
import CreateGroupModal from '../components/Modals/CreateGroupModal';
import Updates from '../components/Dashboard/Updates';
import SectionContainer from '../components/SectionContainer';
import PostsSectionSubHeader from '../components/DiscussionGroups/PostsSectionSubHeader';
import Post from '../components/DiscussionGroups/Post';
import Posts from '../components/DiscussionGroups/Posts';
import Groups from '../components/DiscussionGroups/Groups';

// services
import { useFetchAllPostsQuery } from '../services/apis/postsAPI';
import { useFetchAllGroupsQuery, useFetchGroupByIdQuery, useFetchGroupUpdatesQuery } from '../services/apis/groupsAPI';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import SectionFooter from '../components/NavMenu/SectionFooter';

const DiscussionGroups = ({ guidelines_accepted }) => {
  const theme = useTheme();
  const location = useLocation();
  const params = useParams();
  const { group_id, post_id } = params;
  const isGroupPath = location.pathname === '/forum';
  const isDiscussionPath = location.pathname === `/forum/${group_id}`;

  const [selectedShowNumberGroups, setSelectedShowNumberGroups] = useState(5);
  const [selectedShowNumberPosts, setSelectedShowNumberPosts] = useState(5);
  const [selectedShowNumberAllGroups, setSelectedShowNumberAllGroups] = useState(5);
  const [selectedShowNumberAllPosts, setSelectedShowNumberAllPosts] = useState(5);
  const [currentPageGroups, setCurrentPageGroups] = useState(1);
  const [currentPagePosts, setCurrentPagePosts] = useState(1);
  const [currentPageAllGroups, setCurrentPageAllGroups] = useState(1);
  const [currentPageAllPosts, setCurrentPageAllPosts] = useState(1);
  const [myGroupsSortOrder, setMyGroupsSortOrder] = useState('desc');
  const [allGroupsSortOrder, setAllGroupsSortOrder] = useState('desc');
  const [myPostsSortOrder, setMyPostsSortOrder] = useState('desc');
  const [allPostsSortOrder, setAllPostsSortOrder] = useState('desc');

  const [currentPageUpdates, setCurrentPageUpdates] = useState(1);
  const [selectedShowNumberUpdates, setSelectedShowNumberUpdates] = useState(5);
  const [allGroupSearchQueryObj, setAllGroupSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });
  const [myGroupSearchQueryObj, setMyGroupSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });
  const [allPostsSearchQueryObj, setAllPostsSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });
  const [myPostsSearchQueryObj, setMyPostsSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });

  useEffect(() => {
    setAllGroupSearchQueryObj({
      query: '',
      last_created: null,
    });
    setMyGroupSearchQueryObj({
      query: '',
      last_created: null,
    });
    setAllPostsSearchQueryObj({
      query: '',
      last_created: null,
    });
    setMyPostsSearchQueryObj({
      query: '',
      last_created: null,
    });
    setCurrentPagePosts(1);
    setCurrentPageAllPosts(1);
  }, [location.pathname]);

  const forumTitleStyle = {
    ...theme.styles.global.h1,
    color: `${theme.colors.blue[800]}`,
    display: 'inline-block',
  };

  // Rtk Query
  // Query Object for groups and Post
  const myGroupsQueryObj = {
    page_number: currentPageGroups,
    page_size: selectedShowNumberGroups,
    sort_order: myGroupsSortOrder,
    sort_by: 'created_at',
    pin: true,
    query: myGroupSearchQueryObj.query,
    last_created: myGroupSearchQueryObj.last_created,
    include_apps: true,
    include_courses: true,
  };

  const allGroupsQueryObj = {
    page_number: currentPageAllGroups,
    sort_order: allGroupsSortOrder,
    page_size: selectedShowNumberAllGroups,
    sort_by: 'created_at',
    pin: false,
    query: allGroupSearchQueryObj.query,
    last_created: allGroupSearchQueryObj.last_created,
    include_apps: true,
    include_courses: true,
  };

  const myPostsQueryObj = {
    group_id,
    page_number: currentPagePosts,
    sort_by: 'created_at',
    page_size: selectedShowNumberPosts,
    sort_order: myPostsSortOrder,
    pin: true,
    query: myPostsSearchQueryObj.query,
    last_created: myPostsSearchQueryObj.last_created,
  };

  const allPostsQueryObj = {
    group_id,
    page_number: currentPageAllPosts,
    sort_by: 'created_at',
    page_size: selectedShowNumberAllPosts,
    sort_order: allPostsSortOrder,
    pin: false,
    query: allPostsSearchQueryObj.query,
    last_created: allPostsSearchQueryObj.last_created,
  };

  const updatesQueryObj = {
    page_number: currentPageUpdates,
    page_size: selectedShowNumberUpdates,
  };

  const {
    data: myPostsData,
    isFetching: isMyPostsFetching,
    isError: isMyPostsError,
    error: myPostsError,
    refetch: refetchMyPosts,
  } = useFetchAllPostsQuery(myPostsQueryObj, { skip: !group_id });

  const {
    data: allPostsData,
    isFetching: isAllPostsFetching,
    isError: isAllPostError,
    error: allPostError,
    refetch: refetchAllPosts,
  } = useFetchAllPostsQuery(allPostsQueryObj, { skip: !group_id });

  const {
    data: myGroupsData,
    isFetching: isMyGroupsFetching,
    isError: isMyGroupsError,
    error: myGroupsError,
    refetch: refetchMyGroups,
  } = useFetchAllGroupsQuery(myGroupsQueryObj);

  const {
    data: allGroupsData,
    isFetching: isAllGroupsFetching,
    isError: isAllGroupsError,
    error: allGroupsError,
    refetch: refetchAllGroups,
  } = useFetchAllGroupsQuery(allGroupsQueryObj);

  const {
    data: allGroupsUpdatesData,
    isFetching: isAllGroupsUpdatesFetching,
    isError: isAllGroupsUpdatesError,
    error: allGroupsUpdatesError,
    refetch: refetchGroupsUpdates,
  } = useFetchGroupUpdatesQuery(updatesQueryObj);

  useEffect(() => {
    if (isGroupPath) {
      refetchMyGroups();
      refetchAllGroups();
      setCurrentPageAllPosts(1);
      setCurrentPagePosts(1);
    }
    refetchGroupsUpdates();
  }, [isGroupPath, refetchMyGroups, refetchAllGroups, refetchGroupsUpdates]);

  useEffect(() => {
    if (isDiscussionPath) {
      refetchMyPosts();
      refetchAllPosts();
    }
    refetchGroupsUpdates();
  }, [isDiscussionPath, refetchMyPosts, refetchAllPosts, refetchGroupsUpdates]);

  const can_create_group = allGroupsData?.permissions?.group?.can_create;
  const can_create_post = allPostsData?.permissions?.post?.can_create;

  const {
    data: groupByIdData,
    isFetching: isFetchingGroupById,
    isError: isErrorGroupById,
    error: errorGroupById,
  } = useFetchGroupByIdQuery(group_id, { skip: !group_id });

  const user_banned = groupByIdData?.data?.user_banned;
  const no_action_in_group = groupByIdData?.data?.user_locked || groupByIdData?.data?.is_locked;

  // render myGroups Component
  const myGroupsComponent = () => {
    if (isMyGroupsFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Groups Data..." />;
    if (isMyGroupsError) return <StatusAlert status="error" error={myGroupsError} />;
    return (
      <Groups
        groupsData={myGroupsData.data}
        groupsPermissions={myGroupsData.permissions?.group}
        isPinned
        selectedShowNumber={selectedShowNumberGroups}
        noDataMessage={[myGroupSearchQueryObj?.query ? 'Your search did not find any pinned groups' : 'Pin a group to see the latest updates from it and always have it at the top of this page.']}
      />
    );
  };

  // render allGroups Component
  const allGroupsComponent = () => {
    if (isAllGroupsFetching) return <StatusAlert status="loading" alert_title="Fetching All Groups Data..." />;
    if (isAllGroupsError) return <StatusAlert status="error" error={allGroupsError} />;
    return (
      <Groups
        groupsData={allGroupsData.data}
        groupsPermissions={allGroupsData.permissions?.group}
        isPinned={false}
        selectedShowNumber={selectedShowNumberAllGroups}
        noDataMessage={[allGroupSearchQueryObj?.query ? 'Your search did not find any groups' : 'There are no unpinned groups']}
      />
    );
  };

  // render myPosts Component
  const myPostsComponent = () => {
    if (isMyPostsFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Posts Data..." />;
    if (isMyPostsError) return <StatusAlert status="error" error={myPostsError} />;
    return (
      <Posts
        no_action_in_group={no_action_in_group}
        postsData={myPostsData}
        isPinned
        selectedShowNumber={selectedShowNumberPosts}
        noDataMessage={[myPostsSearchQueryObj?.query ? 'Your search did not find any pinned posts' : 'Pin a post to see the latest updates from it and always have it at the top of this page.']}
      />
    );
  };

  // render allPosts Component
  const allPostsComponent = () => {
    if (isAllPostsFetching) return <StatusAlert status="loading" alert_title="Fetching All Posts Data..." />;
    if (isAllPostError) return <StatusAlert status="error" error={allPostError} />;
    return (
      <Posts
        no_action_in_group={no_action_in_group}
        postsData={allPostsData}
        isPinned={false}
        selectedShowNumber={selectedShowNumberAllPosts}
        noDataMessage={[allPostsSearchQueryObj?.query ? 'Your search did not find any posts' : 'There is no unpinned post.']}
      />
    );
  };

  let currentGroupError = null;
  if (group_id && errorGroupById) {
    currentGroupError = errorGroupById;
  }

  if (group_id && post_id) {
    return (
      <Post no_action_in_group={no_action_in_group} />
    );
  }

  return (
    <Box w="full">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
        dir="column"
      >

        {isGroupPath ? (
          <Text style={forumTitleStyle}>Forum</Text>
        ) : (
          <NavLink to="/forum" style={forumTitleStyle}>Forum</NavLink>
        )}

        {
          isGroupPath && can_create_group && (
          <CreateGroupModal
            buttonLabel="Create Group"
            title="My Modal"
          />
          )
        }
      </Flex>
      {/* Whene group doesnot exist or it's hidden for the user */}
      {currentGroupError
        && (
        <Text style={{ ...theme.styles.global.h2, fontWeight: 600, textAlign: 'center' }} color="red.600" mt={10}>
          {currentGroupError?.data?.message || 'The item you are trying to find does not exist'}
        </Text>
        ) }

      {/* Grid layout for Group Discussion page */}
      {!currentGroupError && (
      <Grid
        templateAreas={`"myGroups groupUpdates"
                      "allGroups groupUpdates"`}
        templateColumns="2.6fr 1fr"
        templateRows="auto 1fr"
        gap="2rem"
        pt={4}
        id="MyGroups"
      >
        {
              isGroupPath
              && (
                <Box
                  gridArea="myGroups"
                  pt={0}
                  pl={0}
                >
                  <SectionContainer
                    sectionTitle="Pinned Groups"
                    sectionInfoData={myGroupsData}
                    setSortOrder={setMyGroupsSortOrder}
                    childComponent={(myGroupsComponent())}
                    searchIcon
                    searchQueryObj={myGroupSearchQueryObj}
                    setSearchQueryObj={setMyGroupSearchQueryObj}
                  />
                  <SectionFooter
                    selectedShowNumber={selectedShowNumberGroups}
                    setSelectedShowNumber={setSelectedShowNumberGroups}
                    currentPage={currentPageGroups}
                    setCurrentPage={setCurrentPageGroups}
                    totalCount={myGroupsData?.meta?.total_pages}
                  />
                </Box>
              )
            }
        {
              isDiscussionPath
              && (
                <Box
                  gridArea="myGroups"
                  pt={0}
                  pl={0}
                >
                  <PostsSectionSubHeader
                    canCreatePost={can_create_post}
                  />
                  {!user_banned && (
                  <>
                    <SectionContainer
                      sectionTitle="Pinned Posts"
                      sectionInfoData={myPostsData}
                      setSortOrder={setMyPostsSortOrder}
                      childComponent={(
                    myPostsComponent()
                  )}
                      searchIcon
                      searchQueryObj={myPostsSearchQueryObj}
                      setSearchQueryObj={setMyPostsSearchQueryObj}
                    />
                    <SectionFooter
                      selectedShowNumber={selectedShowNumberPosts}
                      setSelectedShowNumber={setSelectedShowNumberPosts}
                      currentPage={currentPagePosts}
                      setCurrentPage={setCurrentPagePosts}
                      totalCount={myPostsData?.meta?.total_pages}
                    />
                  </>
                  )}
                </Box>
              )
            }

        {/* Updates section */}
          {
            (!user_banned || !group_id) && (
            <Box gridArea="groupUpdates" pt={0}>
              <Updates
                sectionTitle="Group Updates"
                seeAllLink={false}
                currentPage={currentPageUpdates}
                setCurrentPage={setCurrentPageUpdates}
                updatesData={allGroupsUpdatesData}
                isFetchingUpdates={isAllGroupsUpdatesFetching}
                isFetchingUpdatesError={isAllGroupsUpdatesError}
                fetchingUpdatesError={allGroupsUpdatesError}
                selectedShowNumber={selectedShowNumberUpdates}
                setSelectedShowNumber={setSelectedShowNumberUpdates}
                guidelines_accepted={guidelines_accepted}
                noDataMessage={['Pin a group or a post to see the updates here.']}
              />
            </Box>
            )
          }
        {/* All Groups section */}
        {
              isGroupPath
              && (
                <Box
                  id="AllGroups"
                  gridArea="allGroups"
                  pt={0}
                >
                  <SectionContainer
                    sectionTitle="All Groups"
                    sectionInfoData={allGroupsData}
                    setSortOrder={setAllGroupsSortOrder}
                    childComponent={(allGroupsComponent())}
                    searchIcon
                    searchQueryObj={allGroupSearchQueryObj}
                    setSearchQueryObj={setAllGroupSearchQueryObj}
                  />
                  <SectionFooter
                    selectedShowNumber={selectedShowNumberAllGroups}
                    setSelectedShowNumber={setSelectedShowNumberAllGroups}
                    currentPage={currentPageAllGroups}
                    setCurrentPage={setCurrentPageAllGroups}
                    totalCount={allGroupsData?.meta?.total_pages}
                  />
                </Box>
              )
            }

        {
              isDiscussionPath && !user_banned
              && (
                <Box gridArea="allGroups" pt={0} pl={0}>
                  <SectionContainer
                    sectionTitle="All Posts"
                    sectionInfoData={allPostsData}
                    setSortOrder={setAllPostsSortOrder}
                    childComponent={(
                      allPostsComponent()
                    )}
                    searchIcon
                    searchQueryObj={allPostsSearchQueryObj}
                    setSearchQueryObj={setAllPostsSearchQueryObj}
                  />
                  <SectionFooter
                    selectedShowNumber={selectedShowNumberAllPosts}
                    setSelectedShowNumber={setSelectedShowNumberAllPosts}
                    currentPage={currentPageAllPosts}
                    setCurrentPage={setCurrentPageAllPosts}
                    totalCount={allPostsData?.meta?.total_pages}
                  />
                </Box>
              )
            }
      </Grid>
      )}
    </Box>
  );
};

export default DiscussionGroups;
