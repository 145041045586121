import { Flex, IconButton, useTheme } from '@chakra-ui/react';
import React from 'react';
import { ThumbUpAltIcon, ThumbUpOffAltIcon } from '../../icons';

const ReactionIconComponent = ({
  alreadyLiked,
  customStyle = {},
  handleClick,
  noActionAllowed = false,
  ariaLabelText,
}) => {
  const theme = useTheme();

  const compStyle = {
    color:
      alreadyLiked && !noActionAllowed
        ? theme.colors.blue[500]
        : theme.colors.gray[800],
    cursor: noActionAllowed ? 'not-allowed' : 'pointer',
    width: '40px',
    background: 'transparent',
    ...customStyle,
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      handleClick(event);
    }
  };

  return (
    <Flex alignItems="center">
      {alreadyLiked ? (
        <IconButton
          aria-label={`UnLike the ${ariaLabelText}`}
          onKeyDown={handleKeyDown}
          onClick={handleClick}
          disabled={noActionAllowed}
          style={compStyle}
          icon={<ThumbUpAltIcon />}
        />
      ) : (
        <IconButton
          aria-label={`Like the ${ariaLabelText}`}
          onKeyDown={handleKeyDown}
          disabled={noActionAllowed}
          onClick={handleClick}
          style={compStyle}
          icon={<ThumbUpOffAltIcon />}
        />
      )}
    </Flex>
  );
};

export default ReactionIconComponent;
