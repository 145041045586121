import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import baseConfig from '../baseConfig';

const baseQuery = async (args, WebApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseConfig.getPropertyByName('apiBaseUrl'),
    prepareHeaders: (headers, { getState }) => {
      const currentLoggedInUser = getState().auth.loggedInUser;
      headers.set('req-user-id', currentLoggedInUser.profile.sub);
      headers.set('Authorization', `Bearer ${currentLoggedInUser.access_token}`);
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

const apiService = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
});

export default apiService;
