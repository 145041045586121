import apiService from '../apiService';

export const moderationAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    moderateModerator: builder.mutation({
      query: ({ user_id, ...params }) => ({
        url: `/moderations/v1/moderate/moderator/${user_id}`,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['search-users', 'user'],
    }),
    moderateUser: builder.mutation({
      query: ({ user_id, params, body }) => ({
        url: `/moderations/v1/moderate/user/${user_id}`,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['search-users', 'report', 'reports', 'user'],
    }),
    moderateGroup: builder.mutation({
      query: ({ group_id, params, body }) => ({
        url: `/moderations/v1/moderate/group/${group_id}`,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['search-users', 'report', 'reports', 'user'],
    }),
    moderatePost: builder.mutation({
      query: ({ post_id, params, body }) => ({
        url: `/moderations/v1/moderate/post/${post_id}`,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['search-users', 'report', 'reports', 'user'],
    }),
    moderateComment: builder.mutation({
      query: ({ comment_id, params, body }) => ({
        url: `/moderations/v1/moderate/comment/${comment_id}`,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['search-users', 'report', 'reports', 'user'],
    }),
  }),
});

export const {
  useModerateModeratorMutation,
  useModerateUserMutation,
  useModerateGroupMutation,
  useModeratePostMutation,
  useModerateCommentMutation,
} = moderationAPI;
