import React from 'react';
import {
  useTheme, Text, Flex, useBreakpointValue, Button,
} from '@chakra-ui/react';
import AccessibleClickBox from '../ElementaryComponents/AccessibleClickBox';
import { ArrowDropDownIcon } from '../../icons';

const AppOrderCard = ({
  appDetailsObj,
  currentAppOrder = [],
  groupAppOrder = [],
  indexInGroup = 0,
  setCurrentAppOrder,
}) => {
  const theme = useTheme();
  const {
    title,
    light_icon_url,
    _id,
  } = appDetailsObj;

  const otherAppStyles = {};
  const cardHeight = useBreakpointValue(
    {
      base: '2.5rem',
      sm: '1.25rem',
      md: '2.5rem',
    },
  );
  // we make is slightly wider than the height to hide the rounded corners of the image. -JJ
  const iconWidth = useBreakpointValue(
    {
      base: '2.5rem',
      sm: '1.25rem',
      md: '2.5rem',
    },
  );

  const groupAppsCount = groupAppOrder.length;
  const indexInAll = currentAppOrder.findIndex((a) => a._id === _id);

  const moveApps = (direction) => {
    const newIndexInGroup = indexInGroup + direction;
    if (newIndexInGroup < 0 || newIndexInGroup >= groupAppsCount) return;

    // Find the index to be replaced
    const idOfNewIndex = groupAppOrder[newIndexInGroup]._id;
    const newIndexInAll = currentAppOrder.findIndex((a) => a._id === idOfNewIndex);

    // Move the app to the new position in the list of all apps.
    const newOrder = JSON.parse(JSON.stringify(currentAppOrder));
    [
      newOrder[indexInAll],
      newOrder[newIndexInAll],
    ] = [
      newOrder[newIndexInAll],
      newOrder[indexInAll],
    ];
    setCurrentAppOrder(newOrder);
  };

  return (
    <Flex
      style={{
        ...theme.styles.global.container,
        ...otherAppStyles,
        borderRadius: '8px',
        boxShadow: 'none',
      }}
      height={cardHeight}
      mb="1rem"
      align="center"
    >
      <AccessibleClickBox
        boxProps={{
          width: iconWidth,
          height: cardHeight,
          flexShrink: 0,
          style: {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            backgroundImage: `url(${light_icon_url})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          },
          role: 'img',
          'aria-label': `Icon for the ${title} app`,
        }}
      />
      <Flex
        flexGrow={1}
        height="100%"
        color="secondary.100"
        fontFamily="Poppins"
        fontSize="1rem"
        lineHeight={1}
        fontStyle="normal"
        pr="0.75rem"
        pl="2rem"
        align="center"
      >
        <Text
          {...theme.styles.global.h4}
          pr="0.25rem"
          wordBreak="normal"
          fontSize="1rem"
        >
          {title}
        </Text>
      </Flex>
      <Flex gap={2} mr={2} alignItems="center">
        <Button
          onClick={() => moveApps(-1)}
          sx={{
            border: '1px solid gray', width: '1rem', height: '1.5rem',
          }}
          _hover={{ backgroundColor: 'white' }}
          color="gray.500"
          style={{ transform: 'rotate(180deg)' }}
          isDisabled={indexInGroup === 0}
          aria-label="Increase the app order"
        >
          <ArrowDropDownIcon />
        </Button>
        <Button
          onClick={() => moveApps(1)}
          sx={{
            border: '1px solid gray', width: '1rem', height: '1.5rem',
          }}
          _hover={{ backgroundColor: 'white' }}
          color="gray.500"
          isDisabled={indexInGroup === groupAppsCount - 1}
          aria-label="Decrease the app order"
        >
          <ArrowDropDownIcon />
        </Button>
      </Flex>
    </Flex>
  );
};

export default AppOrderCard;
