import apiService from '../apiService';

const userTags = [
  'apps',
  'courses',
  'updates',
  'course-updates',
  'groups',
  'group-updates',
  'pins',
  'user',
];

export const usersAPI = apiService.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getUser: builder.query({
        query: (paramsObj = {}) => {
          const loginValue = !!paramsObj.login;

          return {
            url: 'users/v1/me',
            headers: {
              login: loginValue,
              reload: !loginValue,
            },
          };
        },
        providesTags: ['user'],
      }),
      login: builder.mutation({
        queryFn: () => ({
          data: null,
        }),
        invalidatesTags: [...userTags],
      }),
      logout: builder.mutation({
        queryFn: () => ({
          data: null,
        }),
        invalidatesTags: [...userTags],
      }),
      updateUser: builder.mutation({
        query: ({ user_id, paramsObj }) => ({
          url: `users/v1/${user_id}`,
          params: paramsObj,
          method: 'PUT',
        }),
        invalidatesTags: ['user'],
      }),
    });
  },
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useUpdateUserMutation,
} = usersAPI;
