import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Flex,
  Spacer,
  Input,
  FormControl,
  FormHelperText,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import { SearchIcon } from '../../icons';
import { joinTruthyStrings } from '../../utils/helpers';
import { useLazySearchUsersQuery } from '../../services/apis/searchAPI';
import SimplifiedSearchedUsers from '../ElementaryComponents/simplifiedSearchedUsers';

const UserSelectModal = ({ onUserSelect, userData }) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showSearchedUsers, setShowSearchedUsers] = useState(false);

  const searchQueryParams = {
    page_number: 1,
    page_size: 100,
    sort_order: 'desc',
  };

  const [trigger, {
    data: searchedUserData,
    isFetching: isSearchedUserFetching,
    isError: isSearchUserError,
    error: searchUserError,
  }] = useLazySearchUsersQuery();

  useEffect(() => {
    setShowSearchedUsers(true);
  }, [searchedUserData]);

  const handleSetUser = async (userId, userName) => {
    onUserSelect(userId, userName);
  };

  const handleSearchUser = () => {
    if (searchInputValue) {
      trigger({ ...searchQueryParams, query: searchInputValue });
      setShowSearchedUsers(true);
    }
  };

  const onSearchKeyDown = (e) => {
    if (e?.key === 'Enter') {
      handleSearchUser();
    }
  };

  const handleOpenEdit = (e) => {
    onOpen(e);
  };

  const handleEditClose = (e) => {
    setSearchInputValue('');
    onClose(e);
  };

  return (
    <>
      <Button
        onClick={handleOpenEdit}
        height="46px"
        width="220px"
        colorScheme="blue"
        variant="outline"
        mt={7}
        style={{
          ...theme.styles.global.button,
          fontWeight: '600',
          backgroundColor: 'white',
        }}
        size="md"
      >
        Select Individual
      </Button>
      <Modal isOpen={isOpen} onClose={handleEditClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalHeader>
            <Text style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
              Select an Individual to Report On
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Current Moderators list */}
            <Flex
              flexDir="column"
              maxHeight="250px"
              overflowY="scroll"
              pr="1.5rem"
            >
              {userData?.data?.map((moderatorData) => {
                const {
                  first_name, last_name, _id, email, job_title,
                } = moderatorData;

                const moderatorName = joinTruthyStrings({
                  strings: [first_name, last_name],
                  connectingString: ' ',
                });

                return (
                  <Flex key={_id} flexDirection="column" pt={2} pb={2}>
                    <Flex justifyContent="space-between" alignItems="center" gap={2}>
                      <ProfileCardPopOver
                        memberProfile={{}}
                        userAvatar={(
                          <UserAvatar
                            name={moderatorName}
                            user_profile_pic=""
                            avatarSize="XS"
                          />
                        )}
                      />
                      <Text style={{ ...theme.styles.global.h5, fontSize: '18px' }}>
                        {moderatorName}
                      </Text>
                      <Spacer />
                    </Flex>
                    <Flex flexDirection="column" pl={8} mt={2} gap={1}>
                      <Text style={{ ...theme.styles.global.body2 }}>{job_title }</Text>
                      <Text style={{ ...theme.styles.global.body2 }}>{email}</Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
            {/* User Search */}
            <Flex pr="1.5rem">
              <FormControl>
                <InputGroup size="sm">
                  <Input
                    borderRadius="4px"
                    value={searchInputValue}
                    sx={{ padding: '0 12px' }}
                    placeholder="Search by name or email address"
                    isRequired
                    onChange={(e) => setSearchInputValue(e.target.value)}
                    onKeyDown={(e) => onSearchKeyDown(e)}
                    aria-label="Search for a user by name or email address"
                  />
                  <InputRightElement color="white" bg="blue.500" borderRightRadius="4px" cursor="pointer">
                    <IconButton
                      onClick={handleSearchUser}
                      variant="unstyled"
                      marginTop="auto"
                      aria-label="Search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText>
                  Use full names, partial matches may not be found.
                </FormHelperText>
              </FormControl>
            </Flex>
            {/* Searched Users list */}
            <Flex flexDir="column" mt={4} maxHeight="300px" overflow="scroll" pr="1.5rem">
              <SimplifiedSearchedUsers
                isSearchedUserFetching={isSearchedUserFetching}
                isSearchUserError={isSearchUserError}
                searchUserError={searchUserError}
                moderatorsData={userData}
                searchedUserData={searchedUserData}
                searchInputValue={searchInputValue}
                handleSetUser={handleSetUser}
                onClose={onClose}
                showSearchedUsers={showSearchedUsers}
                setShowSearchedUsers={setShowSearchedUsers}
              />
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="flex-start" width="100%">
            <Button
              mr={3}
              onClick={handleEditClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              w="5rem"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default UserSelectModal;
