import React from 'react';
import { Box } from '@chakra-ui/react';

const AccessibleClickBox = (props) => {
  const {
    children,
    onClick,
    url = '',
    open_in_new_window = true,
    boxProps = {},
  } = props;

  const onClickBox = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      const target = open_in_new_window ? '_blank' : '_self';
      const openApp = window.open(url, target, 'noopener,noreferrer');
      if (openApp) openApp.opener = null;
    }
  };

  const onKeyDownInBox = (e) => {
    if (e?.key === 'Enter') {
      onClickBox();
    }
  };

  return (
    <Box
      {...boxProps}
      onClick={() => onClickBox()}
      onKeyDown={(e) => onKeyDownInBox(e)}
      role="link"
      tabIndex="0"
      cursor={onClick || url ? 'pointer' : 'auto'}
    >
      {children}
    </Box>
  );
};

export default AccessibleClickBox;
