import React from 'react';
import {
  Flex, useTheme, Text, Box,
} from '@chakra-ui/react';
import CreateNotificationModal from '../components/Modals/CreateNotificationModal';
import NotificationSection from '../components/Notification/NotificationSection';

const NotificationCenter = () => {
  const theme = useTheme();

  return (
    <Box w="full">
      <Flex justifyContent="space-between" alignItems="center" py={4}>
        <Text
          style={{
            ...theme.styles.global.h1,
            color: theme.colors.blue[800],
            display: 'inline-block',
          }}
        >
          Notification Center
        </Text>
        <CreateNotificationModal buttonLabel="Create Event" title="My Modal" />
      </Flex>
      <Flex justifyContent="space-between">
        <NotificationSection
          sectionTitle="Pending"
          status="pending"
        />
        <NotificationSection
          sectionTitle="Sent"
          status="sent"
        />
      </Flex>
    </Box>
  );
};

export default NotificationCenter;
