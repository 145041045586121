/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Text,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import { joinTruthyStrings } from '../../utils/helpers';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';

const SearchedUsers = ({
  isSearchedUserFetching,
  isSearchUserError,
  searchUserError,
  moderatorsData,
  searchedUserData,
  searchInputValue,
  handleAddModerator,
  setShowSearchedUsers,
  showSearchedUsers,
  onClose,
}) => {
  const theme = useTheme();

  useEffect(() => {
    setShowSearchedUsers(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  if (isSearchedUserFetching) return <StatusAlert status="loading" alert_title="Fetching User..." />;
  if (isSearchUserError) return <StatusAlert status="error" error={searchUserError} />;
  if (!showSearchedUsers) return '';

  const allModerators = moderatorsData?.data;
  const allUsers = searchedUserData?.data;
  const usersWhoCanBeAssigned = allUsers?.filter(
    (user) => {
      return !allModerators.some((moderator) => moderator._id === user._id);
    },
  );

  return (
    usersWhoCanBeAssigned?.length === 0
      ? (
        <DataUnavailable
          display_message={[`${searchInputValue ? 'No user found!' : ''}`]}
          customStyle={{
            boxShadow: 'none',
            border: 'none',
            background: 'none',
            fontSize: '16px',
            lineHeight: '1.5',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        />
      )
      : usersWhoCanBeAssigned?.map((userData) => {
        const {
          first_name, last_name, _id, email, job_title,
        } = userData;

        const userName = joinTruthyStrings({
          strings: [first_name, last_name],
          connectingString: ' ',
        });
        return (
          <Flex key={_id + Math.random()} flexDirection="column" pt={2} pb={2}>
            <Flex justifyContent="space-between" alignItems="center" gap={2}>
              <ProfileCardPopOver
                memberProfile={userData}
                userAvatar={(
                  <UserAvatar
                    name={userName}
                    user_profile_pic=""
                    avatarSize="XS"
                  />
                  )}
              />
              <Text style={{ ...theme.styles.global.h5 }}>
                {userName}
              </Text>
              <Spacer />
              <Text
                style={{ ...theme.styles.global.body, fontSize: '14px' }}
                color="blue.500"
                cursor="pointer"
                onClick={() => handleAddModerator(_id)}
              >
                Add
              </Text>
            </Flex>
            <Flex flexDirection="column" pl={8} mt={2} gap={1}>
              <Text style={{ ...theme.styles.global.body2 }}>{job_title }</Text>
              <Text style={{ ...theme.styles.global.body2 }}>{email}</Text>
            </Flex>
          </Flex>
        );
      })
  );
};

export default SearchedUsers;
