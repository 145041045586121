import { Text } from '@chakra-ui/react';
import React from 'react';

const MaxCharExceedError = ({
  message,
  charCount,
  custom_style = {},
  max_char_limit = 64,
}) => {
  return (
    charCount > max_char_limit ? (
      <Text color="red.600" fontSize="sm" mt="2" style={{ ...custom_style }}>
        {message}
        {' '}
        cannot exceed
        {' '}
        {max_char_limit}
        {' '}
        characters.
      </Text>
    ) : null
  );
};

export default MaxCharExceedError;
