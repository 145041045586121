import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const DataUnavailable = ({ display_message = ['No Content'], customStyle = {} }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...theme.styles.global.container,
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
        padding: '1rem',
        ...customStyle,
      }}
    >
      {
        display_message.map((part) => {
          if (typeof part === 'string') {
            return `${part.trim()} `;
          }
          return (
            <Link
              key={part.path + part.text}
              to={part.path}
              style={{
                color: `${theme.colors.blue[500]}`,
                fontWeight: 700,
              }}
            >
              {`${part.text.trim()} `}
            </Link>
          );
        })
      }
    </Box>
  );
};

export default DataUnavailable;
