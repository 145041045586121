import React from 'react';
import { Button, useTheme } from '@chakra-ui/react';
import { useDownloadAnalyticsReportQuery } from '../../services/apis/reportsAPI';
import StatusAlert from './StatusAlert';

const DownloadButton = ({
  fileName, variant, buttonLabel, size, report_id,
}) => {
  const theme = useTheme();
  const {
    data: downloadData,
    isError: isDownloadError,
    error: downloadError,
  } = useDownloadAnalyticsReportQuery(report_id);

  const handleDownload = async () => {
    try {
      const presignedUrl = downloadData?.presigned_url;
      if (presignedUrl) {
        const a = document.createElement('a');
        a.href = presignedUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(presignedUrl);
      }
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
    }
  };

  if (isDownloadError) return <StatusAlert status="error" error={downloadError} />;

  if (!report_id) return null;

  return (
    <Button
      variant={variant}
      onClick={handleDownload}
      size={size}
      style={{ ...theme.styles.global.button, fontWeight: '600' }}
      colorScheme="blue"
      color="blue.500"
    >
      {buttonLabel}
    </Button>
  );
};

export default DownloadButton;
