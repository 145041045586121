import React, { useEffect, useState } from 'react';
import {
  Box,
  Input,
  List,
  ListItem,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useSearchLocationsQuery } from '../../services/apis/osmAPI';

const LocationSearch = ({
  onSelect, size = 'sm', isLocationSearch = false, initialInputValue = '', searchEntered,
}) => {
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { data, isLoading } = useSearchLocationsQuery(inputValue, {
    skip: inputValue.length < 3, // Skip fetching until the input has at least 3 characters
  });

  useEffect(() => {
    if (!searchEntered) {
      setInputValue('');
    }
  }, [searchEntered]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (isLocationSearch) {
      onSelect({ lat: '', lon: '' });
    }
    setInputValue(value);
    setIsDropdownVisible(value.length >= 3);
  };

  const handleSelect = (location) => {
    onSelect({ ...location });
    setIsDropdownVisible(false);
    setInputValue(location.display_name);
  };

  return (
    <Box position="relative">
      <Input
        sx={{ width: '100%' }}
        value={inputValue}
        onChange={handleInputChange}
        size={size}
        placeholder="Search by location"
      />
      {isLoading && <Spinner size="sm" />}
      {isDropdownVisible && data && (
        <List
          position="absolute"
          zIndex="1"
          bg="white"
          borderWidth="1px"
          borderRadius="md"
          width="100%"
          maxHeight="200px"
          overflowY="auto"
        >
          {data.map((place) => {
            const {
              display_name,
            } = place;

            return (
              <ListItem
                key={place.place_id}
                padding="4px"
                _hover={{ backgroundColor: 'gray.100' }}
                cursor="pointer"
                onClick={() => handleSelect(place)}
              >
                {display_name}
              </ListItem>
            );
          })}
          {data.length === 0 && (
            <ListItem padding="4px">
              <Text>No locations found</Text>
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

export default LocationSearch;
