import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  Select,
  VStack,
  Text,
  Input,
  useTheme,
  useDisclosure,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import CustomPopoverTrigger from '../ElementaryComponents/CustomPopoverTrigger';
import { ArrowDropDownIcon } from '../../icons';
import LocationSearch from '../ElementaryComponents/LocationSearch';

// Array of distance options
const distanceOptions = [
  { value: '10 ', label: 'Within 10 miles' },
  { value: '25 ', label: 'Within 25 miles' },
  { value: '50 ', label: 'Within 50 miles' },
  { value: '100', label: 'Within 100 miles' },
  { value: '250', label: 'Within 250 miles' },
];

const SearchPopOver = ({
  popOverIcon,
  showTimePeriod = true,
  setSearchQueryObj,
  sectionTitle,
  searchTerm,
  setSearchTerm,
  timePeriod,
  setTimePeriod,
  setSearchEntered,
  popOverTitle = 'Search in',
  isLocationSearch = false,
  searchEntered,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLandingPage = location.pathname === '/dashboard';
  const [selectedLocation, setSelectedLocation] = useState({
    lon: '', lat: '', city: '', state: '',
  });
  const [selectedRadius, setSelectedRadius] = useState('Select a distance range');

  const initialFocusRef = useRef();

  useEffect(() => {
    if (!searchEntered) {
      setSelectedRadius('Select a distance range');
    }
  }, [searchEntered]);

  const paramsObj = {
    query: searchTerm,
  };

  if (typeof +timePeriod === 'number') {
    paramsObj.last_created = timePeriod;
  } else {
    delete paramsObj.last_created;
  }

  if (isLocationSearch) {
    const {
      lon, lat, city, state,
    } = selectedLocation;

    if (selectedRadius !== 0) {
      paramsObj.radius = selectedRadius;
    } else {
      delete paramsObj.radius;
    }
    if (selectedLocation.lon && selectedLocation.lat) {
      paramsObj.lat = lat;
      paramsObj.lon = lon;
    }
    if (city || state) {
      paramsObj.query = `${paramsObj.query} ${city || ''} ${state || ''}`.replace(/\s+/g, ' ').trim();
    }
  }

  const handleSearch = () => {
    setSearchQueryObj(paramsObj);
    setSearchEntered(true);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchQueryObj({});
    setSearchEntered(false);
    setSelectedRadius('');
    setSelectedLocation({
      lon: '', lat: '', city: '', state: '',
    });
    onClose();
  };

  const handleLocationSelect = ({ lon, lat, ...rest }) => {
    setSelectedLocation({
      lon, lat, city: rest.address?.city, state: rest.address?.state,
    });
  };

  const handleRadiusChange = (event) => {
    setSelectedRadius(event.target.value);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
    >
      <CustomPopoverTrigger
        isOpen={isOpen}
      >
        <IconButton
          sx={{ backgroundColor: 'rgba(256,256,256,0.2)' }}
          aria-label="Search"
          icon={popOverIcon}
        />
      </CustomPopoverTrigger>
      <PopoverContent style={{ position: 'absolute', right: '8px' }} aria-label="search popover">

        <PopoverBody _focus={{ outline: 'none' }} style={{ ...theme.styles.components.popOverStyle }} padding="16px">
          <VStack>
            <Text style={{ ...theme.styles.global.body, textAlign: 'left', width: '100%' }}>
              {popOverTitle}
              {' '}
              {sectionTitle}
            </Text>
            <Input
              placeholder={isLocationSearch ? 'Search by event name' : 'Type your search here'}
              aria-label="search item"
              size="sm"
              borderRadius="4px"
              value={searchTerm}
              style={{ padding: '0 12px' }}
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={initialFocusRef}
            />
            {showTimePeriod
              && (
                <Select
                  width="100%"
                  height="40px"
                  aria-label="select time period"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                  style={{ padding: '0 12px' }}

                >
                  <option value={1}>Last 1 Day</option>
                  <option value={14}>Last 2 Weeks</option>
                  <option value={30}>Last 1 Month</option>
                  <option value="all">All Time</option>
                </Select>
              )}

            {isLocationSearch && (
              <Box sx={{ width: '100%' }}>
                <LocationSearch
                  onSelect={handleLocationSelect}
                  isLocationSearch
                  searchEntered={searchEntered}
                />

                {/* Select Dropdown */}
                <Select
                  id="selectDistance"
                  aria-label="Select distance"
                  icon={<ArrowDropDownIcon />}
                  cursor="pointer"
                  value={selectedRadius}
                  pr="0"
                  size="sm"
                  mt={2}
                  color="gray.600"
                  onChange={handleRadiusChange}
                >
                  <option value="0">Select a distance range</option>
                  {distanceOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
            <Button
              height="40px"
              onClick={handleSearch}
              width="100%"
              colorScheme="blue"
              bg="blue.500"
            >
              Search
            </Button>
            <Button
              height="40px"
              onClick={handleClearSearch}
              width="100%"
              variant="outline"
            >
              Clear
            </Button>
            {
              isLandingPage && (
              <Button
                as={NavLink}
                to="navLink"
                width="100%"
                variant="outline"
                fontWeight="600"
                _hover={{ textDecoration: 'none' }}
              >
                Open
                {' '}
                  {sectionTitle}
                {'\u00A0'}
                Page
              </Button>
              )
            }
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SearchPopOver;
