import {
  Flex, Text, Spacer, useTheme, Box,
} from '@chakra-ui/react';
import React from 'react';
import parse from 'html-react-parser';
import { capitalizeFirstLetter, getFormattedExactTime } from '../../utils/helpers';
import FlaggedItemTitle from './FlaggedItemTitle';

const userStatus = {
  hide: 'Banned',
  unhide: 'Unbanned',
  lock: 'Locked',
  unlock: 'Unlocked',
};

const contentStatus = {
  hide: 'Hidden',
  lock: 'Locked',
  unhide: 'Unhidden',
  unlock: 'Unlocked',
  ignore: 'Ignored',
};

const statusArr = {
  unlock: 'NONE',
  unbann: 'NONE',
  unhide: 'NONE',
  hide: 'REMOVED',
  lock: 'LOCKED',
  bann: 'BANNED',
  remove: 'REMOVED',
  pending: 'PENDING',
  ignore: 'IGNORED',
};

const ModerationActions = ({
  moderation,
  display_comment = false,
  customStyle = {},
  is_latest_moderation_action = false,
  latest_moderation_action = {},
  paginatedData,
  paginationReq = false,
  selectedShowNumberAction,
  currentPageAction,
}) => {
  const theme = useTheme();
  const isModerated = Object.keys(latest_moderation_action).length !== 0;
  const moderation_actions_data = is_latest_moderation_action && isModerated
    ? [{ ...latest_moderation_action }] : moderation?.moderation_actions;

  const displayActions = paginationReq
    ? paginatedData(moderation_actions_data, selectedShowNumberAction, currentPageAction)
    : moderation_actions_data;

  return (
    <Flex
      flexDir="column"
      ml={8}
      mt={2}
      style={{ ...customStyle }}
    >
      {
        displayActions.map((moderation_action) => {
          const {
            action,
            added_at,
            comment,
            object_type,
            reason,
          } = moderation_action;

          let customTitle = '';
          if (object_type === 'user') {
            customTitle = `User ${userStatus[action]}`;
          } else {
            customTitle = `${capitalizeFirstLetter(object_type)} ${contentStatus[action]}`;
          }

          let customStatus;

          if (object_type === 'user') {
            if (userStatus[action] === 'Banned') {
              customStatus = 'BANNED';
            } else if (userStatus[action] === 'Locked') {
              customStatus = 'LOCKED';
            } else {
              customStatus = 'NONE';
            }
          } else {
            customStatus = statusArr[action] || 'NONE';
          }

          // TODO: need to fix later (key)
          return (
            <Box
              key={added_at + object_type}
              className={display_comment && 'postCard'}
              mt={2}
              pb={2}
            >
              <Flex key={added_at + Math.random()} alignItems="center">
                <FlaggedItemTitle
                  title={customTitle}
                  status={customStatus}
                  isModertorView
                  customStyle={{ minWidth: '240px' }}
                />
                <Spacer />
                <Flex alignItems="center" gap={2}>
                  <Text style={{ ...theme.styles.global.body, fontWeight: 700 }}>
                    {reason}
                  </Text>
                  <Text variant="details">
                    {getFormattedExactTime(added_at)}
                  </Text>
                </Flex>
              </Flex>
              {
              display_comment && (
                <Text
                  style={{
                    ...theme.styles.global.body2, lineHeight: '22px', fontSize: '16px',
                  }}
                  mt={2}
                  mb={2}
                  ml={4}
                >
                  {parse(comment || '')}
                </Text>
              )
            }
            </Box>
          );
        })
      }
    </Flex>
  );
};

export default ModerationActions;
