import React, { useEffect } from 'react';
import {
  Flex,
  Select,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import { Pagination } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ArrowDropDownIcon } from '../../icons/index';

const SectionFooter = ({
  selectedShowNumber,
  setSelectedShowNumber,
  currentPage,
  setCurrentPage,
  totalCount = 1,
  isSidebar,
}) => {
  const customShowNumberSelectStyle = {
    cursor: 'pointer',
  };

  useEffect(() => {
    if (currentPage > totalCount) {
      setCurrentPage(totalCount || 1);
    }
  }, [currentPage, setCurrentPage, totalCount]);

  // single media query with no options
  const [isLarge] = useMediaQuery('(min-width: 1680px)');
  const isSmallSidebar = isSidebar && !isLarge;

  return (
    <Box alignSelf="flex-start">
      <Flex
        align="center"
        mt="0.5rem"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
      >
        <Select
          width="max-content"
          minWidth="90px"
          aria-label="Set the number of items to display."
          icon={<ArrowDropDownIcon />}
          iconSize="sm"
          cursor="pointer"
          defaultValue={selectedShowNumber}
          sx={{ border: 'none' }}
          mr="0.5rem"
          size="sm"
          pr="0"
          color="gray.600"
          onChange={(e) => {
            setSelectedShowNumber(e.target.value);
            setCurrentPage(1);
          }}
        >
          <option value="5" style={{ ...customShowNumberSelectStyle }}>
            Show 5
          </option>
          <option value="10" style={{ ...customShowNumberSelectStyle }}>
            Show 10
          </option>
          <option value="30" style={{ ...customShowNumberSelectStyle }}>
            Show 30
          </option>
        </Select>
        <ThemeProvider theme={createTheme()}>
          <Pagination
            shape="rounded"
            count={totalCount}
            page={currentPage}
            siblingCount={1}
            boundaryCount={isSmallSidebar ? 0 : 1}
            size={isSmallSidebar ? 'small' : 'medium'}
            onChange={(_, page) => setCurrentPage(page)}
          />
        </ThemeProvider>
      </Flex>
    </Box>
  );
};

export default SectionFooter;
