import React from 'react';
import {
  HStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
} from '@chakra-ui/react';
import { ArrowBackIosIcon, ArrowForwardIosIcon } from '../../icons';

const SimplePagination = ({
  totalRows, rowsPerPage, currentPage, setCurrentPage, setRowsPerPage,
}) => {
  const startRange = (currentPage - 1) * rowsPerPage + 1;
  const endRange = Math.min(currentPage * rowsPerPage, totalRows);

  return (
    <HStack justifyContent="flex-end">
      <Text>Rows per page:</Text>
      <NumberInput
        value={rowsPerPage}
        onChange={(_, valueAsNumber) => {
          const newRowsPerPage = Number.isNaN(valueAsNumber) || valueAsNumber
          < 5 ? 5 : valueAsNumber;
          setRowsPerPage(newRowsPerPage);
          setCurrentPage(1);
        }}
        max={999}
        min={1}
        maxWidth="80px"
        aria-label="Rows per page"
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text>
        {startRange}
        -
        {endRange}
        {' '}
        of
        {' '}
        {totalRows}
      </Text>
      <IconButton
        aria-label="Go to previous page"
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        icon={(
          <ArrowBackIosIcon
            style={{ cursor: 'pointer' }}
          />
      )}
      />
      <IconButton
        aria-label="Go to next page"
        onClick={() => setCurrentPage((prev) => Math.min(
          prev + 1,
          Math.ceil(totalRows / rowsPerPage),
        ))}
        icon={(
          <ArrowForwardIosIcon
            style={{ cursor: 'pointer' }}
          />
)}
      />
    </HStack>
  );
};

export default SimplePagination;
