import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedInUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    removeLoggedInUser: (state) => {
      state.loggedInUser = null;
    },
  },
});

export const {
  setLoggedInUser, removeLoggedInUser,
} = authSlice.actions;

export default authSlice.reducer;
