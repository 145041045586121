import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';

const SeeAllNavLink = ({ navigate_to, display_message, cutsomStyle = {} }) => {
  return (
    <Link
      as={NavLink}
      to={navigate_to}
      width="max-content"
      color="blue.500"
      display="inline-flex"
      alignSelf="flex-end"
      fontWeight="500"
      _hover={{ textDecoration: 'none' }}
      sx={cutsomStyle}
    >
      {display_message}
    </Link>
  );
};

export default SeeAllNavLink;
