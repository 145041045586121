/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Flex,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import { getRelativeTime, joinTruthyStrings } from '../../utils/helpers';
import DisplayTags from './DisplayTags';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from './UserAvatar';

const SearchResultCard = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    _id,
    title,
    description = '',
    created_at,
    tags,
    like_count,
    author_body = {},
    baseURL,
  } = props;

  const {
    user_profile_pic = '',
    first_name = '',
    last_name = '',
  } = author_body;

  let authorName = joinTruthyStrings({
    strings: [first_name, last_name],
    connectingString: ' ',
  });
  if (!authorName) authorName = author_body?._id;

  return (
    <Flex
      key={_id}
      onClick={() => navigate(`${baseURL}${_id}`)}
      flexDirection="column"
      style={{ borderBottom: '1px solid #CED8DD', cursor: 'pointer' }}
      marginBottom="0.75rem"
      paddingBottom="0.25rem"
    >
      <Heading as="h3" mb="0.5rem" style={{ ...theme.styles.global.h4 }}>
        {title}
      </Heading>
      <Flex
        alignItems="center"
        marginBottom="1rem"
        flexWrap="wrap"
        gap="0.5rem"
      >
        {author_body?._id && (
        <Flex
          flexShrink={0}
          alignItems="center"
          maxWidth="100%"
          flexWrap="wrap"
          gap="0.5rem"
        >
          <Text style={{ fontWeight: '400', marginRight: '0.25rem' }}>created by:</Text>
          <Flex
            flexShrink={0}
            alignItems="center"
            gap="0.5rem"
          >
            <ProfileCardPopOver
              memberProfile={author_body}
              userAvatar={(
                <UserAvatar
                  name={authorName}
                  user_profile_pic={user_profile_pic}
                />
              )}
            />
            <Text style={{ fontWeight: '700' }} mr="0.5rem">
              {authorName}
            </Text>
          </Flex>
          <Text mr="0.5rem">
            {getRelativeTime(created_at)}
          </Text>
        </Flex>
        )}
        {
          !!like_count && (
            <Text variant="italic" fontStyle="normal">
              {pluralize('like', like_count, true)}
            </Text>
          )
        }
        <DisplayTags tags={tags} />
      </Flex>
      {/*
          The as=cite below is a bit of a hack to avoid console errors from nesting the p tags in
          the description inside the Text p tag. -JJ
        */}
      {description && (
        <Text mb="0.5rem" noOfLines={3} as="cite">
          {parse(description)}
        </Text>
      )}
    </Flex>
  );
};

export default SearchResultCard;
