import React from 'react';
import {
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';

const LoadingModal = ({ isOpen }) => (
  <Modal isOpen={isOpen} isCentered>
    <ModalOverlay />
    <ModalContent p="2rem">
      <ModalBody textAlign="center">
        <Heading as="h1" mb="1rem">We&apos;re Loading Your Information</Heading>
        <Flex alignItems="center">
          <Spinner mr={3} />
          This should only take a few seconds...
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default LoadingModal;
