import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import NotificationSectionHeader from './NotificationSectionHeader';
import NotificationCard from '../Cards/NotificationCard';
import SectionFooter from '../NavMenu/SectionFooter';
import { useFetchNotificationsQuery } from '../../services/apis/updatesAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';

const NotificationSection = ({ sectionTitle, status }) => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedShowNumber, setSelectedShowNumber] = useState('5');
  const [currentPage, setCurrentPage] = useState(1);

  const notificationsQueryObj = {
    page_number: currentPage,
    page_size: selectedShowNumber,
    sort_order: sortOrder,
    sort_by: 'scheduled_at',
    status,
  };
  const {
    data: notificationsData,
    isFetching: isNotificationsFetching,
    isError: isNotificationsError,
    error: notificationsError,
  } = useFetchNotificationsQuery(notificationsQueryObj);

  if (isNotificationsFetching) {
    return (
      <Box w="49%">
        <StatusAlert status="loading" alert_title={`Fetching ${status} notifictions`} />
      </Box>
    );
  }
  if (isNotificationsError) {
    return (
      <Box w="49%">
        <StatusAlert status="error" error={notificationsError} />
      </Box>
    );
  }
  const { data, meta } = notificationsData;

  return (
    <Box w="49%">
      <NotificationSectionHeader
        sectionTitle={sectionTitle}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      />
      {data?.map((notificationData) => {
        const {
          title, type, scheduled_at, description = '', _id,
        } = notificationData;
        return (
          <NotificationCard
            status={status}
            message={title}
            channel={type}
            scheduled_at={scheduled_at}
            description={description}
            notif_id={_id}
            key={_id}
          />
        );
      })}
      <SectionFooter
        selectedShowNumber={selectedShowNumber}
        setSelectedShowNumber={setSelectedShowNumber}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={meta?.total_pages}
      />
    </Box>
  );
};

export default NotificationSection;
