/* eslint-disable max-len */
import React from 'react';
import {
  Flex, Box, Text, useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import { getRelativeTime, joinTruthyStrings } from '../../utils/helpers';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';

const UpdateCard = ({ updatesObj = {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const accessibilityKeyHandler = useAccessibleNavigation();

  const {
    author_body,
    created_at,
    group_id,
    group_title,
    post_id = '',
    post_title = '',
    title,
    type,
    _id,
  } = updatesObj;

  const { user_profile_pic = '', first_name = '', last_name = '' } = author_body || {};

  let authorName = joinTruthyStrings({
    strings: [first_name, last_name],
    connectingString: ' ',
  });
  if (!authorName) authorName = _id;

  let updateNavigationPath;

  if (type === 'post') {
    updateNavigationPath = `/forum/${group_id}/${_id}`;
  } else if (type === 'comment') {
    updateNavigationPath = `/forum/${group_id}/${post_id}/${_id}`;
  }

  return (
    <Flex
      style={{
        marginBottom: '1.5rem',
        width: '100%',
      }}
      borderBottomWidth="1px"
      borderColor="gray.300"
      gap="0.75rem"
    >
      <Box>
        <ProfileCardPopOver
          memberProfile={author_body}
          userAvatar={(
            <UserAvatar
              name={authorName}
              user_profile_pic={user_profile_pic}
            />
          )}
        />
      </Box>
      <Box
        role="link"
        tabIndex={0}
        flexFlow={1}
        onKeyDown={(event) => accessibilityKeyHandler(event, updateNavigationPath)}
        onClick={() => navigate(updateNavigationPath)}
        style={{ cursor: 'pointer' }}
      >
        <Flex
          mb="0.5rem"
          style={{
            ...theme.styles.global.body,
            display: 'inline-block',
            fontWeight: 'normal',
          }}
          color="gray.600"
        >
          <Text as="span" style={{ fontWeight: 700, display: 'inline-block' }}>
            {`${first_name} ${last_name}`}
          </Text>
          {' '}
          {`${type}ed `}
          <Text as="span" whiteSpace="pre-wrap" className="quote_char" style={{ display: 'contents' }}>
            &ldquo;
            {type === 'comment' ? parse(title) : parse(post_title)}
            {type === 'post' ? parse(title) : ''}
            &rdquo;
          </Text>
          <Text as="span" display="inline-flex" ml={1} mr={1}>
            {' '}
            in the
            {' '}
          </Text>
          <Text as="span" style={{ fontWeight: 700, display: 'inline-block' }}>
            {type === 'comment' ? parse(post_title) : parse(group_title)}
          </Text>
        </Flex>

      </Box>

      <Box
        minW="3rem"
        ml="auto"
        style={{
          textAlign: 'right', ...theme.styles.global.body, fontWeight: 'normal', color: 'gray.600',
        }}
      >
        {
          getRelativeTime(created_at)
        }
      </Box>
    </Flex>
  );
};

export default UpdateCard;
