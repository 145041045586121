/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Select,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { ArrowDropDownIcon, DangerousIcon } from '../../icons';
import { sanitizeData } from '../../utils/helpers';
import { useCreateModerationReportMutation, useFetchAllModerationReportsQuery, useLazyFetchAllModerationReportsQuery } from '../../services/apis/postsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
// import StatusAlert from '../ElementaryComponents/StatusAlert';

const reportReasonsArr = ['Inappropriate Content', 'Hostile Behavior', 'Posted in the Wrong Place', 'Other'];

const ReportContentModal = ({
  moderate_btn = false,
  content_type = 'post',
  content_id,
  customBtnStyle = {},
  isModerator,
  moderation_report_id,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reportReason, setReportReason] = useState(reportReasonsArr[0]);
  const [reportComment, setReportComment] = useState('');
  const [reportCommentLength, setReportCommentLength] = useState('');
  const isButtonDisabled = reportComment.trim().length === 0 || reportCommentLength > 560;
  const [newReportId, setNewReportId] = useState(null);
  const [createReport, {
    isLoading: isCreatingReport,
    isError, error,
  }] = useCreateModerationReportMutation();

  // All reports for a group by group_id
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportsQueryObj = {
    page_number: 1,
    page_size: 5,
    sort_by: 'created_at',
    sort_order: 'asc',
    object_id: content_id,
  };
  const [trigger, {
    data: commentReportsData,
    isFetching: isFetchingCommentReports,
    isError: isReportsError,
    error: reportsError,
  }] = useLazyFetchAllModerationReportsQuery();

  // TODO: On report creation display message or navigate based on the user type
  const handleCreateReport = async () => {
    const reportBodyObj = {
      report: {
        reason: sanitizeData(reportReason),
        report_comment: sanitizeData(reportComment),
      },
      object_type: content_type,
      object_id: content_id,
    };

    try {
      const res = await createReport(reportBodyObj).unwrap();
      setNewReportId(res?.data?._id);
    } catch (err) {
      console.error('Failed to create a report', err);
    }
  };

  const handleNavigateToReport = () => {
    navigate(`/reports/${moderation_report_id}`);
  };

  useEffect(() => {
    if (isOpen && content_type === 'comment') {
      trigger(reportsQueryObj);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, content_type]);

  useEffect(() => {
    if (content_type === 'comment' && moderate_btn) {
      const previous_report_id = commentReportsData?.data?.[0]?._id;
      if (previous_report_id) {
        navigate(`/reports/${previous_report_id}`);
      }
    }

    if (newReportId && moderate_btn) {
      navigate(`/reports/${newReportId}`);
    } else if (newReportId) {
      setTimeout(() => {
        onClose();
        setNewReportId('');
        setReportReason(reportReasonsArr[0]);
        setReportComment('');
        setReportCommentLength(0);
      }, [2000]);
    }
  }, [commentReportsData, content_type, moderate_btn, newReportId, onClose, navigate]);

  const getReports = () => {
    if (content_type === 'comment' && moderate_btn) {
      if (isFetchingCommentReports) {
        return (
          <ModalContent style={{
            minHeight: '200px', padding: '1rem', display: 'flex', justifyContent: 'center',
          }}
          >
            <StatusAlert status="loading" alert_title="Fetching comment reports..." />
          </ModalContent>
        );
      }
      if (isReportsError) {
        return (
          <ModalContent style={{
            minHeight: '200px', padding: '1rem', display: 'flex', justifyContent: 'center',
          }}
          >
            <StatusAlert status="error" error={reportsError} />
          </ModalContent>
        );
      }
    }
    return (
      <ModalContent
        borderTop="8px solid"
        borderColor="blue.600"
        style={{ ...theme.styles.components.modalStyle }}
      >
        <ModalHeader>
          <Text style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
            {isModerator ? 'Create Report' : 'Report'}
          </Text>
          <Text
            variant="italic"
            style={{ textAlign: 'left', fontSize: '14px' }}
            color="black"
            mt={2}
          >
            {isModerator
              ? 'To moderate a content item that has not been reported by a user, we need to first create a report.'
              : `This report will only be seen by the admins and moderators. The
              author of the content you are reporting will not be notified, and
              will not know you reported them. Your report will be kept private.
              A moderator or admin may reach out to you for further details
              about your report. Do not misuse the report function, or you may
              lose privileges on the Community.`}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text style={{ ...theme.styles.global.h4 }} mb={2} mt={4}>
            Tell us why you’re reporting it:
          </Text>
          <Select
            tabIndex={0}
            aria-label="sort by created at"
            width="max-content"
            icon={<ArrowDropDownIcon />}
            cursor="pointer"
            defaultValue="inappropriate_content"
            pr="0"
            size="sm"
            color="gray.500"
            onChange={(e) => setReportReason(e.target.value)}
          >
            {reportReasonsArr.map((reason) => {
              return (
                <option value={reason} tabIndex={0} key={reason}>
                  {reason}
                </option>
              );
            })}
          </Select>
          <Text style={{ ...theme.styles.global.h4 }} mb={2} mt={8}>
            Give as many details as possible in your own words:
          </Text>
          <QuillTextEditor
            quillText={reportComment}
            setQuillText={setReportComment}
            customStyle={{ margin: 0 }}
            toolBarType="2"
            setQuillCharCount={setReportCommentLength}
            quillCharCount={reportCommentLength}
          />
        </ModalBody>
        <ModalFooter justifyContent="flex-start" mt="2rem" width="100%">
          <Button
            className="change-focus"
            mr={3}
            onClick={onClose}
            variant="outline"
            border="1px solid #CED8DD"
            size="sm"
            _hover={{ opacity: 0.9 }}
            color="blue.600"
            w="5rem"
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            bg="red.600"
            onClick={handleCreateReport}
            size="sm"
            isDisabled={isButtonDisabled}
            w="5rem"
          >
            Submit
          </Button>
        </ModalFooter>
        {
            isCreatingReport && (
              <Flex style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="loading" alert_title="Creating Report..." />
              </Flex>
            )
        }
        {
            newReportId && (
              <Flex style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="success" alert_title="You have successfully reported this content." />
              </Flex>
            )
          }
      </ModalContent>
    );
  };

  return (
    <>
      <Button
        onClick={(moderation_report_id && isModerator) ? handleNavigateToReport : onOpen}
        width="100%"
        style={{
          ...customBtnStyle,
        }}
        leftIcon={!isModerator && <DangerousIcon style={{ color: 'red' }} />}
        size="sm"
      >
        {isModerator ? 'Moderate' : 'Report'}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered style={{ minHeight: '400px' }}>
        <ModalOverlay />
        {getReports()}
      </Modal>
    </>
  );
};
export default ReportContentModal;
