import React, { useState } from 'react';
import { Flex, Text, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import SectionHeader from './SectionHeader';
import AppCard from '../Cards/AppCard';
import SeeAllNavLink from '../NavMenu/SeeAllNavLink';
import StatusAlert from '../ElementaryComponents/StatusAlert';

const AppsLink = ({ msg }) => (
  <SeeAllNavLink
    navigate_to="/all-apps"
    display_message={msg}
  />
);

const PinnedApps = ({
  isFetching,
  isError,
  guidelines_accepted,
}) => {
  const [selectedShowNumber, setSelectedShowNumber] = useState('5');
  const pinnedApps = (useSelector((state) => state.apps.pinnedApps) ?? [])
    .filter((a) => a.show_in_app_launcher);
  const yourOtherApps = (useSelector((state) => state.apps.yourOtherApps) ?? [])
    .filter((a) => a.show_in_app_launcher);
  const myAppsCount = pinnedApps.length + yourOtherApps.length;

  const getPinnedAppSection = () => {
    if (isFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Apps..." />;
    if (isError) {
      return (
        <Stack direction="column" gap="0.5rem">
          <StatusAlert
            status="error"
            alert_title="There was an error while loading your pinned apps."
          />
          <AppsLink msg="View the Apps Page" />
        </Stack>
      );
    }
    if (pinnedApps.length > 0) {
      return (
        <>
          {pinnedApps?.map((appDetailsObj) => {
            const { _id } = appDetailsObj;
            return (
              <AppCard
                appDetailsObj={appDetailsObj}
                is_pinned
                key={_id}
                guidelines_accepted={guidelines_accepted}
              />
            );
          })}
          <AppsLink msg={`See All (${myAppsCount})`} />
        </>
      );
    }
    if (pinnedApps.length === 0 && yourOtherApps.length > 0) {
      return (
        <>
          <Text variant="details" marginBottom="1rem">
            To have your favorite apps appear here, open the apps page using the
            link below and click on the ... menu on each app card.
          </Text>
          <AppsLink msg={`See All (${myAppsCount})`} />
        </>
      );
    }
    if (myAppsCount === 0) {
      return (
        <SeeAllNavLink
          navigate_to="/all-apps"
          display_message="You don't currently have any apps. You can view other apps on the Exchange on the Apps page."
          cutsomStyle={{
            fontSize: '1.25rem',
            width: '100%',
            lineHeight: 1.2,
            fontWeight: '500',
          }}
        />
      );
    }
    return null;
  };

  return (
    <Flex direction="column">
      <SectionHeader
        sectionTitle="Pinned Apps"
        selectedShowNumber={selectedShowNumber}
        setSelectedShowNumber={setSelectedShowNumber}
        totalCount={pinnedApps?.length}
        showTimePeriod={false}
      />
      {getPinnedAppSection()}
    </Flex>
  );
};

export default PinnedApps;
