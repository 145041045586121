import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import AppOrderCard from './AppOrderCard';
import StatusAlert from '../ElementaryComponents/StatusAlert';

const AppLists = ({
  isFetchingAppOrder,
  orderType,
  currentAppOrder,
  setCurrentAppOrder,
  currentPinnedApps,
  currentOtherApps,
}) => {
  return (
    <div>
      {!isFetchingAppOrder ? (
        <div>
          {orderType === 'default' && (
          <div style={{
            flexBasis: 2,
            flexGrow: 1,
          }}
          >
            <Text
              sx={{ fontWeight: 700, fontSize: '18px', margin: '1rem 0' }}
              color="gray.800"
            >
              Application Order
            </Text>
            { currentAppOrder.map((app_object, index) => {
              return (
                <AppOrderCard
                  key={app_object?._id}
                  appDetailsObj={app_object}
                  groupAppOrder={currentAppOrder}
                  indexInGroup={index}
                  currentAppOrder={currentAppOrder}
                  setCurrentAppOrder={setCurrentAppOrder}
                />
              );
            })}
          </div>
          )}
          {orderType === 'user' && (
          <Flex
            direction="column"
            gap="2rem"
            style={{
              flexBasis: 1,
              minWidth: '40%',
            }}
          >
            {currentPinnedApps.length > 0 && (
            <div>
              <Text
                sx={{ fontWeight: 700, fontSize: '18px', margin: '1rem 0' }}
                color="gray.800"
              >
                Pinned
              </Text>
              { currentPinnedApps?.map((app_object, index) => {
                return (
                  <AppOrderCard
                    key={app_object?._id}
                    appDetailsObj={app_object}
                    groupAppOrder={currentPinnedApps}
                    indexInGroup={index}
                    currentAppOrder={currentAppOrder}
                    setCurrentAppOrder={setCurrentAppOrder}
                  />
                );
              })}
            </div>
            )}
            {currentOtherApps.length > 0 && (
            <div>
              <Text
                sx={{ fontWeight: 700, fontSize: '18px', margin: '1rem 0' }}
                color="gray.800"
              >
                Other Apps
              </Text>
              { currentOtherApps?.map((app_object, index) => {
                return (
                  <AppOrderCard
                    key={app_object?._id}
                    appDetailsObj={app_object}
                    groupAppOrder={currentOtherApps}
                    currentAppOrder={currentAppOrder}
                    indexInGroup={index}
                    setCurrentAppOrder={setCurrentAppOrder}
                  />
                );
              })}
            </div>
            )}
          </Flex>
          )}
        </div>
      ) : (
        <StatusAlert
          status="loading"
          alert_title="Fetching App Order"
        />
      )}
    </div>
  );
};

export default AppLists;
