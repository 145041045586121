import React, { useEffect } from 'react';
import {
  VStack, useTheme, Box, Flex, Text,
} from '@chakra-ui/react';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import { useNavigate, useLocation } from 'react-router-dom';
import SectionHeader from './SectionHeader';
import SeeAllNavLink from '../NavMenu/SeeAllNavLink';
import { useFetchAllGroupsQuery } from '../../services/apis/groupsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import { getRelativeTime } from '../../utils/helpers';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import GuidelinesNotAccepted from '../ElementaryComponents/GuidelinesNotAccepted';

const MyGroups = ({ sectionTitle, noDataMessage = [], guidelines_accepted }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const accessibilityKeyHandler = useAccessibleNavigation();

  // Query Object for pinned groups
  const myGroupsQueryObj = {
    page_number: 1,
    page_size: 3,
    sort_order: 'desc',
    sort_by: 'created_at',
    pin: true,
    include_apps: true,
    include_courses: true,
  };
  const {
    data: myGroupsData,
    isFetching: isMyGroupsFetching,
    isError: isMyGroupsError,
    // error: myGroupsError,
    refetch: refetchMyGroups,
  } = useFetchAllGroupsQuery(myGroupsQueryObj);

  useEffect(() => {
    refetchMyGroups();
  }, [location.pathname, refetchMyGroups]);

  const getMyGroups = () => {
    if (isMyGroupsFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Groups Data..." />;
    // eslint-disable-next-line no-unused-expressions
    if (isMyGroupsError) null;

    return (
      myGroupsData?.data?.length === 0
        ? <DataUnavailable display_message={noDataMessage} />
        : (
          <VStack
            style={{
              ...theme.styles.global.container,
            }}
            minHeight="4rem"
            padding="1rem"
            alignSelf="flex-end"
            spacing={2}
            alignItems="flex-start"
          >
            {
            myGroupsData?.data.map((myGroupObj) => {
              const {
                title, post_count, comment_count, created_at, _id, latest_post,
              } = myGroupObj;
              const navigatePath = `/forum/${_id}`;
              return (
                <Box
                  key={_id}
                  role="link"
                  tabIndex={0}
                  onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
                  style={{
                    borderBottom: '1px solid #CED8DD',
                    marginBottom: '1rem',
                    cursor: 'pointer',
                  }}
                  pb="0.5rem"
                  width="100%"
                  onClick={() => navigate(navigatePath)}
                >
                  <Flex justify="space-between" width="100%">
                    <Box>
                      <Text
                        style={{
                          ...theme.styles.global.h4,
                          display: 'inline-block',
                          fontWeight: 700,
                        }}
                        color="gray.800"
                      >
                        {parse(title)}
                      </Text>
                    </Box>
                    <Box
                      minW="3rem"
                      ml="auto"
                      style={{
                        textAlign: 'right',
                        ...theme.styles.global.body,
                        fontWeight: 'normal',
                        color: 'gray.600',
                      }}
                    >
                      {
                        getRelativeTime(latest_post?.created_at || created_at)
                      }
                    </Box>
                  </Flex>

                  <Flex width="100%" gap="0.5rem">
                    <Text variant="details">
                      {pluralize('post', post_count, true)}
                    </Text>
                    <Text variant="details">
                      {pluralize('reply', comment_count, true)}
                    </Text>
                  </Flex>

                </Box>
              );
            })
          }

            <SeeAllNavLink navigate_to="/forum" display_message="See All" />
          </VStack>
        )
    );
  };
  return (
    <Box>
      <SectionHeader
        sectionTitle={sectionTitle}
      />
      {guidelines_accepted
        ? getMyGroups()
        : (
          <GuidelinesNotAccepted
            display_message={[
              { btn_text: 'Agree to the user guidelines ' },
              ' to access groups in the forum.']}
            btn_type="text"
          />
        )}
    </Box>
  );
};

export default MyGroups;
