/* eslint-disable consistent-return */
import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useTheme,
  Box,
} from '@chakra-ui/react';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { useCreateGroupMutation } from '../../services/apis/groupsAPI';
import TagsInput from '../ElementaryComponents/TagsInput';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';

const CreateGroupModals = ({ buttonLabel }) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { auth } = useContext(TEEAuthDataContext);
  const [tags, setTags] = useState([]);
  const [groupTitle, setGroupTitle] = useState('');
  const [groupTitleLength, setGroupTitleLength] = useState(0);
  const [groupDescription, setGroupDescription] = useState('');
  const [groupDescriptionLength, setGroupDescriptionLength] = useState(0);
  const [createGroup, {
    isLoading, isError, error, reset,
  }] = useCreateGroupMutation();
  const isButtonDisabled = !removeHTMLAndSpaces(groupTitle)
    || !removeHTMLAndSpaces(groupDescription)
    || groupDescriptionLength > 560 || groupTitleLength > 64;

  const onClose = () => {
    // reset the form.
    reset();
    setTags([]);
    setGroupTitle('');
    setGroupDescription('');
    setGroupTitleLength(0);
    setGroupDescriptionLength(0);
    onModalClose();
  };

  const handleCreateGroup = async () => {
    const newGroup = {
      author_id: sanitizeData(auth.user.profile.sub),
      title: sanitizeData(groupTitle),
      description: sanitizeData(groupDescription),
      tags: sanitizeData(tags, 'arr_of_str'),
    };
    try {
      await createGroup(newGroup).unwrap();
      onClose();
      setTags([]);
    } catch (err) {
      console.log('Error', err);
    }
  };
  return (
    <Box>
      <Button
        id="create-group"
        colorScheme="blue"
        bg="blue.500"
        onClick={onOpen}
        style={{ ...theme.styles.global.button, fontWeight: '600' }}
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="757px" minHeight="580px" borderTop="8px solid" borderColor="blue.600">
          <ModalHeader style={{ ...theme.styles.global.h3 }}>Create Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl id="group_modal" isRequired mb="1rem">
                <FormLabel style={{
                  ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                }}
                >
                  Group Name
                </FormLabel>
                <Input
                  onChange={(e) => {
                    const inputText = e.target.value;
                    setGroupTitle(inputText);
                    setGroupTitleLength(inputText?.length);
                  }}
                  placeholder="Please enter group name"
                  isInvalid={groupTitleLength > 64}
                />
                <MaxCharExceedError
                  message="Group name"
                  charCount={groupTitleLength}
                />
              </FormControl>
              <TagsInput
                tags={tags}
                setTags={setTags}
                label="Group Tags"
              />
              <FormControl id="description" mt={4} isRequired>
                <FormLabel style={{
                  ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                }}
                >
                  Group Description
                </FormLabel>
                <QuillTextEditor
                  placeholder="Please enter group description"
                  quillText={groupDescription}
                  setQuillText={setGroupDescription}
                  customStyle={{ margin: 0 }}
                  setQuillCharCount={setGroupDescriptionLength}
                  quillCharCount={groupDescriptionLength}
                />
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter alignSelf="flex-start" mt="3rem" width="100%">
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
              style={{ ...theme.styles.global.body }}
              w="5rem"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              size="sm"
              style={{ ...theme.styles.global.body }}
              onClick={() => handleCreateGroup()}
              isDisabled={isLoading || isButtonDisabled}
              _hover={{ opacity: isButtonDisabled ? 0.4 : 0.9 }}
              w="5rem"
            >
              Save
            </Button>
          </ModalFooter>
          {
            isLoading && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="loading" alert_title="Creating Group..." />
            </Box>
            )
          }
          {
            isError && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Box>
            )
          }
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateGroupModals;
