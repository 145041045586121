/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import moment from 'moment';
import DOMPurify from 'dompurify';
import { quillClassToStyle } from './quillStyles';

export const addTimeStamp = (fileName) => {
  const nameWithoutSpace = fileName.replace(/\s+/g, '_');
  const currentTimeStamp = moment().format('YYDDMM_HHmmss');

  const indexOfLastDot = nameWithoutSpace.lastIndexOf('.');
  const fileNameWithTimeStamp = `${nameWithoutSpace.substring(0, indexOfLastDot)}_${currentTimeStamp}${nameWithoutSpace.substring(indexOfLastDot)}`;
  return fileNameWithTimeStamp;
};

export const getInitialsFromName = (fullName) => {
  const nameParts = fullName.split(' ');

  const initialsParts = nameParts.map((namePart, index, array) => {
    const isFirstOrLast = index === 0 || index === array.length - 1;
    return isFirstOrLast ? namePart[0] : null;
  });

  const initials = initialsParts.join('').toUpperCase();

  return initials;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Changes a string to a format that can be used for both an id and a hash in a url.
 * @param {string} string - The string to parse
 * @returns {string} - A string in all lowercase with _ instead of spaces
 */
export const stringToID = (string) => string.toLowerCase().split(' ').join('_');

export const getRelativeTime = (date) => {
  const units = [
    { name: 'y', minutes: 365 * 24 * 60 },
    { name: 'mo', minutes: 30 * 24 * 60 },
    { name: 'w', minutes: 7 * 24 * 60 },
    { name: 'd', minutes: 24 * 60 },
    { name: 'h', minutes: 60 },
    { name: 'm', minutes: 1 },
  ];

  const now = moment();
  const backendMoment = moment(date);
  const diffInMinutes = now.diff(backendMoment, 'minutes');

  for (let i = 0; i < units.length; i++) {
    if (diffInMinutes >= units[i].minutes) {
      const value = Math.floor(diffInMinutes / units[i].minutes);
      return `${value}${units[i].name}`;
    }
  }

  return 'just now';
};

export const getFormattedExactTime = (date) => {
  const exactTime = moment(date).format('hh:mm A [on] MM/DD/YY');
  return exactTime;
};
export const getFormattedNotificationTime = (date) => {
  const exactTime = moment(date).format('h:mma, MMM D, YYYY');
  return exactTime;
};

export const sanitizeData = (data, data_type = 'str') => {
  if (data_type === 'str') {
    return DOMPurify.sanitize(data, { ADD_ATTR: ['target'] })?.trim();
  } if (data_type === 'arr_of_str') {
    const sanitizedArray = data.map((item) => DOMPurify.sanitize(item, { ADD_ATTR: ['target'] })?.trim());
    return sanitizedArray;
  }
  return data;
};

/** Extracts the state values from an array of RTK Query queries
 * @param {Array} values - An array of query values
 * @returns {Object} - An object with isFetching, isSuccess, isError, and error
 */
export function getQueryState(values) {
  // If any of the queries are loading, return true
  const isLoading = values.filter((v) => v.isLoading).length > 0;
  // If any of the queries are still fetching, return true
  const isFetching = values.filter((v) => v.isFetching).length > 0;
  // Only return isSuccess as true if all the queries are successful
  const isSuccess = values.filter((v) => v.isSuccess).length > 0;
  // Only return errors when isError is also true so we don't return cached errors
  const errors = values.filter((v) => v.isError).map((v) => v.error);
  const isError = errors.length > 0;

  return {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    errors,
  };
}

/** Combines values into a string, skipping any that are missing or falsey.
 *  BE CAREFUL, this will remove false and 0 from the array.
 *
 * @param {Object} opts - the options for this function.
 * @param {Array} opts.strings - An array of strings.
 * @param {string} opts.connectingString - Insert this between the joined strings.
 * @param {boolean} opts.joinLastWithAnd - Should the last two be joined with ", and"?
 * @returns {string} - A string joined with no falsey values.
 */
export function joinTruthyStrings(opts) {
  const {
    strings,
    joinLastWithAnd,
    connectingString = ', ',
  } = opts;
  if (!Array.isArray(strings)) return '';
  const toJoin = strings.filter((s) => s);
  if (!joinLastWithAnd) return toJoin.join(connectingString);
  const lastString = toJoin.pop();
  const withoutLastString = toJoin.join(connectingString);
  return [withoutLastString, lastString].join(`${connectingString}and `);
}

/** Removes html tags from a rich text field. Useful for detecting if a blank looking field has
 * unseen tags in it.
 * @param {string} content - The text that might have html tags in it.
 * @returns {string} - the cleaned text.
 */
export function removeHTMLTags(content) {
  const div = document.createElement('div');
  div.innerHTML = content;
  return div.textContent || div.innerText || '';
}

/** Removes html tags and spaces to make sure a string has content.
 * @param {string} content - The text to check.
 * @returns {string} - the cleaned text.
 */
export function removeHTMLAndSpaces(content) {
  return content.replace(/<[^>]+>|[ \t\r\n]/g, '');
}

export const getStatus = ({
  is_locked,
  is_hidden,
  user_locked,
  user_banned,
}) => {
  if (is_hidden) return 'HIDDEN';
  if (user_banned) return 'BANNED';
  if (is_locked || user_locked) return 'LOCKED';
  return 'NONE';
};

export const formatStatusText = ({
  can_view_hidden,
  includeStatusText,
  is_locked,
  is_hidden,
  user_locked,
  user_banned,
}) => {
  const status = getStatus({
    is_locked, is_hidden, user_locked, user_banned,
  });

  const showFlaggedItemTitle = is_hidden || is_locked || user_locked || user_banned;

  const isItemFlagged = is_hidden || is_locked;
  const isUserFlagged = user_locked || user_banned;

  let formatedStatusText = '';
  if (includeStatusText) {
    if (isItemFlagged && !isUserFlagged) {
      formatedStatusText = `[${capitalizeFirstLetter(status)}]`;
    } else if (isUserFlagged) {
      formatedStatusText = `[You are ${capitalizeFirstLetter(status)}]`;
    }
  }

  return { status, formatedStatusText, showFlaggedItemTitle };
};

/**
 * Formats a given link to ensure it includes a protocol scheme. If the link does not start with
 * 'http://' or 'https://', 'http://' is prepended to the link to form a complete URL.
 *
 * @param {string} link - The URL to format.
 * @returns {string} - The formatted URL with a protocol scheme.
 */
export const formatLink = (link) => {
  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    return `http://${link}`;
  }
  return link;
};

/**
 * Converts Quill editor classes to inline styles in the given HTML string.
 *
 * @param {string} html - The HTML content from the Quill editor.
 * @returns {string} - The processed HTML content with inline styles.
 */

export const convertQuillClassesToInlineStyles = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  Object.keys(quillClassToStyle).forEach((quillClass) => {
    const elements = doc.querySelectorAll(`.${quillClass}`);
    elements.forEach((element) => {
      const existingStyle = element.getAttribute('style') || '';
      element.setAttribute('style', `${existingStyle} ${quillClassToStyle[quillClass]}`);
      element.classList.remove(quillClass);
    });
  });

  return doc.body.innerHTML;
};
