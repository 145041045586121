import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useTheme,
  Box,
} from '@chakra-ui/react';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { useParams } from 'react-router-dom';
import TagsInput from '../ElementaryComponents/TagsInput';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { useCreatePostMutation } from '../../services/apis/postsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';

const PostModal = ({
  groupId: propGroupId,
  buttonLabel,
  variant = 'solid',
  customBtnStyle = {},
}) => {
  const theme = useTheme();
  const { group_id: urlGroupId } = useParams();
  const groupId = propGroupId || urlGroupId;
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { auth } = useContext(TEEAuthDataContext);
  const [tags, setTags] = useState([]);
  const [createPost, { isLoading, isError, error }] = useCreatePostMutation();
  const [postTitle, setPostTitle] = useState('');
  const [postTitleLength, setPostTitleLength] = useState(0);
  const [postDescription, setPostDescription] = useState('');
  const [postDescriptionLength, setPostDescriptionLength] = useState('');
  const isButtonDisabled = !removeHTMLAndSpaces(postTitle)
    || !removeHTMLAndSpaces(postDescription)
    || postDescriptionLength > 560 || postTitleLength > 64;

  const onClose = () => {
    // reset the form.
    setTags([]);
    setPostTitle('');
    setPostDescription('');
    setPostTitleLength(0);
    setPostDescriptionLength(0);
    onModalClose();
  };

  const handleCreatePost = async () => {
    const newPostBody = {
      title: sanitizeData(postTitle),
      description: sanitizeData(postDescription),
      author_id: auth.user.profile.sub,
      group_id: groupId,
      tags: sanitizeData(tags, 'arr_of_str'),
    };
    try {
      await createPost(newPostBody).unwrap();
      onClose();
    } catch (err) {
      console.error('Failed to create a post', err);
    }
  };

  return (
    <Box>
      <Button
        colorScheme="blue"
        bg="blue.500"
        onClick={onOpen}
        variant={variant}
        style={{ fontWeight: '600', ...customBtnStyle }}
        size="sm"
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="757px" minHeight="580px" borderTop="8px solid" borderColor="blue.600" style={{ ...theme.styles.components.modalStyle }}>
          <ModalHeader style={{ ...theme.styles.global.h3 }}>New Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="form">
            <FormControl id="title" isRequired mb="1rem">
              <FormLabel style={{
                ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
              }}
              >
                Post Title
              </FormLabel>
              <Input
                value={postTitle}
                onChange={(e) => {
                  const inputText = e.target.value;
                  setPostTitle(e.target.value);
                  setPostTitleLength(inputText?.length);
                }}
                isInvalid={postTitleLength > 64}

              />
              <MaxCharExceedError
                message="Post name"
                charCount={postTitleLength}
              />

            </FormControl>

            <TagsInput
              tags={tags}
              setTags={setTags}
              label="Add Tags"
            />

            <Box>
              <FormControl id="description" mt={4} isRequired>
                <FormLabel style={{
                  ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                }}
                >
                  Post Description
                </FormLabel>
                <QuillTextEditor
                  quillText={postDescription}
                  setQuillText={setPostDescription}
                  customStyle={{ margin: 0 }}
                  setQuillCharCount={setPostDescriptionLength}
                  quillCharCount={postDescriptionLength}
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter justifyContent="flex-start" mt="1rem" width="100%">
            <Button
              className="change-focus"
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              color="blue.600"
              w="5rem"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              onClick={() => handleCreatePost()}
              size="sm"
              isDisabled={isButtonDisabled}
              _hover={{ opacity: isButtonDisabled ? 0.4 : 0.9 }}
              w="5rem"
            >
              Save
            </Button>
          </ModalFooter>
          {
            isLoading && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="loading" alert_title="Creating Post..." />
              </Box>
            )
          }
          {
            isError && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Box>
            )
          }
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PostModal;
