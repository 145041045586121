import apiService from '../apiService';

export const coursesAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllCourses: builder.query({
      query: (paramsObj) => ({
        url: '/courses/v1',
        params: paramsObj,
      }),
      providesTags: ['courses'],
    }),
    fetchCourseById: builder.query({
      query: (course_id) => ({
        url: `courses/v1/${course_id}`,
      }),
      providesTags: ['course'],
      invalidatesTags: ['courses'],
    }),
    updateCourseById: builder.mutation({
      query: ({ course_id, courseData }) => ({
        url: `courses/v1/${course_id}`,
        method: 'PUT',
        body: courseData,
      }),
      // invalidatesTags: ['course', 'courses', 'updates'],
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(apiService.util.invalidateTags(['course', 'courses', 'updates']));
        } catch {
          console.error('Error');
        }
      },
    }),
    deleteCourse: builder.mutation({
      query: (course_id) => ({
        url: `courses/v1/${course_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['courses', 'updates', 'course-updates'],
    }),
    createCourse: builder.mutation({
      query: ({ newCourseData, create_group }) => {
        return {
          url: `courses/v1/?create_group=${create_group}`,
          method: 'POST',
          body: newCourseData,
        };
      },
      invalidatesTags: ['courses', 'updates', 'course-updates'],
    }),
    createCourseGroup: builder.mutation({
      query: (course_id) => ({
        url: `groups/v1/courses/${course_id}`,
        method: 'POST',
      }),
      invalidatesTags: ['course', 'courses', 'updates', 'course-updates'],
    }),
    getPresignedUrl: builder.query({
      query: (preSignedUrlPath) => ({
        url: preSignedUrlPath,
      }),
      invalidatesTags: ['courses'],
    }),
    uploadCourseToS3: builder.mutation({
      query: ({ fileKey, file, config }) => ({
        url: fileKey,
        method: 'PUT',
        body: file,
        prepareHeaders: () => {
          return config;
        },
      }),
      invalidatesTags: ['courses'],
    }),
    fetchCourseUpdates: builder.query({
      query: (paramsObj) => ({
        url: '/courses/v1/updates',
        params: paramsObj,
      }),
      providesTags: ['course-updates'],
    }),
    reactToCourse: builder.mutation({
      query: ({ course_id, reaction_type }) => ({
        url: `courses/v1/${course_id}/reactions?reaction_type=${reaction_type}`,
        method: 'POST',
      }),
      async onQueryStarted({ course_id, user_id, reaction_type }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiService.util.updateQueryData('fetchCourseById', course_id, (draft) => {
            const reaction = draft.data.reactions[reaction_type];
            if (reaction.users.includes(user_id)) {
              const index = reaction.users.indexOf(user_id);
              if (index !== -1) {
                reaction.users.splice(index, 1);
              }
              delete reaction.reacted;
            } else {
              reaction.users.push(user_id);
              reaction.reacted = true;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useFetchAllCoursesQuery,
  useFetchCourseByIdQuery,
  useDeleteCourseMutation,
  useUpdateCourseByIdMutation,
  useCreateCourseMutation,
  useCreateCourseGroupMutation,
  useGetPresignedUrlQuery,
  useUploadCourseToS3Mutation,
  useFetchCourseUpdatesQuery,
  useReactToCourseMutation,
} = coursesAPI;
