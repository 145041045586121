import React, { useState } from 'react';
import {
  Flex,
  HStack,
  Button,
  useTheme,
  Table,
  Tr,
  Td,
  Text,
  Tbody,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DownloadButton from '../ElementaryComponents/DownloadButton';
import DeleteModal from '../Modals/DeleteModal';

const ReportCard = ({ report }) => {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;

  const canDelete = loggedInUserId === report.author_id;

  const handleView = () => {
    navigate(`/analytics/${report._id}`, {
      state: { author_id: report?.author_id },
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Flex
      direction="row"
      alignItems="center"
      padding="1rem"
      width="full"
      borderBottom="1px solid #CED8DD"
    >
      <Flex flexGrow={1}>
        <Table variant="unstyled">
          <Tbody>
            <Tr
              style={{
                ...theme.styles.global.body,
              }}
            >
              <Td width="20%" flexShrink={0}>
                {' '}
                {report.start_date}
                {' '}
                to
                {' '}
                {report.end_date}
              </Td>
              <Td width="10%" flexShrink={0}>
                {report.report_type}
              </Td>
              <Td width="20%" flexShrink={0}>
                Created:
                {' '}
                {report.created_at}
              </Td>
              <Td width="20%" flexShrink={0}>
                by
                {' '}
                {report.author_body?.first_name}
                {' '}
                {report.author_body?.last_name}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Flex>
      {report.report_status !== 'Processing'
        && report.report_status !== 'Failed' && (
          <HStack minWidth="220px" justifyContent="flex-end" spacing={2}>
            {canDelete && (
              <DeleteModal
                itemId={report._id}
                deleteType="report"
                isIcon
                variant="outline"
                customBtnStyle={{ color: 'red' }}
              />
            )}
            <Button variant="noOutline" onClick={handleView}>
              View
            </Button>
            {showModal && (
              <div>
                <Button onClick={handleClose}>Close</Button>
                <pre>{report.csvContent}</pre>
              </div>
            )}
            <DownloadButton
              report_id={report._id}
              fileName={report?.file_object?.file_name}
              variant="outline"
              buttonLabel="Download"
              size="sm"
            />
          </HStack>
      )}
      {report.report_status === 'Failed' && (
        <HStack
          minWidth="220px"
          justifyContent="flex-start"
          spacing={2}
        >
          <Text color="red.600" fontWeight="bold" p={4}>
            Failed
          </Text>
          {canDelete && (
            <DeleteModal itemId={report._id} deleteType="report" isIcon />
          )}
        </HStack>
      )}
      {report.report_status === 'Processing' && (
        <HStack minWidth="220px" justifyContent="center" spacing={2}>
          <Text>Report is Processing</Text>
        </HStack>
      )}
    </Flex>
  );
};

export default ReportCard;
