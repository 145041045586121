import apiService from '../apiService';

export const postsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllPosts: builder.query({
      query: ({
        group_id, page_number, page_size, sort_by, sort_order, pin, query, last_created,
      }) => ({
        url: 'posts/v1',
        params: {
          group_id,
          page_number,
          page_size,
          sort_by,
          sort_order,
          pin,
          query,
          last_created,
        },
      }),
      providesTags: ['posts'],
    }),
    fetchPostById: builder.query({
      query: (post_id) => ({
        url: `posts/v1/${post_id}`,
      }),
      providesTags: ['post'],
      refetchOnMountOrArgChange: true,
    }),
    updatePostById: builder.mutation({
      query: ({ post_id, postData }) => ({
        url: `posts/v1/${post_id}`,
        method: 'PUT',
        body: postData,
      }),
      invalidatesTags: ['post', 'updates'],
    }),
    deletePost: builder.mutation({
      query: (post_id) => ({
        url: `posts/v1/${post_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['group', 'posts', 'groups', 'updates', 'post'],
    }),
    createPost: builder.mutation({
      query: (newPostData) => ({
        url: 'posts/v1',
        method: 'POST',
        body: newPostData,
      }),
      invalidatesTags: ['posts', 'updates', 'groups'],
    }),
    reactToPost: builder.mutation({
      query: ({ post_id, reaction_type }) => ({
        url: `posts/v1/${post_id}/reactions?reaction_type=${reaction_type}`,
        method: 'POST',
      }),
      async onQueryStarted({ post_id, user_id, reaction_type }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiService.util.updateQueryData('fetchPostById', post_id, (draft) => {
            const reaction = draft.data.reactions[reaction_type];
            if (reaction.reacted) {
              const index = reaction.users.indexOf(user_id);
              if (index !== -1) {
                reaction.users.splice(index, 1);
              }
              delete reaction.reacted;
            } else {
              reaction.users.push(user_id);
              reaction.reacted = true;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['posts'],
    }),
    createModerationReport: builder.mutation({
      query: (reportBodyObj) => ({
        url: 'posts/v1/report',
        method: 'POST',
        body: reportBodyObj,
      }),
      invalidatesTags: ['my-reports', 'user'],
    }),
    fetchAllModerationReports: builder.query({
      query: (paramsObj) => ({
        url: '/posts/v1/report',
        params: paramsObj,
      }),
      providesTags: ['reports'],
    }),
    fetchMyModerationReports: builder.query({
      query: (paramsObj) => ({
        url: '/posts/v1/report/me',
        params: paramsObj,
      }),
      providesTags: ['reports', 'my-reports'],
    }),
    fetchModerationReportById: builder.query({
      query: (report_post_id) => ({
        url: `posts/v1/report/${report_post_id}`,
      }),
      providesTags: ['report'],
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useFetchAllPostsQuery,
  useFetchPostByIdQuery,
  useUpdatePostByIdMutation,
  useDeletePostMutation,
  useCreatePostMutation,
  useReactToPostMutation,
  useCreateModerationReportMutation,
  useFetchAllModerationReportsQuery,
  useLazyFetchAllModerationReportsQuery,
  useFetchMyModerationReportsQuery,
  useFetchModerationReportByIdQuery,
} = postsAPI;
