const routes = {
  dashboard: { url: '/dashboard', name: 'Dashboard' },
  forum: { url: '/forum', name: 'Forum' },
  events: { url: '/events', name: 'Events' },
  messages: { url: '/messages', name: 'Messages' },
  notificationCenter: {
    url: '/notification-center',
    name: 'Notification Center',
  },
  training: { url: '/training', name: 'Training' },
  reports: { url: '/reports', name: 'Reports' },
  search: { url: '/search', name: 'Search' },
  help: { url: '/help', name: 'Help' },
  allApps: { url: '/all-apps', name: 'All Apps' },
  allUpdates: { url: '/all-updates', name: 'All Updates' },
  analytics: { url: '/analytics', name: 'Analytics' },
  infractions: { url: '/infractions', name: 'Infractions' },
  allNotifications: { url: '/all-notifications', name: 'All Notifications' },
  subscriptions: { url: '/subscriptions', name: 'Subscriptions' },
  authCallback: { url: '/callback', name: 'Auth Callback' },
  notFound: { url: '/not-found', name: 'Not Found' },
  communityGuideLines: {
    url: '/community-guidelines',
    name: 'Community Guidelines',
  },
};

export default routes;
