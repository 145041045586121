import React from 'react';
import {
  Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, useTheme,
} from '@chakra-ui/react';
import ReportContentModal from '../Modals/ReportContentModal';

const ActionPopOver = ({ moreVertPopOver, content_type, content_id }) => {
  const theme = useTheme();
  const handleButtonClick = (e) => {
    e.stopPropagation();
  };

  const handlePopoverClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          onKeyDown={handleButtonClick}
          onClick={handleButtonClick}
          width="44px"
          variant="ghost"
          aria-label="more actions"
        >
          {moreVertPopOver}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{ position: 'absolute', right: '8px' }}
        onClick={handlePopoverClick}
        onKeyDown={handlePopoverClick}
        cursor="not-allowed"
      >
        <PopoverBody
          style={{ ...theme.styles.components.popOverStyle }}
          padding="1.25rem 1rem"
        >
          <ReportContentModal
            content_type={content_type}
            content_id={content_id}
            customBtnStyle={{
              color: theme.colors.red[600],
              backgroundColor: 'white',
              border: `1px solid ${theme.colors.red[600]}`,
              height: '40px',
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ActionPopOver;
