let instance;
const globalConfig = {
  apiBaseUrl: '',
};
class BaseConfig {
  constructor() {
    this.baseUrl = '';
    if (instance) {
      throw new Error('New instance cannot be created!!');
    }
    instance = this;
  }

  // eslint-disable-next-line class-methods-use-this
  getPropertyByName(propertyName) {
    return globalConfig[propertyName];
  }

  // eslint-disable-next-line class-methods-use-this
  setPropertyValue(propertyName, propertyValue) {
    globalConfig[propertyName] = propertyValue;
  }
}
const baseConfigInstance = Object.freeze(new BaseConfig());

export default baseConfigInstance;
