/* eslint-disable no-underscore-dangle */
import apiService from '../apiService';

export const commentsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllComments: builder.query({
      query: (paramsObj) => ({
        url: '/comments/v1',
        params: paramsObj,
      }),
      providesTags: ['comments'],
    }),
    fetchCommentById: builder.query({
      query: ({ comment_id, queryParam }) => ({
        url: `comments/v1/${comment_id}`,
        params: queryParam,
      }),
    }),
    updateComment: builder.mutation({
      query: ({ comment_id, body }) => ({
        url: `comments/v1/${comment_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['comments', 'updates'],
    }),
    deleteCommentById: builder.mutation({
      query: (comment_id) => ({
        url: `comments/v1/${comment_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['comments', 'updates', 'posts'],
    }),
    addReactionToComment: builder.mutation({
      query: ({ comment_id, reaction_type }) => ({
        url: `comments/v1/${comment_id}/reactions?reaction_type=${reaction_type}`,
        method: 'POST',
      }),
      async onQueryStarted(
        {
          user_id, comment_id, reaction_type, queryParams,
        },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          apiService.util.updateQueryData(
            'fetchAllComments',
            queryParams,
            (draft) => {
              const comment = draft.data.find((com) => com._id === comment_id);
              if (!comment) return;

              const reaction = comment.reactions[reaction_type];
              if (reaction.reacted) {
                const index = reaction.users.indexOf(user_id);
                if (index !== -1) {
                  reaction.users.splice(index, 1);
                }
                delete reaction.reacted;
              } else {
                reaction.users.push(user_id);
                reaction.reacted = true;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['updates'],
    }),
    createComment: builder.mutation({
      query: (newComment) => ({
        url: 'comments/v1',
        method: 'POST',
        body: newComment,
      }),
      invalidatesTags: ['comments', 'group-updates', 'updates', 'posts'],
    }),
  }),
});

export const {
  useFetchAllCommentsQuery,
  useFetchCommentByIdQuery,
  useUpdateCommentMutation,
  useDeleteCommentByIdMutation,
  useAddReactionToCommentMutation,
  useCreateCommentMutation,
} = commentsAPI;
