import React from 'react';
import {
  useTheme,
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Text,
  Flex,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFetchAllPostsQuery } from '../../services/apis/postsAPI';
import { useFetchAllCoursesQuery } from '../../services/apis/coursesAPI';
import { getQueryState, stringToID } from '../../utils/helpers';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import SeeAllNavLink from '../NavMenu/SeeAllNavLink';
import {
  GroupsIcon,
  CollectionsBookmarkIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
} from '../../icons/index';
import ContentItem from '../ElementaryComponents/ContentItem';

const removeHashCharacter = (str) => str.slice(1);

const ExternalItemGroup = (props) => {
  const {
    theme,
    Icon,
    title,
    items = [],
    baseURL,
    seeAllURL,
    error,
  } = props;

  return (
    <Box flexGrow={1} flexBasis={1}>
      <Flex
        alignItems="center"
        gap="0.5rem"
        marginBottom="1rem"
        style={{
          color: theme.colors.blue['600'],
        }}
      >
        {Icon}
        <h3>{title}</h3>
      </Flex>
      {
        items.map((i) => {
          return (
            <ContentItem {...i} baseURL={baseURL} key={i._id} />
          );
        })
      }
      {items.length === 0 && (
        <Text>
          We don&apos;t have any content for this app yet.
        </Text>
      )}
      {error && (<StatusAlert status="error" error={error} />)}
      {items.length > 0 && (
        <Flex justifyContent="flex-end">
          <SeeAllNavLink navigate_to={seeAllURL} display_message="See More" />
        </Flex>
      )}
    </Box>
  );
};

const ExternalLink = ({ app_url, text }) => (
  <Link
    href={app_url}
    isExternal
    color="blue.500"
    display="flex"
    alignItems="center"
    gap="0.25rem"
  >
    {text}
    <ExternalLinkIcon mx="2px" />
  </Link>
);

const HelpCard = (props) => {
  const {
    _id,
    app_url,
    group_id,
    description,
    title,
    light_icon_url,
    isOpen,
    guidelines_accepted,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { hash } = location;

  const myPostsQueryObj = {
    group_id,
    page_number: 1,
    sort_by: 'likes',
    page_size: 3,
    sort_order: 'desc',
  };

  const myCoursesQueryObj = {
    app_id: _id,
    page_number: 1,
    sort_by: 'likes',
    page_size: 3,
    sort_order: 'desc',
  };

  const getPosts = useFetchAllPostsQuery(myPostsQueryObj, { skip: !group_id });
  const getCourses = useFetchAllCoursesQuery(myCoursesQueryObj, { skip: !_id });

  const {
    data: postData,
    isError: isPostError,
    error: postError,
  } = getPosts;
  const {
    data: courseData,
    isError: isCourseError,
    error: courseError,
  } = getCourses;

  const { isFetching } = getQueryState([
    getPosts,
    getCourses,
  ]);

  const appIconSize = isOpen ? '8rem' : '3rem';
  const appIconCorner = isOpen ? '0.75rem' : '0.25rem';

  const onCardToggle = () => {
    let parsedTitle = stringToID(title);
    // If the card is currently active, navigate to # to close all cards.
    if (removeHashCharacter(hash) === parsedTitle) parsedTitle = '';
    navigate(`#${parsedTitle}`);
  };

  const NotCommunity = title !== 'Community';

  return (
    <>
      {/*
        This div is the scrollTo target, and must be above the card so the top of the card is
        visible after scrolling, due to the top bar being absolutely positioned. Without this,
        the top of the card is under the top bar and not visible. -JJ
      */}
      <div
        id={stringToID(title)}
        style={{ marginBottom: '2rem' }}
      />
      <Flex
        style={{
          ...theme.styles.global.container,
          borderRadius: '0.5rem',
          width: '100%',
          padding: '16px',
        }}
      >
        <Flex flexGrow={1}>
          {light_icon_url && (
          <Box
            width={appIconSize}
            height={appIconSize}
            flexShrink={0}
            style={{
              backgroundImage: `url(${light_icon_url})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              marginRight: '2rem',
              borderRadius: appIconCorner,
            }}
          />
          )}
          <Flex
            flexDirection="column"
            gap="32px"
            flexGrow={1}
          >
            <Flex alignItems="center">
              <Button
                variant="unstyled"
                flexGrow={1}
                onClick={() => onCardToggle()}
              >
                <Heading
                  as="h2"
                  textAlign="left"
                  style={{ ...theme.styles.global.h2, marginTop: '0.5rem', marginBottom: '0.75rem' }}
                  color="gray.800"
                >
                  {title}
                </Heading>
              </Button>
              <IconButton
                variant="unstyled"
                onClick={() => onCardToggle()}
                marginLeft="auto"
                aria-label={isOpen ? `Collapse the ${title} help card` : `Expand the ${title} help card`}
              >
                {isOpen ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            </Flex>
            {isOpen && (
              <>
                <Text>
                  {description}
                </Text>
                {app_url && NotCommunity && (
                  <ExternalLink
                    app_url={app_url}
                    text={`Open the ${title} app in a new tab`}
                  />
                )}
                {/* {documentation && (
                  <ExternalLink
                    app_url={documentation}
                    text={`Open the documentation for the ${title} app in a new tab`}
                  />
                )} */}
                <Box width="100%">
                  {isFetching ? (
                    <StatusAlert status="loading" alert_title="Loading..." />
                  ) : guidelines_accepted && (
                    <Flex gap="4rem">
                      <ExternalItemGroup
                        theme={theme}
                        Icon={<GroupsIcon fontSize="medium" />}
                        title="Top Discussions"
                        items={postData?.data ?? []}
                        baseURL={`/forum/${group_id}/`}
                        seeAllURL={`/forum/${group_id}`}
                        error={isPostError && postError}
                      />
                      <ExternalItemGroup
                        theme={theme}
                        Icon={<CollectionsBookmarkIcon fontSize="medium" />}
                        title="Exchange Trainings"
                        items={courseData?.data ?? []}
                        baseURL="/training/"
                        seeAllURL={`/training?app_id=${_id}`}
                        error={isCourseError && courseError}
                      />
                    </Flex>
                  )}
                </Box>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default HelpCard;
