/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useMemo } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Select,
  Flex,
  Box,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { ArrowDropDownIcon } from '../../icons';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import {
  useModerateCommentMutation,
  useModerateGroupMutation,
  useModeratePostMutation,
  useModerateUserMutation,
} from '../../services/apis/moderationAPI';
// import StatusAlert from '../ElementaryComponents/StatusAlert';
const moderateReasonsArr = [
  'Inappropriate Content',
  'Hostile Behavior',
  'Posted in the Wrong Place',
  'Other',
];

const action_btn_description = {
  lock: 'Content will be read-only. Only moderators and admins can interact.',
  unlock: 'This content will be unlocked.',
  hide: 'Content will be hidden. Only moderators and admins can view it.',
  unhide: 'The content will be restored.',
  user_lock: 'User can\'t post or reply, but current posts are still visible for replies or pins.',
  user_unlock: 'This user will be unlocked.',
  user_hide: 'User will be removed, and all posts and replies will be hidden.',
  user_unhide: 'This user and their content will be restored.',
  ignore: 'Remove this report from the Needs Action list, without taking any action on it.',
};

const ModerateModal = ({ title = '', reportData, isDisabled }) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [moderateReason, setModerateReason] = useState(moderateReasonsArr[0]);
  const [moderateReasonComment, setModerateReasonComment] = useState('');
  const [moderateReasonCommentLength, setModerateReasonCommentLength] = useState(0);
  const [
    moderateUser,
    {
      isLoading: isModeratingUser,
      isError: isModeratingUserError,
      error: moderatingUserError,
    },
  ] = useModerateUserMutation();
  const [
    moderateGroup,
    {
      isLoading: isModeratingGroup,
      isError: isModeratingGroupError,
      error: moderatingGroupError,
    },
  ] = useModerateGroupMutation();
  const [
    moderatePost, {
      isLoading: isModeratingPost,
      isError: isModeratingPostError,
      error: moderatingPostError,
    },
  ] = useModeratePostMutation();
  const [
    moderateComment, {
      isLoading: isModeratingComment,
      isError: isModeratingCommentError,
      error: moderatingCommentError,
    },
  ] = useModerateCommentMutation();
  const moderating_loading = isModeratingUser || isModeratingGroup
    || isModeratingPost || isModeratingComment;
  const is_moderating_error = isModeratingUserError || isModeratingGroupError
    || isModeratingPostError || isModeratingCommentError;
  const moderating_error = moderatingUserError || moderatingGroupError
    || moderatingPostError || moderatingCommentError;

  const isButtonDisabled = !removeHTMLAndSpaces(moderateReasonComment) || moderating_loading
  || moderateReasonCommentLength > 560;
  const report_status_for_ignore = ['pending', 'appeal'];

  const data = reportData?.data;
  const report_id = data?._id;
  const moderation_data = data?.moderation;
  const user_id = moderation_data?.object_author_id;
  const object_id = moderation_data?.object_id;
  const object_type = moderation_data?.object_type;
  const permissions = reportData?.permissions;
  const report_status = moderation_data?.report_status;
  const can_be_ignored = report_status_for_ignore.includes(report_status);

  const author_locked = moderation_data?.author_locked;
  const author_banned = moderation_data?.author_banned;
  const is_content_locked = moderation_data?.is_content_locked;
  const is_content_hidden = moderation_data?.is_content_hidden;

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const object_author_body = moderation_data?.object_author_body;
  const authorModGroupsList = object_author_body?.community_access?.group?.moderator;
  const object_group_id = moderation_data?.object_group_id;
  const object_author_id = moderation_data?.object_author_id;

  const isModeratingSelf = loggedInUserId === object_author_id;
  const isGroupModerator = authorModGroupsList.includes(object_group_id);

  const can_hide_user = permissions?.user?.can_hide && !isGroupModerator && !isModeratingSelf;
  const can_lock_user = permissions?.user?.can_lock && !isGroupModerator && !isModeratingSelf;

  const can_lock_content = permissions[object_type]?.can_lock;
  const can_hide_content = permissions[object_type]?.can_hide;

  const body = useMemo(() => {
    return {
      reason: sanitizeData(moderateReason),
      comment: sanitizeData(moderateReasonComment),
    };
  }, [moderateReason, moderateReasonComment]);

  // Handle content moderation
  const handleContent = useCallback(
    async (action) => {
      const params = {
        report_id,
        action,
      };

      const dataObj = {
        [`${object_type}_id`]: object_id,
        params,
        body,
      };

      try {
        let result;
        if (object_type === 'group') {
          result = await moderateGroup(dataObj).unwrap();
        } else if (object_type === 'post') {
          result = await moderatePost(dataObj).unwrap();
        } else if (object_type === 'comment') {
          result = await moderateComment(dataObj).unwrap();
        }

        console.log('result', result);
      } catch (error) {
        console.log('Error', error);
      }
    },
    [
      moderateGroup,
      moderatePost,
      moderateComment,
      report_id,
      object_id,
      object_type,
      body,
    ],
  );

  // Handle user moderation
  const handleUser = async (action) => {
    const params = {
      report_id,
      action,
    };

    try {
      await moderateUser({
        user_id,
        params,
        body,
      }).unwrap();
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        width="120px"
        isDisabled={isDisabled}
        variant="outline"
        colorScheme="red"
        style={{
          color: `${theme.colors.red[600]}`,
          border: `1px solid ${theme.colors.red[600]}`,
          padding: '0px 12px',
        }}
        size="sm"
      >
        Moderate
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalHeader>
            <Text style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
              Moderate
            </Text>
            <Text
              variant="italic"
              backgroundColor="#EFF4F6"
              p={2}
              mt={2}
              mr={0}
              color="gray.600"
              textAlign="left"
            >
              {parse(title)}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center">
              <Text style={{ ...theme.styles.global.h4 }} mb={2} mt={2}>
                Reason for Moderation
              </Text>
              <Select
                tabIndex={0}
                aria-label="sort by created at"
                width="max-content"
                icon={<ArrowDropDownIcon />}
                cursor="pointer"
                defaultValue="inappropriate_content"
                pr="0"
                ml={2}
                size="sm"
                color="gray.500"
                onChange={(e) => setModerateReason(e.target.value)}
              >
                {moderateReasonsArr.map((reason) => {
                  return (
                    <option value={reason} tabIndex={0} key={reason}>
                      {reason}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            <Text style={{ ...theme.styles.global.h4 }} mb={2} mt={8}>
              Record your reasons for the other moderators
            </Text>
            <QuillTextEditor
              quillText={moderateReasonComment}
              setQuillText={setModerateReasonComment}
              customStyle={{ margin: 0 }}
              toolBarType="2"
              setQuillCharCount={setModerateReasonCommentLength}
              quillCharCount={moderateReasonCommentLength}
            />
            {/* Action Buttons */}
            <Flex mt="2rem" gap={4} justifyContent="space-between">
              {/* Content section */}

              <Flex flexDirection="column" w="40%">
                <Text
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                  color="gray.600"
                >
                  Content
                </Text>
                {object_type !== 'comment' && can_lock_content && (
                <Flex flexDirection="column" gap={4} mt={4}>
                  <Button
                    className="change-focus"
                    colorScheme="orange"
                    variant="outline"
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      minWidth: '200px',
                      color: `${theme.colors.orange['600']}`,
                      borderColor: `${theme.colors.orange['600']}`,
                    }}
                    size="sm"
                    onClick={() => handleContent(is_content_locked ? 'unlock' : 'lock')}
                    isDisabled={!!isButtonDisabled}
                  >
                    {is_content_locked ? 'Unlock Content' : 'Lock Content'}
                  </Button>
                  <Text
                    variant="italic"
                    style={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {action_btn_description[is_content_locked ? 'unlock' : 'lock']}
                  </Text>
                </Flex>
                )}
                {can_hide_content && (
                <Flex flexDirection="column" gap={4} mt={4}>
                  <Button
                    className="change-focus"
                    colorScheme="red"
                    variant="solid"
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      minWidth: '200px',
                      backgroundColor: `${theme.colors.red['600']}`,
                    }}
                    size="sm"
                    onClick={() => handleContent(is_content_hidden ? 'unhide' : 'hide')}
                    isDisabled={isButtonDisabled}
                  >
                    {is_content_hidden ? 'Unhide Content' : 'Remove Content'}
                  </Button>
                  <Text
                    variant="italic"
                    style={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {action_btn_description[is_content_hidden ? 'unhide' : 'hide']}
                  </Text>
                </Flex>
                )}
              </Flex>
              <Box
                height="250px"
                width="1px"
                backgroundColor="gray.300"
              />
              {/* User section */}
              <Flex flexDirection="column" w="40%">
                <Text
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                  color="gray.600"
                >
                  User
                </Text>

                {can_lock_user && (
                <Flex flexDirection="column" gap={4} mt={4}>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      minWidth: '200px',
                    }}
                    size="sm"
                    onClick={() => handleUser(author_locked ? 'unlock' : 'lock')}
                    isDisabled={isButtonDisabled}
                  >
                    {author_locked ? 'Unlock User' : 'Lock User'}
                  </Button>
                  <Text
                    variant="italic"
                    style={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {action_btn_description[author_locked ? 'user_unlock' : 'user_lock']}
                  </Text>
                </Flex>
                )}
                {can_hide_user && (
                <Flex flexDirection="column" gap={4} mt={4}>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    color="red.600"
                    borderColor="red.600"
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      minWidth: '200px',
                    }}
                    size="sm"
                    onClick={() => handleUser(author_banned ? 'unhide' : 'hide')}
                    isDisabled={isButtonDisabled}
                  >
                    {author_banned ? 'Unban User' : 'Ban User'}
                  </Button>
                  <Text
                    variant="italic"
                    style={{ fontSize: '14px', textAlign: 'left' }}
                  >
                    {action_btn_description[author_banned ? 'user_unhide' : 'user_hide']}
                  </Text>
                </Flex>
                )}
              </Flex>
            </Flex>

            {
                can_be_ignored && (
                <Flex gap={4} justifyContent="space-between" mt={4}>
                  <Button
                    colorScheme="gray"
                    variant="outline"
                    color="graye.600"
                    borderColor="gray.400"
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      minWidth: '200px',
                    }}
                    size="sm"
                    onClick={() => handleContent('ignore')}
                    isDisabled={isButtonDisabled}
                    w="45%"
                  >
                    Ignore
                  </Button>
                  <Text
                    variant="italic"
                    style={{ fontSize: '14px', textAlign: 'left' }}
                    w="45%"
                  >
                    {action_btn_description.ignore}
                  </Text>
                </Flex>
                )
              }

          </ModalBody>
          <ModalFooter justifyContent="flex-end" mt="1rem" width="100%">
            <Button
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              w="108px"
            >
              Cancel
            </Button>
          </ModalFooter>
          {
            (moderating_loading) && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="loading" alert_title="Moderating..." />
              </Box>
            )
          }
          {
            (is_moderating_error) && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={moderating_error} />
              </Box>
            )
          }
        </ModalContent>
      </Modal>
    </>
  );
};
export default ModerateModal;
