/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Menu, MenuButton, MenuList, MenuItem, Icon, Button,
} from '@chakra-ui/react';
import { FaChevronDown, FaHome } from 'react-icons/fa';
import {
  DeleteIcon, LinkIcon, VideocamIcon, InsertDriveFileIcon, SlideshowIcon, InsertPhotoIcon,
} from '../../icons/index';

const DropdownWithIcons = ({
  menuOptions, selectedOption, setSelectedOption, readOnly,
}) => {
  const selectedIcon = menuOptions.filter(
    (option) => option.option_name.toLowerCase() === selectedOption.toLowerCase(),
  );
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant="outline"
            rightIcon={<FaChevronDown />}
            isActive={isOpen}
            readOnly={readOnly}
            size="md"
            aria-label="Select file type"
          >
            {selectedIcon[0].option_icon}
          </MenuButton>
          <MenuList>
            {menuOptions.map((menuItem) => {
              const { option_name, option_icon } = menuItem;
              return (
                <MenuItem
                  key={option_name}
                  icon={option_icon}
                  aria-label={option_name}
                  onClick={() => setSelectedOption(option_name)}
                >
                  {option_name}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DropdownWithIcons;
