import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store';
import App from './App';
import baseConfig from './baseConfig';

import reportWebVitals from './reportWebVitals';

// TODO: remove /community here if that is incorrect. I had to add it to get the app to work. -JJ
const configPath = process.env.REACT_APP_BASE_PATH ? `${process.env.REACT_APP_BASE_PATH}/config.json` : '/config.json';
fetch(configPath).then((res) => res.json()).then((config) => {
  baseConfig.setPropertyValue('apiBaseUrl', config.api.communityBaseUri);
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH ?? ''}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App appConfig={config} />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
  );
})
  .catch((err) => console.log('err', err));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
