import React from 'react';
import GuidelinesModal from './GuidelinesModal';

const DefaultOpenGuidlinesModal = ({ open_modal = false }) => {
  return (
    <GuidelinesModal
      open_modal={open_modal}
      custom_btn_style={{ display: 'hidden' }}
      btn_type="text"
    />
  );
};

export default DefaultOpenGuidlinesModal;
