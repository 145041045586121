import { Badge } from '@chakra-ui/react';
import React from 'react';

const CustomBadge = ({ itemCount, style = {} }) => {
  const defaultBadgeStyles = {
    position: 'absolute',
    top: '0',
    right: '-2px',
    borderRadius: 100,
  };

  const badgeStyles = { ...defaultBadgeStyles, ...style };

  return (
    <Badge style={badgeStyles}>
      {itemCount}
    </Badge>
  );
};

export default CustomBadge;
