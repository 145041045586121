import React from 'react';
import { Text, useTheme } from '@chakra-ui/react';
import parse from 'html-react-parser';
import {
  LockIcon, BlockIcon, ReportIcon, IgnoreIcon,
} from '../../icons/index';

const statusObj = {
  NONE: {
    icon: '',
    color: 'black',
  },
  LOCKED: {
    icon: <LockIcon style={{ marginRight: '5px' }} />,
    color: 'blue.800',
  },
  BANNED: {
    icon: <BlockIcon style={{ marginRight: '5px' }} />,
    color: 'red.700',
  },
  HIDDEN: {
    icon: <BlockIcon style={{ marginRight: '5px' }} />,
    color: 'red.700',
  },
  REMOVED: {
    icon: <BlockIcon style={{ marginRight: '5px' }} />,
    color: 'red.700',
  },
  PENDING: {
    icon: <ReportIcon style={{ marginRight: '5px' }} />,
    color: 'orange.500',
  },
  IGNORED: {
    icon: <IgnoreIcon style={{ marginRight: '5px' }} />,
    color: 'yellow.600',
  },
};

const FlaggedItemTitle = (props) => {
  const {
    title, customStyle = {}, status, formatedStatusText = '',
  } = props;
  const theme = useTheme();

  return (
    <Text
      display="flex"
      alignItems="center"
      style={{
        ...theme.styles.global.h4,
        ...customStyle,
        fontWeight: 700,
      }}
      color={statusObj[status].color}
    >
      {status !== 'NONE' && statusObj[status].icon}
      {' '}
      {parse(title)}
      {' '}
      {formatedStatusText}
    </Text>
  );
};
export default FlaggedItemTitle;
