/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
  FormControl, FormLabel, Input, useTheme, Box,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import TagsInput from '../ElementaryComponents/TagsInput';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { useUpdateGroupByIdMutation } from '../../services/apis/groupsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';

const EditGroupModal = ({
  buttonLabel,
  variant = 'solid',
  customBtnStyle = {},
  groupData,
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { group_id } = useParams();
  const [tags, setTags] = useState([]);
  const [groupTitle, setGroupTitle] = useState('');
  const [groupTitleLength, setGroupTitleLength] = useState(0);
  const [groupDescription, setGroupDescription] = useState('');
  const [groupDescriptionLength, setGroupDescriptionLength] = useState(0);
  const [updateGroupById, {
    isLoading, isError, error, reset,
  }] = useUpdateGroupByIdMutation();
  const isButtonDisabled = !removeHTMLAndSpaces(groupTitle)
    || !removeHTMLAndSpaces(groupDescription) || groupDescriptionLength > 560 || groupTitleLength > 64;

  useEffect(() => {
    if (groupData) {
      setTags(groupData.tags);
      setGroupTitle(groupData.title);
      setGroupTitleLength(groupData.title?.length);
      setGroupDescription(groupData.description);
      // Description length will be set by the Quill editor on mount.
    }
  }, [groupData]);

  const onClose = () => {
    // reset the form.
    reset();
    setTags(groupData?.tags ?? []);
    setGroupTitle(groupData?.title ?? '');
    setGroupDescription(groupData?.description ?? '');
    setGroupTitleLength(groupData.title?.length ?? 0);
    setGroupDescriptionLength(groupData.description?.length ?? 0);
    onModalClose();
  };

  const handleEditGroup = async () => {
    const sanitizedGroupData = {
      title: sanitizeData(groupTitle),
      tags: sanitizeData(tags, 'arr_of_str'),
      description: sanitizeData(groupDescription),
    };
    try {
      await updateGroupById({ group_id, groupData: sanitizedGroupData }).unwrap();
      onClose();
      setTags([]);
    } catch (err) {
      console.log('Error', err);
    }
  };

  return (
    <Box>
      <Button
        colorScheme="blue"
        bg={variant === 'solid' ? 'blue.500' : 'white'}
        onClick={onOpen}
        variant={variant}
        style={{ ...theme.styles.global.button, fontWeight: '600', ...customBtnStyle }}
        size="sm"
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="757px" minHeight="580px" borderTop="8px solid" borderColor="blue.600" style={{ ...theme.styles.components.modalStyle }}>
          <ModalHeader style={{ ...theme.styles.global.h3 }}>Edit Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl id="name" mb="1rem" isRequired>
                <FormLabel style={{
                  ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                }}
                >
                  Group Name
                </FormLabel>
                <Input
                  value={parse(groupTitle)}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    setGroupTitle(inputText);
                    setGroupTitleLength(inputText?.length);
                  }}
                  placeholder="Please enter group name"
                  isInvalid={groupTitleLength > 64}
                />
                <MaxCharExceedError
                  message="Group name"
                  charCount={groupTitleLength}
                />
              </FormControl>
              <TagsInput
                tags={tags}
                setTags={setTags}
                label="Group Tags"
              />
              <Box>
                <FormControl id="description" mt={4} isRequired>
                  <FormLabel style={{
                    ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                  }}
                  >
                    Group Description
                  </FormLabel>
                  <QuillTextEditor
                    placeholder="Please enter group description"
                    quillText={groupDescription}
                    setQuillText={setGroupDescription}
                    customStyle={{ margin: 0 }}
                    setQuillCharCount={setGroupDescriptionLength}
                    quillCharCount={groupDescriptionLength}
                  />

                </FormControl>
              </Box>
            </form>
          </ModalBody>

          <ModalFooter alignSelf="flex-start" mt="1rem" width="100%">
            <Button
              mr={3}
              className="change-focus"
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
              isDisabled={isLoading}
              w="5rem"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              onClick={() => handleEditGroup()}
              size="sm"
              isDisabled={isLoading || isButtonDisabled}
              w="5rem"
            >
              Save
            </Button>
          </ModalFooter>
          {
            isLoading && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="loading" alert_title="Updating Group..." />
              </Box>
            )
          }
          {
            isError && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Box>
            )
          }
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditGroupModal;
