import {
  Button, Flex, Text, useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotAuthorized = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Flex
      flexDirection="column"
      width="600px"
      textAlign="left"
      justifyContent="center"
      gap={4}
      margin="auto"
      mt="6rem"
    >
      <Text style={{ ...theme.styles.global.h2 }} color="blue.800">
        We’re sorry, you can’t access this page.
      </Text>
      <Text style={{ ...theme.styles.global.body }} color="blue.800">
        The page you’ve arrived on can’t be loaded. It might not exist, or you might
        need special permission to view it. Please check the URL in your address bar for errors.
      </Text>
      <Button
        mt="2rem"
        colorScheme="blue"
        bg="blue.500"
        width="max-content"
        height="3rem"
        onClick={() => navigate('/dashboard')}
        margin="auto"
      >
        Return To the Dashboard
      </Button>
    </Flex>
  );
};
export default NotAuthorized;
