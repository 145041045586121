import React, { useEffect } from 'react';
import {
  useTheme,
  Text,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from './UserAvatar';
import { joinTruthyStrings } from '../../utils/helpers';
import StatusAlert from './StatusAlert';
import DataUnavailable from './DataUnavailable';

const SimplifiedSearchedUsers = ({
  isSearchedUserFetching,
  isSearchUserError,
  searchUserError,
  searchedUserData,
  searchInputValue,
  handleSetUser,
  setShowSearchedUsers,
  showSearchedUsers,
  onClose,
}) => {
  const theme = useTheme();

  useEffect(() => {
    setShowSearchedUsers(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  if (isSearchedUserFetching) return <StatusAlert status="loading" alert_title="Fetching User..." />;
  if (isSearchUserError) return <StatusAlert status="error" error={searchUserError} />;
  if (!showSearchedUsers) return '';

  const allUsers = searchedUserData?.data;

  return (
    allUsers?.length === 0
      ? (
        <DataUnavailable
          display_message={[`${searchInputValue ? 'No user found!' : ''}`]}
          customStyle={{
            boxShadow: 'none',
            border: 'none',
            background: 'none',
            fontSize: '16px',
            lineHeight: '1.5',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        />
      )
      : allUsers?.map((userData) => {
        const {
          first_name, last_name, _id, email, job_title,
        } = userData;

        const userName = joinTruthyStrings({
          strings: [first_name, last_name],
          connectingString: ' ',
        });

        return (
          <Flex key={_id + Math.random()} flexDirection="column" pt={2} pb={2}>
            <Flex justifyContent="space-between" alignItems="center" gap={2}>
              <ProfileCardPopOver
                memberProfile={userData}
                userAvatar={(
                  <UserAvatar
                    name={userName}
                    user_profile_pic=""
                    avatarSize="XS"
                  />
                  )}
              />
              <Text style={{ ...theme.styles.global.h5 }}>
                {userName}
              </Text>
              <Spacer />
              <Text
                style={{ ...theme.styles.global.body, fontSize: '14px' }}
                color="blue.500"
                cursor="pointer"
                onClick={() => handleSetUser(_id, userName)}
              >
                Add
              </Text>
            </Flex>
            <Flex flexDirection="column" pl={8} mt={2} gap={1}>
              <Text style={{ ...theme.styles.global.body2 }}>{job_title }</Text>
              <Text style={{ ...theme.styles.global.body2 }}>{email}</Text>
            </Flex>
          </Flex>
        );
      })
  );
};

export default SimplifiedSearchedUsers;
