import React, { useState } from 'react';
import {
  Box,
  Tag,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Flex,
  TagLabel,
  TagCloseButton,
  useTheme,
} from '@chakra-ui/react';

const TagsInput = ({
  tags = [], setTags, label = 'Tags', isRequired,
}) => {
  const theme = useTheme();
  const [inputTag, setInputTag] = useState('');
  const [error, setError] = useState();

  /*
     ^: start of the string.
    (?![_\d-]): Negative lookahead. Cannot start with an underscore, digit, or dash.
    [\w-]{2,64}: Must be between 2 and 64 word characters (letters, digits, underscores) or dashes.
    The above match also rejects spaces or other separators other than _ or -.
    $: end of the string.
  */
  const isValidTag = (input) => /^(?![_\d-])[\w-]{2,64}$/.test(input);

  const tagIncluded = (input) => tags.some((tag) => tag.toLowerCase() === input.toLowerCase());

  const removeTags = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const addTags = (event) => {
    const inputTagValue = event.target.value.trim();

    if (event.key === 'Enter') {
      if (tagIncluded(inputTagValue)) {
        setError('You already entered this tag.');
      } else if (!isValidTag(inputTagValue)) {
        setError('Please enter a valid tag.');
      } else {
        setError('');
        setInputTag('');
        setTags((oldTags) => [...oldTags, inputTagValue]);
      }
    }
  };

  return (
    <Box>
      <Flex flexWrap="wrap" alignItems="center">
        <FormControl isInvalid={error} isRequired={isRequired}>
          <FormLabel
            style={{
              ...theme.styles.global.body,
              fontSize: '14px',
              margin: 0,
              marginBottom: '4px',
            }}
          >
            {label}
          </FormLabel>
          {tags?.map((tag) => (
            <Tag
              size="md"
              key={tag}
              variant="solid"
              color="white"
              bg="blue.500"
              mr={2}
              mb={2}
              fontFamily="Open Sans"
              fontWeight="bold"
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => removeTags(tag)} />
            </Tag>
          ))}
          <Input
            placeholder="Press enter to add tags"
            value={inputTag}
            onChange={(e) => setInputTag(e.target.value.trim())}
            onKeyDown={addTags}
            isInvalid={error}
            ml={2}
          />
          <FormHelperText variant="details">
            Press Enter to add a Tag. Tags must be between 2 and 64 characters,
            cannot start with a number or symbol, and cannot have spaces.
            Underscores and dashes are allowed.
          </FormHelperText>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
      </Flex>
    </Box>
  );
};

export default TagsInput;
