import React, { useEffect, useState } from 'react';
import {
  Box, Flex, Text, useTheme,
} from '@chakra-ui/react';
import {
  NavLink, useLocation, useParams,
} from 'react-router-dom';
import moment from 'moment';
import { useFetchAllEventsQuery } from '../services/apis/eventsAPI';
import EventsCard from '../components/Events/EventsCard';
import SectionContainer from '../components/SectionContainer';
import Event from '../components/Events/Event';
import CreateEventModal from '../components/Modals/CreateEventModal';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import SectionFooter from '../components/NavMenu/SectionFooter';

const Events = ({
  showNumberSelect = true, guidelines_accepted,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { event_id } = useParams();
  const today = moment();

  const [currentPageMyEvents, setCurrentPageMyEvents] = useState(1);
  const [selectedShowNumberMyEvents, setSelectedShowNumberMyEvents] = useState('5');
  const [myEventsSortOrder, setMyEventsSortOrder] = useState('asc');
  const [currentPageAllEvents, setCurrentPageAllEvents] = useState(1);
  const [selectedShowNumberAllEvents, setSelectedShowNumberAllEvents] = useState('5');
  const [allEventsSortOrder, setAllEventsSortOrder] = useState('asc');

  const [allEventsSearchQueryObj, setAllEventsSearchQueryObj] = useState({
    query: '',
    last_created: null,
    lat: '',
    lon: '',
    radius: 0,
  });
  const [myEventsSearchQueryObj, setMyEventsSearchQueryObj] = useState({
    query: '',
    last_created: null,
    lat: '',
    lon: '',
    radius: 0,
  });
  const [startDate, setStartDate] = useState(today);
  const [pinnedStartDate, setPinnedStartDate] = useState(today);
  const [endDate, setEndDate] = useState(null);
  const [pinnedEndDate, setPinnedEndDate] = useState(null);

  const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
  const formattedPinnedStartDate = pinnedStartDate ? moment(pinnedStartDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
  const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
  const formattedPinnedEndDate = pinnedEndDate ? moment(pinnedEndDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
  const sectionTitle = startDate && endDate
    ? `Events from ${moment(startDate).format('MMM Do, YYYY')} to ${moment(endDate).format('MMM Do, YYYY')}`
    : startDate
      ? `Events from ${moment(startDate).format('MMM Do, YYYY')}`
      : endDate
        ? `Events until ${moment(endDate).format('MMM Do, YYYY')}`
        : 'All Events';
  const pinnedSectionTitle = pinnedStartDate && pinnedEndDate
    ? `Pinned Events from ${moment(pinnedStartDate).format('MMM Do, YYYY')} to ${moment(pinnedEndDate).format('MMM Do, YYYY')}`
    : pinnedStartDate
      ? `Pinned Events from ${moment(pinnedStartDate).format('MMM Do, YYYY')}`
      : pinnedEndDate
        ? `Pinned Events until ${moment(pinnedEndDate).format('MMM Do, YYYY')}`
        : 'All Pinned Events';

  useEffect(() => {
    setAllEventsSearchQueryObj({
      query: '',
      last_created: null,
      lat: '',
      lon: '',
      radius: 0,
    });
    setMyEventsSearchQueryObj({
      query: '',
      last_created: null,
      lat: '',
      lon: '',
      radius: 0,
    });
  }, [location.pathname]);

  useEffect(() => {
    setCurrentPageMyEvents(1);
  }, [myEventsSearchQueryObj.query]);
  useEffect(() => {
    setCurrentPageAllEvents(1);
  }, [allEventsSearchQueryObj.query]);

  // Rtk Query
  // Query Objects
  const myEventsQueryObj = {
    page_number: currentPageMyEvents,
    page_size: selectedShowNumberMyEvents,
    sort_order: myEventsSortOrder,
    sort_by: 'start_date',
    pin: true,
    query: myEventsSearchQueryObj.query,
    last_created: myEventsSearchQueryObj.last_created,
    longitude: myEventsSearchQueryObj.lon,
    latitude: myEventsSearchQueryObj.lat,
    radius: myEventsSearchQueryObj.radius,
    ...formattedPinnedStartDate && { start_date: formattedPinnedStartDate },
    ...formattedPinnedEndDate && { end_date: formattedPinnedEndDate },
  };
  const allEventsQueryObj = {
    page_number: currentPageAllEvents,
    page_size: selectedShowNumberAllEvents,
    sort_order: allEventsSortOrder,
    sort_by: 'start_date',
    pin: false,
    query: allEventsSearchQueryObj.query,
    last_created: allEventsSearchQueryObj.last_created,
    longitude: allEventsSearchQueryObj.lon,
    latitude: allEventsSearchQueryObj.lat,
    radius: allEventsSearchQueryObj.radius,
    ...formattedStartDate && { start_date: formattedStartDate },
    ...formattedEndDate && { end_date: formattedEndDate },
  };

  const {
    data: myEventsData,
    isFetching: isMyEventsFetching,
    isError: isMyEventsError,
    error: myEventsError,
    refetch: refetchMyEvents,
  } = useFetchAllEventsQuery(myEventsQueryObj);
  const {
    data: allEventsData,
    isFetching: isAllEventsFetching,
    isError: isAllEventsError,
    error: allEventsError,
    refetch: refetchAllEvents,
  } = useFetchAllEventsQuery(allEventsQueryObj);

  const isEventsPath = location.pathname === '/events';
  const canCreate = allEventsData?.permissions?.event?.can_create;
  const eventsTitleStyle = {
    ...theme.styles.global.h1,
    color: `${theme.colors.blue[800]}`,
    display: 'inline-block',
  };

  useEffect(() => {
    refetchAllEvents();
    refetchMyEvents();
  }, [isEventsPath, refetchMyEvents, refetchAllEvents]);

  // render myEvents Component
  const myEventsComponent = () => {
    if (isMyEventsFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Events Data..." />;
    if (isMyEventsError) return <StatusAlert status="error" error={myEventsError} />;
    const eventsData = myEventsData?.data;
    return (
      <>
        <EventsCard
          eventsData={eventsData}
          is_pinned
          selectedShowNumber={selectedShowNumberMyEvents}
          noDataMessage={['Pin an event to see the latest updates from it and always have it at the top of this page.']}
        />
        {eventsData?.length > 0 && (
          <SectionFooter
            showNumberSelect={showNumberSelect}
            selectedShowNumber={selectedShowNumberMyEvents}
            setSelectedShowNumber={setSelectedShowNumberMyEvents}
            currentPage={currentPageMyEvents}
            setCurrentPage={setCurrentPageMyEvents}
            totalCount={myEventsData.meta?.total_pages}
          />
        )}
      </>
    );
  };

  // render allEvents Component
  const allEventsComponent = () => {
    if (isAllEventsFetching) return <StatusAlert status="loading" alert_title="Fetching All Events Data..." />;
    if (isAllEventsError) return <StatusAlert status="error" error={allEventsError} />;
    const eventsData = allEventsData?.data;
    return (
      <>
        <EventsCard
          eventsData={eventsData}
          is_pinned
          selectedShowNumber={selectedShowNumberAllEvents}
          noDataMessage={['There is no event available for this section.']}
        />
        {eventsData.length > 0 && (
        <SectionFooter
          showNumberSelect={showNumberSelect}
          selectedShowNumber={selectedShowNumberAllEvents}
          setSelectedShowNumber={setSelectedShowNumberAllEvents}
          currentPage={currentPageAllEvents}
          setCurrentPage={setCurrentPageAllEvents}
          totalCount={allEventsData.meta?.total_pages}
        />
        )}
      </>
    );
  };

  if (event_id) {
    return <Event guidelines_accepted={guidelines_accepted} />;
  }

  return (
    <Box w="full">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        {isEventsPath ? (
          <Text style={eventsTitleStyle}>Events</Text>
        ) : (
          <NavLink to="/events" style={eventsTitleStyle}>Events</NavLink>
        )}

        {
          isEventsPath && canCreate && guidelines_accepted
          && (
          <CreateEventModal
            buttonLabel="Create Event"
            title="My Modal"
          />
          )
        }
      </Flex>

      {/* Grid layout for Group Discussion page */}
      <Flex
        direction="column"
        gap="2rem"
        pt={4}
      >
        {/* My Events */}
        {
          isEventsPath
          && (
          <Box
            id="MyEvents"
            gridArea="myEvents"
            pt={0}
            pl={0}
          >
            <SectionContainer
              sectionTitle={pinnedSectionTitle}
              sectionInfoData={myEventsData}
              selectedShowNumber={selectedShowNumberMyEvents}
              setSelectedShowNumber={setSelectedShowNumberMyEvents}
              setSortOrder={setMyEventsSortOrder}
              childComponent={(myEventsComponent())}
              searchIcon
              setSearchQueryObj={setMyEventsSearchQueryObj}
              showTimePeriod={false}
              dateSearch
              defaultSort="asc"
              startDate={pinnedStartDate}
              setStartDate={setPinnedStartDate}
              endDate={pinnedEndDate}
              setEndDate={setPinnedEndDate}
              isLocationSearch
            />
          </Box>
          )
        }
        {/* All Events */}
        {
        isEventsPath
        && (
          <Box gridArea="allEvents" pt={0}>
            <SectionContainer
              sectionTitle={sectionTitle}
              sectionInfoData={allEventsData}
              setSortOrder={setAllEventsSortOrder}
              childComponent={(allEventsComponent())}
              searchIcon
              dateSearch
              defaultSort="asc"
              setSearchQueryObj={setAllEventsSearchQueryObj}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              showTimePeriod={false}
              isLocationSearch
            />
          </Box>
        )
        }
      </Flex>
    </Box>
  );
};

export default Events;
