import apiService from '../apiService';

export const reportsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAnalyticsReports: builder.query({
      query: (paramsObj) => ({
        url: '/users/v1/report',
        params: paramsObj,
      }),
      providesTags: ['reports'],
    }),
    createAnalyticsReport: builder.mutation({
      query: (newReportData) => ({
        url: '/users/v1/report',
        method: 'POST',
        body: newReportData,
      }),
      invalidatesTags: ['reports'],
    }),
    deleteAnalyticsReport: builder.mutation({
      query: (report_id) => ({
        url: `users/v1/report/${report_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['reports'],
    }),
    downloadAnalyticsReport: builder.query({
      query: (report_id) => `/users/v1/report/${report_id}/download`,
    }),
  }),
});

export const {
  useFetchAnalyticsReportsQuery,
  useCreateAnalyticsReportMutation,
  useDeleteAnalyticsReportMutation,
  useDownloadAnalyticsReportQuery,
} = reportsAPI;
