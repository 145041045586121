import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Flex, Text, useTheme, Grid,
} from '@chakra-ui/react';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFetchAllAppsQuery } from '../services/apis/appsAPI';
import { setPinnedApps, setYourOtherApps, setOtherAppsOnExchange } from '../slices/appsSlice';
import ProfileCardPopOver from '../components/PopOver/ProfileCardPopOver';

// Components
import PinnedApps from '../components/Dashboard/PinnedApps';
import Updates from '../components/Dashboard/Updates';
import MyGroups from '../components/Dashboard/MyGroups';
import MyTraining from '../components/Dashboard/MyTraining';
import UserAvatar from '../components/ElementaryComponents/UserAvatar';
import { useFetchAllUpdatesQuery } from '../services/apis/updatesAPI';
import { useGetUserQuery } from '../services/apis/usersAPI';
import DefaultOpenGuidlinesModal from '../components/Modals/DefaultOpenGuidlinesModal';

const Dashboard = () => {
  const theme = useTheme();
  const { auth } = useContext(TEEAuthDataContext);
  const loggedInUserProfile = auth?.user?.profile;
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPageUpdates, setCurrentPageUpdates] = useState(1);
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);

  const {
    data: userData,
    refetch: refetchUserData,
  } = useGetUserQuery();
  const latest_guidelines_status = userData?.data?.latest_guidelines_status;
  const user_email = loggedInUserProfile?.email;
  const guidelines_accepted = latest_guidelines_status === 'accepted';
  const default_open_modal = latest_guidelines_status === 'pending';

  const updatesQueryObj = {
    page_number: currentPageUpdates,
    page_size: selectedShowNumber,
    pin: true,
  };
  const {
    data: allUpdatesData,
    isFetching: isAllUpdatesFetching,
    isError: isAllUpdatesError,
    error: allUpdatesError,
    refetch: refetchAllUpdates,
  } = useFetchAllUpdatesQuery(updatesQueryObj);

  const {
    data: allAppsData,
    isFetching: isAllAppsFetching,
    isError: isAllAppsError,
    error: allAppsError,
    isSuccess: allAppsSuccess,
    refetch: refetchAllApps,
  } = useFetchAllAppsQuery();

  useEffect(() => {
    refetchAllUpdates();
    refetchAllApps();
    refetchUserData();
  }, [location.pathname, refetchAllApps, refetchAllUpdates, refetchUserData]);

  useEffect(() => {
    if (allAppsSuccess && allAppsData) {
      const { pinned_apps, your_other_apps, other_apps_on_exchange } = allAppsData.data[0];
      dispatch(setPinnedApps(pinned_apps));
      dispatch(setYourOtherApps(your_other_apps));
      dispatch(setOtherAppsOnExchange(other_apps_on_exchange));
    }
  }, [dispatch, allAppsSuccess, allAppsData]);

  return (
    <Box>
      {/* Top profile section */}
      <Flex
        borderBottom="1px solid #DDDDDD"
        pb="1rem"
        alignItems="center"
        gap="1rem"
      >
        <ProfileCardPopOver
          memberProfile={userData?.data}
          userAvatar={(
            <UserAvatar
              name={`${userData?.data?.first_name}  ${userData?.data?.last_name}`}
              image_url={userData?.data?.profile_url}
              avatarSize="XL"
              customStyle={{
                fontSize: '2.5rem',
              }}
            />
          )}
        />

        <Box color={theme.colors.blue[800]}>
          <Text style={{ ...theme.styles.global.h1 }} mb="0.25rem">
            Welcome back
            {' '}
            {loggedInUserProfile?.given_name}
            {' '}
            {loggedInUserProfile?.family_name}
            !
          </Text>
          {/* <Text style={{ ...theme.styles.global.h4 }}>
            You have
            {' '}
            <Text as="span" color={theme.colors.blue[500]} sx={{ fontWeight: '700' }}>
              25 updates
            </Text>
            {' '}
            on items you’ve pinned and
            {' '}
            <Text as="span" color={theme.colors.blue[500]} sx={{ fontWeight: '700' }}>
              3 new messages.
            </Text>
          </Text> */}
        </Box>
      </Flex>

      {/* Grid layout for Dashboard sections */}
      <Grid
        templateAreas={`"pinnedApp updates myGroups"
                      "pinnedApp updates myTraning"`}
        templateColumns="1.2fr 2fr 1.5fr"
        templateRows="auto 1fr"
        gap="2rem"
        pt={4}
      >
        {/* Pinned Apps section */}
        <Box gridArea="pinnedApp" pt={0} pl={0}>
          <PinnedApps
            isFetching={isAllAppsFetching}
            isError={isAllAppsError}
            error={allAppsError}
            guidelines_accepted={guidelines_accepted}
          />
        </Box>

        {/* Updates section */}
        <Box gridArea="updates" pt={0}>
          <Updates
            sectionTitle="Updates"
            currentPage={currentPageUpdates}
            setCurrentPage={setCurrentPageUpdates}
            updatesData={allUpdatesData}
            isFetchingUpdates={isAllUpdatesFetching}
            isFetchingUpdatesError={isAllUpdatesError}
            fetchingUpdatesError={allUpdatesError}
            selectedShowNumber={selectedShowNumber}
            setSelectedShowNumber={setSelectedShowNumber}
            pagination={false}
            guidelines_accepted={guidelines_accepted}
            noDataMessage={[
              'You don’t have any updates. Pin more',
              { text: 'groups or posts', path: '/forum' },
              'or',
              { text: 'trainings', path: '/training' },
              'to see their latest activity here.',
            ]}
          />
        </Box>

        {/* My Groups section */}
        <Box gridArea="myGroups" pt={0}>
          <MyGroups
            sectionTitle="Pinned Groups"
            guidelines_accepted={guidelines_accepted}
            noDataMessage={[
              {
                text: 'Pin a group',
                path: '/forum',
              },
              'to see the updates here.']}
          />
        </Box>

        {/* My Training section */}
        <Box gridArea="myTraning" pt={0}>
          <MyTraining
            sectionTitle="Pinned Trainings"
            guidelines_accepted={guidelines_accepted}
            noDataMessage={
            [
              {
                text: 'Pin a training',
                path: '/training',
              },
              'to see the updates here.']
        } />
        </Box>
      </Grid>
      {default_open_modal && user_email
        && (
        <DefaultOpenGuidlinesModal
          open_modal={default_open_modal}
        />
        )}
    </Box>
  );
};

export default Dashboard;
