import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useAccessibleNavigation = () => {
  const navigate = useNavigate();

  return useCallback((event, path, triggerOnSpace = true) => {
    if (event.keyCode === 13 || (triggerOnSpace && event.keyCode === 32)) {
      navigate(path);
      event.preventDefault();
    }
  }, [navigate]);
};

export default useAccessibleNavigation;
