import React, { useState } from 'react';
import {
  Box,
  Flex,
  SkipNavContent,
  useDisclosure,
} from '@chakra-ui/react';
import { Content } from '@texas-education-exchange/edx-portal-shared';
import LeftSideBar from './LeftSideBar';
import AppRouter from '../routes/AppRouter';
import { useGetUserQuery } from '../services/apis/usersAPI';
import LoadingModal from '../components/ElementaryComponents/LoadingModal';
import GuidelinesModal from '../components/Modals/GuidelinesModal';

const RootLayout = () => {
  const [navExpanded, setNavExpanded] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isGuidelinesModalOpen, setIsGuidelinesModalOpen] = useState(false);
  const {
    data: userData,
    isLoading: isUserDataLoading,
  } = useGetUserQuery();

  const handleGuidelinesClick = () => {
    onOpen();
    setIsGuidelinesModalOpen(true);
  };

  const guidelinesAccepted = userData?.data?.latest_guidelines_status === 'accepted';

  return (
    <Flex w="full">
      <LoadingModal isOpen={isUserDataLoading} />
      <Flex w="full">
        <Flex minH="100vh" mt="" h="auto">
          <LeftSideBar
            navExpanded={navExpanded}
            setNavExpanded={setNavExpanded}
            access_sidebar={userData?.access_sidebar}
            userData={userData}
            onGuidelinesClick={handleGuidelinesClick}
            ariaCurrentType="page"
          />
        </Flex>
        <Flex flex="1" marginLeft="20px" marginTop="10px" transition="margin-left 0.3s">
          <SkipNavContent />
          <Content marginTop="0px" maxW="1800px" w="full">
            <AppRouter />
          </Content>
        </Flex>
      </Flex>

      {/* Guidelines Modal */}
      {isGuidelinesModalOpen && (
        <Box display="none">
          <GuidelinesModal
            open_modal={isGuidelinesModalOpen}
            guidelines_accepted={guidelinesAccepted}
            onClose={onClose}
            isOpen={isOpen}
            onOpen={onOpen}
          />
        </Box>
      )}

    </Flex>
  );
};

export default RootLayout;
