import React from 'react';
import { Box } from '@chakra-ui/react';
import SectionHeader from './Dashboard/SectionHeader';

const SectionContainer = ({
  sectionTitle,
  sectionInfoData,
  childComponent,
  selectedShowNumber,
  setSelectedShowNumber,
  setSortOrder,
  searchIcon,
  setSearchQueryObj,
  showPostModal,
  showTimePeriod,
  groupId,
  dateSearch,
  setEndDate,
  endDate,
  setStartDate,
  startDate,
  defaultSort,
  customStyle = {},
  isLocationSearch,
}) => {
  return (
    <Box style={customStyle}>
      <SectionHeader
        sectionTitle={sectionTitle}
        orderFilter
        setSearchQueryObj={setSearchQueryObj}
        searchIcon={searchIcon}
        setSortOrder={setSortOrder}
        selectedShowNumber={selectedShowNumber}
        setSelectedShowNumber={setSelectedShowNumber}
        totalCount={sectionInfoData?.length}
        showPostModal={showPostModal}
        groupId={groupId}
        dateSearch={dateSearch}
        defaultSort={defaultSort}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showTimePeriod={showTimePeriod}
        isLocationSearch={isLocationSearch}
      />
      {childComponent}
    </Box>
  );
};

export default SectionContainer;
