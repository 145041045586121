/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Flex,
  Select,
  Box,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import moment from 'moment';
import parse from 'html-react-parser';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { useCreateNotificationMutation, useDeleteNotificationMutation } from '../../services/apis/updatesAPI';
import { convertQuillClassesToInlineStyles, removeHTMLAndSpaces, sanitizeData } from '../../utils/helpers';
import MomentDatePicker from '../ElementaryComponents/MomentDatePicker';
import MomentTimePicker from '../ElementaryComponents/MomentTimePicker';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';

const CreateNotificationModal = ({
  notifBtnText = 'Create a Notification',
  notifDescription = '',
  notifChannel = '',
  notifTitle = '',
  customBtnStyle = {},
  delete_notif_id = '',
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const today = moment();
  const [notificationSubject, setNotificationSubject] = useState('');
  const [notificationSubjectLength, setNotificationSubjectLength] = useState(0);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationTitleLength, setNotificationTitleLength] = useState(0);
  const [notificationChannel, setNotificationChannel] = useState(notifChannel || 'in-app');
  const [scheduledDate, setScheduledDate] = useState(today);
  const [scheduledTime, setScheduledTime] = useState(today);
  const [dateError, setDateError] = useState(false);
  const [createNotification, { isFetching, isError, error }] = useCreateNotificationMutation();
  const [
    deleteNotification,
  ] = useDeleteNotificationMutation();
  const combinedDate = moment(scheduledDate)
    .hour(moment(scheduledTime).hour())
    .minute(moment(scheduledTime).minute())
    .second(moment(scheduledTime).second())
    .toISOString();

  useEffect(() => {
    if (notificationChannel === 'email') {
      const title = notifChannel === 'email' ? notifDescription : '';
      const subject = notifChannel === 'email' ? notifTitle : '';
      setNotificationTitle(title);
      setNotificationSubject(subject);
      setNotificationTitleLength(title?.length || 0);
      setNotificationSubjectLength(subject?.length || 0);
    } else {
      const title = notifChannel === 'app' ? notifTitle : '';
      setNotificationTitle(title);
      setNotificationSubject('');
      setNotificationTitleLength(title?.length || 0);
      setNotificationSubjectLength(0);
    }
  }, [notifDescription, notifTitle, notificationChannel, notifChannel]);

  const charCountLimit = (notificationChannel === 'email'
    ? (notificationTitleLength === 0)
    || (notificationSubjectLength > 64 || notificationSubjectLength === 0)
    : notificationTitleLength > 150);

  const isButtonDisabled = !removeHTMLAndSpaces(combinedDate)
  || !removeHTMLAndSpaces(notificationTitle) || charCountLimit;
  const { auth } = useContext(TEEAuthDataContext);

  const userTimezone = moment.tz.guess();
  const TZAbbreviated = moment.tz(userTimezone).zoneAbbr();

  const handleDateChange = (date) => {
    setDateError(false);
    setScheduledDate(date);
  };

  const handleTimeChange = (time) => {
    setDateError(false);
    setScheduledTime(time);
  };

  const handleDeleteNotification = async () => {
    try {
      await deleteNotification(delete_notif_id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateNotification = async () => {
    const scheduled_at = moment(scheduledDate)
      .hour(moment(scheduledTime).hour())
      .minute(moment(scheduledTime).minute())
      .second(moment(scheduledTime).second())
      .toISOString();
    const momentDate = moment(scheduled_at);
    const timeDiff = momentDate.diff(moment(), 'minutes');

    if (timeDiff < 10) {
      setDateError(true);
    } else {
      const newGroup = {
        author_id: sanitizeData(auth.user.profile.sub),
        type: sanitizeData(notificationChannel),
        scheduled_at: combinedDate,
      };

      if (notificationChannel === 'email') {
        const processedContent = convertQuillClassesToInlineStyles(notificationTitle);
        newGroup.description = sanitizeData(processedContent);
        newGroup.title = sanitizeData(notificationSubject);
      } else {
        newGroup.title = sanitizeData(notificationTitle);
      }

      try {
        await createNotification(newGroup).unwrap();
        await handleDeleteNotification();
        onClose();
        setNotificationTitle('');
        setNotificationTitleLength(0);
        setNotificationSubject('');
        setNotificationSubjectLength(0);
      } catch (err) {
        console.log('Error', err);
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleOpen = () => {
    setNotificationChannel(notifChannel || 'in-app');
    if (notifChannel === 'email') {
      setNotificationTitle(notifDescription || '');
      setNotificationSubject(notifTitle || '');
      setNotificationTitleLength(notifDescription?.length || 0);
      setNotificationSubjectLength(notifTitle?.length || 0);
    } else {
      setNotificationTitle(notifTitle || '');
      setNotificationSubject('');
      setNotificationTitleLength(notifTitle?.length || 0);
      setNotificationSubjectLength(0);
    }

    setScheduledDate(today);
    setScheduledTime(today);
    onOpen();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        style={{
          backgroundColor: theme.colors.blue[500],
          color: 'white',
          padding: '0px 12px',
          ...customBtnStyle,
        }}
        variant="outline"
        size="sm"
        colorScheme="blue"
        color="blue.500"
        borderColor="blue.500"
      >
        {notifBtnText}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalHeader>
            <Text style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
              Create a Notification
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Channel</FormLabel>
              <Select
                width="300px"
                height="40px"
                onChange={(e) => {
                  setNotificationChannel(e.target.value);
                }}
                sx={{ padding: '0 12px' }}
                value={notificationChannel}
              >
                {['In App', 'Email']?.map((notif_type) => {
                  const type_value = notif_type === 'In App' ? 'in-app' : notif_type.toLowerCase();

                  return <option value={type_value} key={notif_type}>{notif_type}</option>;
                })}
              </Select>

            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Subject Line (email only)</FormLabel>
              <Input
                placeholder="Subject Line"
                onChange={(e) => {
                  const inputText = e.target.value;
                  setNotificationSubject(inputText);
                  setNotificationSubjectLength(inputText?.length);
                }}
                height="40px"
                sx={{ padding: '0 12px' }}
                value={parse(notificationSubject)}
                isDisabled={notificationChannel === 'in-app'}
                isInvalid={notificationChannel === 'email' && notificationSubjectLength > 64}
              />
              <MaxCharExceedError
                message="Notification subject"
                charCount={notificationSubjectLength}
              />
            </FormControl>

            <FormControl id="description" mt={4} isRequired>
              <FormLabel style={{
                ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
              }}
              >
                Notification Text
              </FormLabel>
              <QuillTextEditor
                quillText={notificationTitle}
                setQuillText={setNotificationTitle}
                customStyle={{ margin: 0 }}
                toolBarType="2"
                setQuillCharCount={setNotificationTitleLength}
                quillCharCount={notificationTitleLength}
                maxCharCount={notificationChannel === 'email' ? null : 150}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Schedule For</FormLabel>
              <Flex alignItems="flex-end">
                <Box>
                  <FormLabel style={{
                    ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                  }}
                  >
                    Date
                  </FormLabel>
                  <MomentDatePicker
                    value={scheduledDate}
                    minDate={today}
                    onChange={handleDateChange}
                  />

                </Box>
                <Text m={3} ml={0} mr={6} style={{ fontWeight: 700 }}>at</Text>
                <Box>
                  <FormLabel style={{
                    ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                  }}
                  >
                    Time
                    {' '}
                    {TZAbbreviated}
                  </FormLabel>
                  <MomentTimePicker
                    value={scheduledTime}
                    minValue={scheduledDate && scheduledDate.isSame(today, 'day') ? today : undefined}
                    onChange={handleTimeChange}
                  />
                </Box>
              </Flex>
              <Text
                color={dateError ? 'red.600' : 'black.800'}
                mt={4}
              >
                All notifications must be scheduled for at least 10 minutes in
                the future.
              </Text>
            </FormControl>

          </ModalBody>
          <ModalFooter justifyContent="flex-start" width="100%">
            <Button
              mr={3}
              onClick={handleClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              width="100px"
            >
              Cancel
            </Button>
            <Button
              mr={3}
              onClick={handleCreateNotification}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              width="100px"
              style={{
                backgroundColor: theme.colors.blue[500],
                color: 'white',
                border: `1px solid ${theme.colors.blue[500]}`,
                padding: '0px 12px',
              }}
              isDisabled={isButtonDisabled || dateError}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
        {isFetching && <StatusAlert status="loading" alert_title="Creating Notification" /> }
        {isError && <StatusAlert status="error" error={error} /> }
      </Modal>
    </>
  );
};
export default CreateNotificationModal;
