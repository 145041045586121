import React, { useState } from 'react';
import {
  Flex,
  Spacer,
  Select,
  Heading,
  useTheme,
  Text,
  Button,
} from '@chakra-ui/react';
import moment from 'moment';
import { SearchIcon, ArrowDropDownIcon, CancelIcon } from '../../icons/index';
import SearchPopOver from '../PopOver/SearchPopOver';
import PostModal from '../Modals/CreatePostModal';
import MomentDatePicker from '../ElementaryComponents/MomentDatePicker';

const SectionTopBar = ({
  sectionTitle,
  dateSearch = false,
  orderFilter = false,
  defaultSort = 'desc',
  setSortOrder,
  showNumberSelect = false,
  searchIcon = false,
  selectedShowNumber,
  setSelectedShowNumber,
  showTimePeriod,
  secondarySearchBtn = { buttonLabel: '', navLink: '' },
  titleSize = 'lg',
  titleElement = 'h3',
  setSearchQueryObj,
  showPostModal,
  groupId,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isLocationSearch,
}) => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [timePeriod, setTimePeriod] = useState('all');
  const [searchEntered, setSearchEntered] = useState(false);

  const paramsObj = {
    query: searchTerm,
  };

  if (typeof timePeriod === 'number') {
    paramsObj.last_created = timePeriod;
  } else {
    delete paramsObj.last_created;
  }

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchQueryObj({});
    setSearchEntered(false);
  };

  return (
    <Flex align="center" mb="0.5rem">
      <Heading
        as={titleElement}
        size={titleSize}
        style={{ ...theme.styles.global[titleElement] }}
        color="blue.800"
      >
        {sectionTitle}
      </Heading>
      <Spacer />
      {dateSearch && (
        <Flex alignItems="center">
          <Text pr="2" pt="1" color="gray.600">
            Show between
          </Text>
          <MomentDatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            minDate={moment('01/01/2023')}
          />
          <Text pr="2" pt="1" color="gray.600">
            and
          </Text>
          <MomentDatePicker
            value={endDate}
            onChange={(date) => setEndDate(date)}
            minDate={startDate}
          />
          <Button
            mt="1.5"
            mr="3"
            variant="text"
            color={theme.colors.gray['600']}
            size="md"
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          >
            Clear Dates
          </Button>
        </Flex>
      )}
      {orderFilter && (
        <Select
          aria-label="sort by created at"
          width="max-content"
          icon={<ArrowDropDownIcon />}
          cursor="pointer"
          defaultValue={defaultSort}
          sx={{ border: 'none' }}
          pr="0"
          size="sm"
          color="gray.600"
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="desc">
            Newest
          </option>
          <option value="asc">
            Oldest
          </option>
        </Select>
      )}
      {showNumberSelect && (
        <Select
          width="max-content"
          minWidth="90px"
          aria-label="set item display size"
          icon={<ArrowDropDownIcon />}
          iconSize="sm"
          cursor="pointer"
          sx={{ border: 'none' }}
          colorScheme="purple"
          defaultValue={selectedShowNumber}
          mr="0.5rem"
          size="sm"
          pr="0"
          color="gray.600"
          onChange={(e) => setSelectedShowNumber(e.target.value)}
        >
          <option value="3">Show 3</option>
          <option value="5">Show 5</option>
        </Select>
      )}
      {searchIcon && (
        <SearchPopOver
          showTimePeriod={showTimePeriod}
          secondarySearchBtn={secondarySearchBtn}
          sectionTitle={sectionTitle}
          selectedShowNumber={selectedShowNumber}
          setSearchQueryObj={setSearchQueryObj}
          popOverIcon={<SearchIcon cursor="pointer" />}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
          setSearchEntered={setSearchEntered}
          isLocationSearch={isLocationSearch}
          searchEntered={searchEntered}
        />
      )}
      {searchIcon && searchEntered && (
        <CancelIcon cursor="pointer" onClick={handleClearSearch} />
      )}
      {showPostModal && <PostModal buttonLabel="New Post" groupId={groupId} />}
    </Flex>
  );
};

export default SectionTopBar;
