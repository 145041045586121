import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { SideBar } from '@texas-education-exchange/edx-portal-shared';
import { useNavigate } from 'react-router-dom';
import {
  DashboardIcon,
  GroupsIcon,
  HelpIcon,
  TrainingIcon,
  SearchIcon,
  MoreHorizIcon,
  EventsIcon,
  ReportsIcon,
  AssessmentIcon,
  WarningIcon,
  NotificationIcon,
} from '../icons/index';
import CustomBadge from '../components/ElementaryComponents/CustomBadge';
import routes from '../core/routes';

const LeftSidebar = ({
  navExpanded,
  setNavExpanded,
  access_sidebar = {},
  userData = {},
  onGuidelinesClick,
}) => {
  const iconStyle = {
    fontSize: '1.2rem',
  };
  const latest_guidelines_status = userData?.data?.latest_guidelines_status;
  const guidelines_accepted = latest_guidelines_status === 'accepted';
  const [showGuidelinesModal, setShowGuidelinesModal] = useState(
    !guidelines_accepted,
  );

  useEffect(() => {
    if (!showGuidelinesModal && !guidelines_accepted) {
      setShowGuidelinesModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guidelines_accepted]);

  const navigate = useNavigate();

  const [selectedItemId, setSelectedItemId] = useState('dashboard');

  const onChangeSelectedItemId = (id) => {
    setSelectedItemId(id);

    switch (id) {
      case 'dashboard':
        return navigate(routes.dashboard.url);
      case 'forums':
        return navigate(routes.forum.url);
      case 'trainings':
        return navigate(routes.training.url);
      case 'events':
        return navigate(routes.events.url);
      case 'search':
        return navigate(routes.search.url);
      case 'help':
        return navigate(routes.help.url);
      case 'moderations':
        return navigate(routes.reports.url);
      case 'analytics':
        return navigate(routes.analytics.url);
      case 'infractions':
        return navigate(routes.infractions.url);
      case 'notifications-center':
        return navigate(routes.notificationCenter.url);
      case 'site-tour':
        return navigate(`/${routes.dashboard.url}?startSiteTour=true&refreshTour=${Math.floor(
          Math.random() * 1000,
        )}`);
      case 'community-guidelines':
        return onGuidelinesClick();
      case 'subscriptions':
        return navigate(routes.subscriptions.url);
      default:
        return navigate(routes.dashboard.url);
    }
  };

  const items = [
    {
      id: 'dashboard',
      text: 'Dashboard',
      icon: (
        <DashboardIcon aria-label="Dashboard" style={{ ...iconStyle }} />
      ),
    },
    {
      id: 'forums',
      text: 'Forum',
      icon: <GroupsIcon aria-label="Forum" style={{ ...iconStyle }} />,
    },
    {
      id: 'trainings',
      text: 'Trainings',
      icon: (
        <TrainingIcon aria-label="Trainings" style={{ ...iconStyle }} />
      ),
    },
    {
      id: 'events',
      text: 'Events',
      icon: <EventsIcon aria-label="Events" style={{ ...iconStyle }} />,
    },
    {
      id: 'search',
      text: 'Search',
      icon: <SearchIcon aria-label="Search" style={{ ...iconStyle }} />,
    },
    {
      id: 'help',
      text: 'Help',
      icon: <HelpIcon aria-label="Help" style={{ ...iconStyle }} />,
    },
  ];

  if (access_sidebar?.moderation) {
    // Add moderation to the sidebar
    items.push(
      {
        id: 'moderations',
        text: 'Moderation',
        icon: (
          <div style={{ position: 'relative' }}>
            <ReportsIcon aria-label="Reports" style={{ ...iconStyle }} />
            <CustomBadge
              itemCount={access_sidebar?.pending_moderation_report}
              aria-label={`${access_sidebar?.pending_moderation_report} new reports`}
              style={{
                top: '-6px',
                right: '-10px',
                variant: 'subtle',
                fontSize: '0.75rem',
              }}
            />
          </div>
        ),
      },

    );
  }

  if (access_sidebar?.report) {
    // Add analytics to the sidebar
    items.push({
      id: 'analytics',
      text: 'Analytics',
      icon: <AssessmentIcon aria-label="Analytics" style={{ ...iconStyle }} />,
    });
  }

  // Add infractions to the sidebar
  items.push({
    id: 'infractions',
    text: 'Infractions',
    icon: <WarningIcon aria-label="Infractions" style={{ ...iconStyle }} />,
  });

  if (access_sidebar?.notification) {
    // Add notification center to the sidebar
    items.push({
      id: 'notifications-center',
      text: 'Notification Center',
      icon: <NotificationIcon aria-label="Notifications Center" style={{ ...iconStyle }} />,
    });
  }

  const extraSubItems = [
    { id: 'community-guidelines', text: 'Community Guidelines' },
    { id: 'subscriptions', text: 'Subscriptions' },
  ];

  if (guidelines_accepted) {
    // Add site tour to the sidebar
    extraSubItems.unshift({ id: 'site-tour', text: 'Site Tour' });
  }

  items.push({
    // Add extras to the sidebar
    id: 'extras',
    text: 'Extras',
    icon: <MoreHorizIcon aria-label="Extra Options" style={{ fontSize: '0.8rem' }} />,
    subItems: extraSubItems,
  });

  return (
    <Flex
      alignItems="center"
      id="LeftNavBar"
      bg="blue.600"
      flexDirection="column"
      height="100%"
      minHeight="84vh"
      cursor="pointer"
      onClick={() => setNavExpanded(!navExpanded)}
    >
      <SideBar
        data-testid="sidebar"
        show
        onClickItem={onChangeSelectedItemId}
        ariaCurrentType="page"
        items={items}
        selectedItemId={selectedItemId}
        borderColor="hsla(0, 0%, 100%, 0.1)"
        hoverColor="hsla(0, 0%, 100%, 0.1)"
        activeColor="hsla(0, 0%, 100%, 0.1)"
        style={{ gap: '10px' }}
      />
    </Flex>

  );
};

export default LeftSidebar;
