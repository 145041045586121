import React, { useState } from 'react';
import {
  Flex, Text, Box, Grid,
  useTheme, InputGroup, InputRightElement, Input, Stack,
} from '@chakra-ui/react';
import { useFetchAllAppsQuery } from '../../services/apis/appsAPI';
import { useGetUserQuery } from '../../services/apis/usersAPI';
import { SearchIcon } from '../../icons';
import AppCard from '../Cards/AppCard';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import StatusAlert from '../ElementaryComponents/StatusAlert';

const AppSubsections = ({
  subsection = '',
  subsection_title,
  my_apps,
  is_pinned,
}) => {
  const theme = useTheme();
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
  } = useGetUserQuery();
  const latest_guidelines_status = userData?.data?.latest_guidelines_status;
  const guidelines_accepted = latest_guidelines_status === 'accepted';

  const {
    data: allAppsData,
    isFetching: isAllAppsFetching,
    isError: isAllAppsError,
    error: allAppsError,
  } = useFetchAllAppsQuery(null, { skip: isUserDataLoading || !isUserDataSuccess });

  const apps_data = (allAppsData?.data[0]?.[subsection] ?? [])
    .filter((a) => a.show_in_app_launcher);

  const [searchQuery, setSearchQuery] = useState('');

  const searched_apps = apps_data.filter((appObj) => {
    if (searchQuery) return appObj.title.toLowerCase().includes(searchQuery.toLowerCase());
    return apps_data;
  });

  const getApps = () => {
    if (isAllAppsFetching) return <StatusAlert status="loading" alert_title={`Fetching ${subsection_title}`} />;
    if (isAllAppsError) return <StatusAlert status="error" error={allAppsError} />;

    if (searched_apps.length === 0) return <DataUnavailable display_message={[`You don't have any ${subsection_title}`]} />;

    return searched_apps.map((appDetailsObj) => {
      const { _id } = appDetailsObj;
      return (
        <AppCard
          appDetailsObj={appDetailsObj}
          key={_id}
          my_apps={my_apps}
          is_pinned={is_pinned}
          guidelines_accepted={guidelines_accepted}
        />
      );
    });
  };

  return (
    <Box>
      <Flex justifyContent="space-between" width="100%" alignItems="flex-end" mb="0.5rem" mt="2rem" aria-label={subsection_title}>
        <Box>
          <Text
            style={{ ...theme.styles.global.h3 }}
            color="blue.800"
          >
            {subsection_title}
          </Text>
        </Box>
        <Flex alignItems="center">
          <Stack>
            <InputGroup sx={{ borderRadius: '1rem', verticalAlign: 'center' }} size="sm">
              <InputRightElement pointerEvents="none">
                <SearchIcon />
              </InputRightElement>
              <Input
                type="text"
                placeholder={`${subsection_title}`}
                width="200px"
                fontSize="0.7rem"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </Stack>
        </Flex>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" rowGap={3} columnGap={6} width="100%">
        {
          getApps()
        }
      </Grid>
    </Box>
  );
};

export default AppSubsections;
