/* eslint-disable no-unused-vars */
import {
  Button, Flex, Text, useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { useFetchSubscriptionsQuery, useUpdateSubscriptionsMutation } from '../services/apis/updatesAPI';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';

const subscriptionInfoText = {
  title: {
    subscribed: 'Unsubscribe to Email Notifications',
    unsubscribed: 'You Have Unsubscribed',
  },
  description: {
    subscribed: `Click below to stop receiving notifications from The Exchange in your email.
    You will continue to get notifications in The Exchange application, which you
    can view by clicking the notification icon at the top of the page.`,
    unsubscribed: `You will no longer receive notifications from The Exchange in your email. 
    You will continue to get notifications in The Exchange application, which you can view by 
    clicking the notification icon at the top of the page.`,
  },
  btnText: {
    subscribed: 'Unsubscribe',
    unsubscribed: 'Subscribe',
  },
};

const Subscriptions = () => {
  const theme = useTheme();

  const {
    data: subscriptionData,
    isFetching: isFetchingSubscriptionData,
    isError: isSubscriptionDataError,
    error: subscriptionDataError,
  } = useFetchSubscriptionsQuery();

  const [updateSubscriptions, {
    isLoading: isEditSubscriptionLoading,
    isError: isEditSubscriptionError,
    error: editSubscriptionError,
  }] = useUpdateSubscriptionsMutation();

  if (isFetchingSubscriptionData || isEditSubscriptionLoading) {
    return (
      <StatusAlert
        status="loading"
        alert_title="Updating Subscription Status"
      />
    );
  }
  if (isSubscriptionDataError || isEditSubscriptionError) {
    return <StatusAlert status="error" error={subscriptionDataError || editSubscriptionError} />;
  }

  const subscriptionStatus = subscriptionData?.status;

  const handleSubscription = async () => {
    try {
      await updateSubscriptions({ action: subscriptionStatus === 'subscribed' ? 'unsubscribe' : 'subscribe' });
    } catch (err) {
      console.error('Error updating training:', err);
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="70%"
      maxW="800px"
      textAlign="left"
      justifyContent="center"
      margin="auto"
      mt="6rem"
    >
      <Text
        style={{ ...theme.styles.global.h2, fontSize: '2rem' }}
        color="blue.800"
        mb="1rem"
      >
        {subscriptionInfoText.title[subscriptionStatus]}
      </Text>
      <Text
        style={{ ...theme.styles.global.body, fontSize: '1.25rem' }}
        color="blue.800"
        mb="2rem"
      >
        {subscriptionInfoText.description[subscriptionStatus]}
      </Text>
      <Button
        colorScheme="blue"
        bg="blue.500"
        width="max-content"
        height="2.5rem"
        w="180px"
        onClick={() => handleSubscription()}
        margin="auto"
      >
        {subscriptionInfoText.btnText[subscriptionStatus]}
      </Button>
    </Flex>
  );
};
export default Subscriptions;
