import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useTheme,
  Text,
  Box,
  VStack,
  Flex,
  CircularProgress,
  Link,
  Checkbox,
  Select,
  Spacer,
  Heading,
  IconButton,
  FormHelperText,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import axios from 'axios';
import {
  DeleteIcon,
  LinkIcon,
  VideocamIcon,
  InsertDriveFileIcon,
  SlideshowIcon,
  InsertPhotoIcon,
  SaveIcon,
  ReportProblemIcon,
} from '../../icons/index';
import TagsInput from '../ElementaryComponents/TagsInput';
import {
  useCreateCourseMutation,
  useCreateCourseGroupMutation,
  useGetPresignedUrlQuery,
  useUpdateCourseByIdMutation,
} from '../../services/apis/coursesAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import {
  sanitizeData,
  addTimeStamp,
  removeHTMLAndSpaces,
  formatLink,
} from '../../utils/helpers';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import DropdownWithIcons from '../ElementaryComponents/DropdownWithIcons';
import { useFetchAllAppsQuery } from '../../services/apis/appsAPI';
import { sortArrOfObject } from '../../utils/arrayOperations';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';
import { useDeleteGroupMutation } from '../../services/apis/groupsAPI';

const fileDropDownOptions = [
  {
    option_name: 'Link',
    option_icon: <LinkIcon />,
  },
  {
    option_name: 'Video',
    option_icon: <VideocamIcon />,
  },
  {
    option_name: 'Slides',
    option_icon: <SlideshowIcon />,
  },
  {
    option_name: 'Document',
    option_icon: <InsertDriveFileIcon />,
  },
  {
    option_name: 'Images',
    option_icon: <InsertPhotoIcon />,
  },
];

const allFileTypes = ['Link', 'Video', 'Slides', 'Document', 'Images'];

const CreateCourseModal = ({
  buttonLabel,
  variant = 'solid',
  customBtnStyle = {},
  isEditing,
  courseData,
  customSize = 'md',
}) => {
  const theme = useTheme();
  const { auth } = useContext(TEEAuthDataContext);
  const reqUserId = auth.user?.profile.sub;
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const [clearState, setClearState] = useState(false);
  const [tags, setTags] = useState([]);
  const [courseTitle, setCourseTitle] = useState('');
  const [courseTitleLength, setCourseTitleLength] = useState(0);
  const [courseDescription, setCourseDescription] = useState('');
  const [courseDescriptionLength, setCourseDescriptionLength] = useState(0);
  const [courseDuration, setCourseDuration] = useState(0);
  // const [currentAppId, setCurrentAppId] = useState('');
  const [modalCloseTrigger, setModalCloseTrigger] = useState(false);

  const [
    editCourse,
    {
      isLoading: isEditCourseLoading,
      isError: isEditCourseError,
      error: editCourseError,
      reset: resetEditingCourse,
    },
  ] = useUpdateCourseByIdMutation();

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [currentFile, setCurrentFile] = useState({
    file_type: 'link',
    file_name: '',
    file_s3_url: '',
  });
  const [currentLink, setCurrentLink] = useState({
    link_type: 'link',
    link_name: '',
    link_url: '',
  });
  const [allFiles, setAllFiles] = useState([]);
  const [allLinks, setAllLinks] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [duplicateLink, setDuplicateLink] = useState(false);
  const [fileSizeExceed, setFileSizeExceed] = useState(false);
  const [createCourseForApp, setCreateCourseForApp] = useState(false);
  const [createDiscussionForTraining, setCreateDiscussionForTraining] = useState(false);
  const [discussionGroupApiDone, setDiscussionGroupApiDone] = useState(false);
  const [selectedAppId, setSelectedAppId] = useState('');

  const fileInputRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [preSignedPath, setPreSignedPath] = useState('');
  const [newFileS3Url, setNewFileS3Url] = useState('');
  const [newFile, setNewFile] = useState(null);
  const [selectedUploadFileType, setSelectedUploadFileType] = useState('Link');
  const [selectedLinkFileType, setSelectedLinkFileType] = useState('Link');
  const { data } = useGetPresignedUrlQuery(preSignedPath, {
    skip: !preSignedPath,
  });
  const { data: allAppsData } = useFetchAllAppsQuery();
  const addLinkDisabled = Object.values(currentLink).some(
    (value) => value === '' || value === undefined,
  );
  const appList = allAppsData?.data[0]?.pinned_apps.concat(
    allAppsData?.data[0]?.your_other_apps,
  ) || [];
  const sortedAppList = sortArrOfObject(appList, 'asc', 'title');

  const modalUpdate = () => {
    setModalCloseTrigger((prev) => !prev);
    setClearState(true);
  };

  const onClose = () => {
    modalUpdate();
    onModalClose();
    resetEditingCourse();
  };

  useEffect(() => {
    if (isEditing && courseData) {
      if (courseData.app_id) {
        setCreateCourseForApp(true);
      } else {
        setCreateCourseForApp(false);
      }
      setCreateDiscussionForTraining(!!courseData.group_id);
      setCourseTitle(courseData.title);
      setCourseTitleLength(courseData.title.length);
      setCourseDescription(courseData.description);
      setCourseDescriptionLength(courseData.description?.length);
      setCourseDuration(courseData.duration);
      setTags(courseData.tags);
      setAllLinks(courseData.course_material.links);
      setAllFiles(courseData.course_material.files);
      setSelectedAppId(courseData.app_id);
      setShowFileUpload(false);
      setShowLinkInput(false);
      setCurrentLink({
        link_type: 'Link',
        link_name: '',
        link_url: '',
      });
      setCurrentFile({ ...currentFile, file_name: '', file_s3_url: '' });
    } else {
      setCourseTitle('');
      setCourseTitleLength(0);
      setCourseDescription('');
      setCourseDescriptionLength(0);
      setCourseDuration(0);
      setSelectedAppId('');
      setCreateDiscussionForTraining(false);
      // setCurrentAppId('');
      setCreateCourseForApp(false);
      setShowFileUpload(false);
      setShowLinkInput(false);
      setTags([]);
      setCurrentLink({
        link_type: 'Link',
        link_name: '',
        link_url: '',
      });
      setCurrentFile({ ...currentFile, file_name: '', file_s3_url: '' });
      setAllLinks([]);
      setAllFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData, isEditing, modalCloseTrigger]);

  const getFileTypeIcon = (fileType) => {
    const selectedIcon = fileDropDownOptions.filter(
      (option) => option.option_name.toLowerCase() === fileType.toLowerCase(),
    );
    return selectedIcon[0].option_icon;
  };

  const handleDeleteFile = (file_s3_url) => {
    const allFilesCopy = JSON.parse(JSON.stringify(allFiles));
    setAllFiles(
      allFilesCopy.filter((file) => file.file_s3_url !== file_s3_url),
    );
  };

  const handleAddLink = () => {
    const indexOfLink = allLinks.findIndex(
      (link) => link.link_url === currentLink.link_url,
    );
    if (indexOfLink === -1) {
      setAllLinks([
        ...allLinks,
        { ...currentLink, link_type: selectedLinkFileType },
      ]);
      setCurrentLink({
        link_type: 'Link',
        link_name: '',
        link_url: '',
      });
      setDuplicateLink(false);
    } else {
      setDuplicateLink(true);
    }
  };

  const handleRemoveLink = (link_url) => {
    const allLinksCopy = JSON.parse(JSON.stringify(allLinks));
    setAllLinks(allLinksCopy.filter((link) => link.link_url !== link_url));
    if (link_url === currentLink.link_url) {
      setDuplicateLink(false);
    }
  };

  const handleFileUpload = (event) => {
    const addedFile = event.target.files[0];
    const fileSizeInMB = addedFile.size / (1024 * 1024);
    const fileRequestType = 'upload';

    if (fileSizeInMB > 5) {
      setFileSizeExceed(true);
    } else if (fileSizeInMB < 5 && addedFile) {
      setFileSizeExceed(false);
      const fileNameWithTimeStamp = addTimeStamp(
        addedFile.name,
        addedFile.type,
      );
      const fileKey = `course_material/${reqUserId}/${fileNameWithTimeStamp}`;
      const fileUrl = `/courses/v1/files/?file_key=${fileKey}`;
      const preSignedUrlPath = `/courses/v1/files/?file_key=${fileKey}&type=${fileRequestType}`;
      setPreSignedPath(preSignedUrlPath);
      setNewFileS3Url(fileUrl);
      setNewFile(addedFile);
    }
  };

  const uploadFile = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': newFile.type,
        },
      };
      setFileUploading(true);
      await axios.put(data.signed_url, newFile, config);
      setAllFiles([
        ...allFiles,
        {
          file_name: currentFile.file_name || newFile.name,
          file_type: selectedUploadFileType,
          file_s3_url: newFileS3Url,
        },
      ]);
      setFileUploading(false);
      setSelectedUploadFileType('Link');
      setCurrentFile({ ...currentFile, file_name: '', file_s3_url: '' });
    } catch (uploadError) {
      console.log('Error uploading file:', uploadError);
    }
  };

  useEffect(() => {
    if (data && preSignedPath) {
      uploadFile();
      setPreSignedPath('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateForm = useCallback(() => {
    const isCourseForApp = !createCourseForApp || Boolean(selectedAppId);

    if (
      removeHTMLAndSpaces(courseTitle)
      && removeHTMLAndSpaces(courseDescription)
      && tags.length > 0
      && courseDuration
      && (allFiles.length > 0 || allLinks.length > 0)
      && isCourseForApp
      && courseTitleLength <= 64
      && courseDescriptionLength <= 560
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, max-len
  }, [
    courseTitle,
    courseDescription,
    tags,
    courseDuration,
    allLinks,
    allFiles,
    createCourseForApp,
    selectedAppId,
  ]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const [
    createCourse,
    {
      isLoading: isCreateCourseLoading,
      isError: isCreatingCourseError,
      error: creatingCourseError,
      reset: resetCreatingCourse,
    },
  ] = useCreateCourseMutation();
  const [
    createCourseGroup,
    {
      isLoading: isCreateCourseGroupLoading,
      isError: isCreatingCourseGroupError,
      error: creatingCourseGroupError,
      reset: resetCreatingCourseGroup,
    },
  ] = useCreateCourseGroupMutation();

  const [deleteGroup, { isLoading: isDeletingGroup }] = useDeleteGroupMutation();

  const handleCreateCourse = async () => {
    const newCourse = {
      app_id: selectedAppId,
      tags: sanitizeData(tags, 'arr_of_str'),
      title: sanitizeData(courseTitle),
      description: sanitizeData(courseDescription),
      duration: courseDuration,
      course_material: {
        files: allFiles,
        links: allLinks,
      },
    };

    if (!isEditing) {
      newCourse.author_id = sanitizeData(auth.user?.profile.sub);
    }

    if (!selectedAppId) {
      delete newCourse.app_id;
    }

    try {
      if (isEditing) {
        const res = await editCourse({
          course_id: courseData._id,
          courseData: newCourse,
        }).unwrap();

        if (!!courseData.group_id !== createDiscussionForTraining
          && !discussionGroupApiDone && res.data) {
          if (createDiscussionForTraining) {
            await createCourseGroup(courseData._id).unwrap();
          } else {
            await deleteGroup(courseData.group_id);
          }
          setDiscussionGroupApiDone(true);
        }
      } else {
        await createCourse({
          newCourseData: newCourse,
          create_group: createDiscussionForTraining,
        }).unwrap();
      }
      setCourseTitle('');
      setCourseTitleLength(0);
      setTags([]);
      setCourseDescription('');
      setCourseDescriptionLength(0);
      setCourseDuration(0);
      onClose();
    } catch (err) {
      console.error('Error creating or updating training:', err);
    }
  };

  useEffect(() => {
    if (clearState) {
      resetCreatingCourse();
      resetCreatingCourseGroup();
      resetEditingCourse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearState]);

  const getDiscussionMessage = () => {
    if (isEditing) {
      if (createDiscussionForTraining) {
        return 'Unchecking this box will remove the discussion from this training, including any current posts or replies.';
      }
      return 'Check this box if you want to create a discussion for this training.';
    }
    if (createDiscussionForTraining) {
      return 'If you uncheck this box, no discussion will be created for this training.';
    }
    return 'Check this box if you want to create a discussion for this training.';
  };

  return (
    <Box>
      <Button
        colorScheme="blue"
        bg="blue.500"
        onClick={onOpen}
        variant={variant}
        style={{
          ...theme.styles.global.button,
          fontWeight: '600',
          ...customBtnStyle,
        }}
        size={customSize}
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent
          maxWidth="757px"
          minHeight="580px"
          borderTop="8px solid"
          borderColor="blue.600"
          p={10}
        >
          <ModalCloseButton tabIndex={0} />
          <ModalHeader
            style={{ display: 'flex', minHeight: '80px', alignItems: 'center' }}
          >
            <Heading
              as="h3"
              mr={100}
              style={{ ...theme.styles.global.h3, fontWeight: 700 }}
            >
              {isEditing ? 'Edit Training' : 'Create Training'}
            </Heading>
            <Spacer />
            <VStack alignItems="end" width="50%">
              <Flex width="100%" justifyContent="flex-end">
                <Checkbox
                  width="14%"
                  minWidth="110px"
                  tabIndex={0}
                  isChecked={createCourseForApp}
                  sx={{
                    justifyContent: 'center',
                    fontWeight: '400',
                    fontSize: '1.5rem',
                    color: 'black',
                  }}
                  onChange={(e) => {
                    setCreateCourseForApp(e.target.checked);
                    if (!e.target.checked) {
                      setSelectedAppId('');
                    }
                  }}
                >
                  For Apps
                </Checkbox>

                {createCourseForApp && (
                  <Select
                    width="50%"
                    height="40px"
                    placeholder="Select App"
                    aria-label="Select an application this training is for."
                    onChange={(e) => setSelectedAppId(e.target.value)}
                    sx={{ padding: '0 12px' }}
                    value={selectedAppId}
                  >
                    {sortedAppList?.map((app) => {
                      const { _id, title } = app;
                      return (
                        <option value={_id} key={_id}>
                          {title}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </Flex>
            </VStack>
          </ModalHeader>
          <ModalBody>
            <form>
              <FormControl id="name" isRequired>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Training Title
                </FormLabel>
                <Input
                  value={parse(courseTitle)}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    setCourseTitle(inputText);
                    setCourseTitleLength(inputText.length);
                  }}
                  placeholder="The name of your training"
                  isInvalid={courseTitleLength > 64}
                />
                <MaxCharExceedError
                  message="Training name"
                  charCount={courseTitleLength}
                />
              </FormControl>
              <FormControl id="description" isRequired mt={4} mb={16}>
                <FormLabel
                  htmlFor="description-editor"
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Training Description
                </FormLabel>
                <QuillTextEditor
                  id="description-editor"
                  quillText={courseDescription}
                  setQuillText={setCourseDescription}
                  placeholder="Provide a brief description about the training"
                  toolBarType={2}
                  customStyle={{ margin: 0 }}
                  setQuillCharCount={setCourseDescriptionLength}
                  quillCharCount={courseDescriptionLength}
                />
              </FormControl>

              <FormControl id="courseDuration" isRequired mt={4}>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                  className="change-focus"
                >
                  Hours to Complete
                </FormLabel>
                <NumberInput
                  value={courseDuration}
                  onChange={(_, valueAsNumber) => {
                    const newDuration = Number.isNaN(valueAsNumber)
                      ? 0
                      : valueAsNumber;
                    setCourseDuration(newDuration);
                  }}
                  max={999}
                  min={0.25}
                  step={0.25}
                  maxWidth="8rem"
                  mb="1rem"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <TagsInput
                tags={tags}
                setTags={setTags}
                label="Add Tags"
                isRequired
              />

              <FormControl mt={4}>
                <Checkbox
                  isChecked={createDiscussionForTraining}
                  sx={{
                    fontWeight: '700',
                    fontSize: '1.5rem',
                    color: 'black',
                  }}
                  onChange={(e) => {
                    setCreateDiscussionForTraining(e.target.checked);
                  }}
                >
                  Add a Discussion to this Training
                </Checkbox>
                <Flex color="red.600" alignItems="flex-start" gap={2}>
                  <ReportProblemIcon sx={{ marginTop: '6px' }} />
                  <FormHelperText color="red.600" sx={{ fontSize: '1rem', fontStyle: 'normal' }}>
                    {getDiscussionMessage()}
                  </FormHelperText>
                </Flex>
              </FormControl>
              {/* Secondary Header */}
              <FormControl
                isRequired
                style={{
                  ...theme.styles.global.h4,
                }}
                mt={4}
              >
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Add Training Materials
                </FormLabel>
              </FormControl>
              <Text
                style={{
                  ...theme.styles.global.body,
                  fontWeight: '400',
                }}
                mt={2}
              >
                You can add videos, slides, pdfs, images, and other training
                materials up to 5 MB per file.
              </Text>
              <Text
                style={{
                  ...theme.styles.global.body,
                  fontWeight: '400',
                }}
                mt={2}
              >
                Video and other files larger than 5 MB must be stored on a
                website outside of the Exchange.
              </Text>
              {/* TODO: add correct href */}
              <VStack align="start" spacing={5}>
                {/* File Upload Section */}
                <FormControl id="fileUpload" isRequired mt={4}>
                  <Button
                    onClick={() => setShowFileUpload(true)}
                    variant="outline"
                    border="none"
                    size="md"
                    color="blue.500"
                    p={0}
                  >
                    + Upload a File up to 5 MB
                  </Button>
                  {showFileUpload && (
                    <Flex mb={6} pb={2} pt={2} gap="1rem" alignItems="center">
                      <DropdownWithIcons
                        menuOptions={fileDropDownOptions}
                        selectedOption={selectedUploadFileType}
                        setSelectedOption={setSelectedUploadFileType}
                      />
                      <Input
                        type="text"
                        placeholder="Display Name"
                        id="training_display_name"
                        minWidth="300px"
                        width="40%"
                        size="md"
                        p={2}
                        ml={6}
                        mr={8}
                        value={parse(currentFile.file_name)}
                        onChange={(e) => setCurrentFile({
                          ...currentFile,
                          file_name: e.target.value,
                        })}
                        isRequired
                      />
                      {fileUploading ? (
                        <Box style={{ minWidth: '110px', textAlign: 'center' }}>
                          <CircularProgress isIndeterminate size="40px" />
                        </Box>
                      ) : (
                        <>
                          <Button
                            onClick={() => fileInputRef.current.click()}
                            variant="outline"
                            border="1px solid #3D5EFC"
                            size="md"
                            color="blue.500"
                            mr={4}
                          >
                            Browse Files
                          </Button>
                          <Input
                            ref={fileInputRef}
                            type="file"
                            accept=".pdf, .doc, .docx, .txt, .ppt, .pptx, .xls, .xlsx, .jpg, .png, .mp4"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileUpload(e)}
                          />
                        </>
                      )}
                      <IconButton
                        aria-label="Delete this file"
                        onClick={() => setShowFileUpload(false)}
                        icon={<DeleteIcon cursor="pointer" />}
                      />
                    </Flex>
                  )}
                  {fileSizeExceed && (
                    <Text
                      style={{
                        fontWeight: '500',
                        color: 'red',
                        textAlign: 'center',
                      }}
                    >
                      Upload Error : File size should not be greater than 5 MB.
                    </Text>
                  )}
                  {allFiles.map((course) => {
                    const { file_name, file_type, file_s3_url } = course;

                    return (
                      <Flex key={file_s3_url} alignItems="center" pt={1} pb={1}>
                        <Flex
                          mr={6}
                          width="80px"
                          pl={3}
                          style={{ color: '#3D5EFC' }}
                        >
                          {getFileTypeIcon(
                            allFileTypes.includes(file_type)
                              ? file_type
                              : allFileTypes[3],
                          )}
                        </Flex>
                        <Text
                          minWidth="300px"
                          w="60%"
                          style={{ fontWeight: '500' }}
                        >
                          {file_name}
                        </Text>
                        <IconButton
                          aria-label="Delete this file"
                          onClick={() => handleDeleteFile(file_s3_url)}
                          icon={(
                            <DeleteIcon
                              cursor="pointer"
                              style={{ color: '#3D5EFC' }}
                            />
                          )}
                        />
                      </Flex>
                    );
                  })}
                </FormControl>

                {/* Link Section */}
                <FormControl id="linkUrl" isRequired mt={4}>
                  <Button
                    style={{ ...theme.styles.global.body }}
                    color="blue.500"
                    variant="outline"
                    border="none"
                    size="md"
                    onClick={() => setShowLinkInput(true)}
                    p={0}
                    isDisabled={allLinks.length >= 3}
                  >
                    + Add a Link for Videos or Files over 5 MB
                  </Button>
                  {showLinkInput && (
                    <Flex style={{ textAlign: 'center' }}>
                      <Text minW="80px" />
                      <Text
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                        }}
                        width="35%"
                      >
                        Display Name*
                      </Text>
                      <Text
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                        }}
                        width="35%"
                      >
                        File URL*
                      </Text>
                    </Flex>
                  )}
                  {showLinkInput && (
                    <>
                      <Flex alignItems="center" mt={2} mb={4} gap="1rem">
                        <DropdownWithIcons
                          menuOptions={fileDropDownOptions}
                          selectedOption={selectedLinkFileType}
                          setSelectedOption={setSelectedLinkFileType}
                        />
                        <Input
                          id="training_display_link_name"
                          value={parse(currentLink.link_name)}
                          onChange={(e) => {
                            setCurrentLink({
                              ...currentLink,
                              link_name: e.target.value.trim(),
                            });
                          }}
                          isRequired
                          placeholder="Enter the Display name"
                          width="35%"
                          mr={4}
                          ml={4}
                        />
                        <Input
                          id="training_display_url_name"
                          value={currentLink.link_url}
                          onChange={(e) => {
                            setCurrentLink({
                              ...currentLink,
                              link_url: e.target.value.trim(),
                            });
                          }}
                          placeholder="Enter the link URL"
                          width="35%"
                          mr={4}
                        />
                        <IconButton
                          aria-label="Save this link"
                          onClick={() => (addLinkDisabled ? null : handleAddLink())}
                          cursor={addLinkDisabled ? 'arrow' : 'pointer'}
                          width="40px"
                          icon={(
                            <SaveIcon
                              style={{
                                color: addLinkDisabled ? 'gray' : 'black',
                              }}
                            />
                          )}
                        />
                        <IconButton
                          aria-label="Delete this link"
                          onClick={() => setShowLinkInput(false)}
                          width="40px"
                          style={{ cursor: 'pointer' }}
                          icon={<DeleteIcon />}
                        />
                      </Flex>
                      {duplicateLink && (
                        <Text
                          style={{
                            fontWeight: '500',
                            color: 'red',
                            textAlign: 'center',
                          }}
                        >
                          The above link has already been added.
                        </Text>
                      )}
                    </>
                  )}
                  {allLinks.map((course) => {
                    const { link_name, link_type, link_url } = course;
                    return (
                      <Flex key={link_url} alignItems="center" pt={1} pb={1}>
                        <Flex
                          mr={6}
                          width="80px"
                          pl={4}
                          style={{ color: '#3D5EFC' }}
                        >
                          {getFileTypeIcon(
                            allFileTypes.includes(link_type)
                              ? link_type
                              : allFileTypes[3],
                          )}
                        </Flex>
                        <Text
                          as={Link}
                          href={formatLink(link_url)}
                          target="_blank"
                          minWidth="300px"
                          w="60%"
                          _hover={{ textDecoration: 'none' }}
                          style={{
                            fontWeight: '500',
                            color: '#3D5EFC',
                            marginRight: '1rem',
                          }}
                        >
                          {link_name}
                        </Text>
                        <IconButton
                          aria-label="Delete this link"
                          onClick={() => handleRemoveLink(link_url)}
                          icon={<DeleteIcon style={{ color: '#3D5EFC' }} />}
                        />
                      </Flex>
                    );
                  })}
                </FormControl>
              </VStack>
            </form>
          </ModalBody>

          <ModalFooter justifyContent="flex-start" mt="1rem" width="100%">
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
              w="5rem"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              onClick={() => handleCreateCourse()}
              size="sm"
              _hover={{ opacity: !isFormValid ? 0.4 : 1 }}
              isDisabled={!isFormValid}
              w="5rem"
            >
              Save
            </Button>
          </ModalFooter>
          {(isCreateCourseLoading || isEditCourseLoading || isCreateCourseGroupLoading) && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert
                status="loading"
                alert_title={
                  (isEditCourseLoading || isCreateCourseGroupLoading || isDeletingGroup)
                    ? 'Updating Training...'
                    : 'Creating Training...'
                }
              />
            </Box>
          )}
          {isCreatingCourseError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={creatingCourseError} />
            </Box>
          )}
          {isCreatingCourseGroupError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={creatingCourseGroupError} />
            </Box>
          )}
          {isEditCourseError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={editCourseError} />
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateCourseModal;
