import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { getInitialsFromName } from '../../utils/helpers';
import { AVATARSIZES, AVATARTEXTSIZES } from '../../const';

const avatarStyle = {
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  overflow: 'hidden',
  textAlign: 'center',
  fontWeight: 'normal',
};

const UserAvatar = ({
  name,
  image_url,
  customStyle = {},
  avatarSize = 'MD',
  icon = '',
}) => {
  if (icon) {
    return (
      <Flex
        style={{
          ...customStyle,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        mr={2}
      >
        {icon}
      </Flex>
    );
  }

  if (image_url) {
    return (
      <img
        src={image_url}
        aria-label={name}
        style={{
          ...customStyle,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
  }
  const initials = getInitialsFromName(name);
  return (
    <Box
      style={{
        ...avatarStyle,
        width: AVATARSIZES[avatarSize],
        height: AVATARSIZES[avatarSize],
        fontSize: AVATARTEXTSIZES[avatarSize],
        ...customStyle,
      }}
      backgroundColor="gray.300"
      fontSize={AVATARTEXTSIZES[avatarSize]}
      aria-label={initials}
    >
      {initials}
    </Box>
  );
};

export default UserAvatar;
