import React, { useEffect } from 'react';
import {
  VStack, useTheme, Box, Flex, Text,
} from '@chakra-ui/react';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import { useLocation, useNavigate } from 'react-router-dom';
import SectionHeader from './SectionHeader';
import SeeAllNavLink from '../NavMenu/SeeAllNavLink';
import { useFetchAllCoursesQuery } from '../../services/apis/coursesAPI';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { getRelativeTime } from '../../utils/helpers';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';

const MyTraining = ({ sectionTitle, noDataMessage = [] }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const accessibilityKeyHandler = useAccessibleNavigation();

  const myCoursesQueryObj = {
    page_number: 1,
    page_size: 3,
    sort_order: 'asc',
    sort_by: 'created_at',
    // no_app_id: true,
    pin: true,
    include_apps: true,
  };
  const {
    data: myCoursesData,
    isFetching: isMyCoursesFetching,
    isError: isMyCoursesError,
    error: MyCoursesError,
    refetch: refetchMyTraining,
  } = useFetchAllCoursesQuery(myCoursesQueryObj);

  useEffect(() => {
    refetchMyTraining();
  }, [location.pathname, refetchMyTraining]);

  const myTrainingComponent = () => {
    if (isMyCoursesFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Trainings Data..." />;
    if (isMyCoursesError) return <StatusAlert status="error" error={MyCoursesError} />;
    return (
      myCoursesData?.data?.length === 0
        ? <DataUnavailable display_message={noDataMessage} />
        : (
          <VStack
            style={{
              ...theme.styles.global.container,
            }}
            minHeight="4rem"
            padding="1rem"
            alignSelf="flex-end"
            spacing={2}
            alignItems="flex-start"
          >
            {
              myCoursesData?.data?.map((myCourse) => {
                const {
                  title, like_count, created_at, _id,
                } = myCourse;
                const navigatePath = `/training/${_id}`;

                return (
                  <Box
                    key={_id}
                    role="link"
                    tabIndex={0}
                    onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
                    style={{ borderBottom: '1px solid #CED8DD', marginBottom: '1rem' }}
                    pb="0.5rem"
                    width="100%"
                    cursor="pointer"
                    onClick={() => navigate(navigatePath)}
                  >
                    <Flex justify="space-between" width="100%">
                      <Box>
                        <Text
                          style={{
                            ...theme.styles.global.h4,
                            display: 'inline-block',
                            fontWeight: 700,
                          }}
                          maxWidth="200px"
                          whiteSpace="nowrap"
                          wordBreak="break-all"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          color="gray.800"
                        >
                          {parse(title)}
                        </Text>
                      </Box>
                      <Box
                        w="3rem"
                        style={{
                          textAlign: 'right',
                          ...theme.styles.global.body,
                          fontWeight: 'normal',
                          color: 'gray.600',
                        }}
                      >
                        {getRelativeTime(created_at)}
                      </Box>
                    </Flex>

                    <Flex width="100%" style={{ marginTop: 0 }}>
                      <Text variant="details">
                        {pluralize('like', like_count, true)}
                      </Text>
                    </Flex>
                  </Box>
                );
              })
}
            <SeeAllNavLink navigate_to="/training" display_message="See All" />
          </VStack>
        )
    );
  };

  return (
    <Box>
      <SectionHeader
        sectionTitle={sectionTitle}
      />
      {myTrainingComponent()}
    </Box>
  );
};

export default MyTraining;
