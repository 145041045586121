import React, { useEffect, useState } from 'react';
import {
  Button, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
  FormControl, FormLabel, Input, useTheme, Box,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import TagsInput from '../ElementaryComponents/TagsInput';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { useUpdatePostByIdMutation } from '../../services/apis/postsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';

const EditPostModal = ({
  buttonLabel, variant = 'solid', customBtnStyle = {}, postData,
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { post_id } = useParams();
  const [tags, setTags] = useState([]);
  const [postTitle, setPostTitle] = useState('');
  const [postTitleLength, setPostTitleLength] = useState(0);
  const [postDescription, setPostDescription] = useState('');
  const [postDescriptionLength, setPostDescriptionLength] = useState('');
  const isButtonDisabled = !removeHTMLAndSpaces(postTitle)
    || !removeHTMLAndSpaces(postDescription) || postDescriptionLength > 560 || postTitleLength > 64;

  const [updatePostById, {
    isLoading, isError, error,
  }] = useUpdatePostByIdMutation();

  useEffect(() => {
    if (postData) {
      setTags(postData.tags);
      setPostTitle(postData.title);
      setPostDescription(postData.description);
      setPostTitleLength(postData.title?.length);
      setPostDescriptionLength(postData.description?.length);
    }
  }, [postData]);

  const onClose = () => {
    // reset the form.
    setTags(postData?.tags ?? []);
    setPostTitle(postData?.title ?? '');
    setPostDescription(postData?.description ?? '');
    setPostTitleLength(postData.title?.length ?? 0);
    setPostDescriptionLength(postData.description?.length ?? 0);
    onModalClose();
  };

  const handleEditPost = async () => {
    const sanitizedUpdatedPost = {
      title: sanitizeData(postTitle),
      tags: sanitizeData(tags, 'arr_of,str'),
      description: sanitizeData(postDescription),
    };
    try {
      await updatePostById({ post_id, postData: sanitizedUpdatedPost }).unwrap();
      onClose();
    } catch (err) {
      console.log('Error, this is 400 bad request');
    }
  };

  return (
    <Box>
      <Button
        onClick={onOpen}
        colorScheme="blue"
        bg={variant === 'solid' ? 'blue.500' : 'white'}
        variant={variant}
        style={{ ...theme.styles.global.button, fontWeight: '600', ...customBtnStyle }}
        size="sm"
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="757px" minHeight="580px" borderTop="8px solid" borderColor="blue.600" style={{ ...theme.styles.components.modalStyle }}>
          <ModalHeader style={{ ...theme.styles.global.h3 }}>Edit Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="form">
            <FormControl id="name" mb="1rem">
              <FormLabel style={{
                ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
              }}
              >
                Post Title
              </FormLabel>
              <Input
                value={parse(postTitle)}
                onChange={(e) => {
                  const inputText = e.target.value;
                  setPostTitle(e.target.value);
                  setPostTitleLength(inputText?.length);
                }}
                isInvalid={postTitleLength > 64}
              />
              <MaxCharExceedError
                message="Post name"
                charCount={postTitleLength}
              />
            </FormControl>
            <TagsInput
              tags={tags}
              setTags={setTags}
              label="Add Tags"
            />
            <Box>
              <FormControl id="description" mt={4}>
                <QuillTextEditor
                  quillText={postDescription}
                  setQuillText={setPostDescription}
                  customStyle={{ margin: 0 }}
                  setQuillCharCount={setPostDescriptionLength}
                  quillCharCount={postDescriptionLength}
                />
                <FormLabel style={{
                  ...theme.styles.global.body, fontSize: '14px', margin: 0, marginBottom: '4px',
                }}
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter alignSelf="flex-start" mt="1rem" width="100%">
            <Button
              className="change-focus"
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
              isDisabled={isLoading}
              w="5rem"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              onClick={() => handleEditPost()}
              size="sm"
              isDisabled={isLoading || isButtonDisabled}
              w="5rem"
            >
              Save
            </Button>
          </ModalFooter>
          {
            isLoading && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="loading" alert_title="Updating Post..." />
              </Box>
            )
          }
          {
            (isError || error) && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Box>
            )
          }
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditPostModal;
