import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CircularProgress,
} from '@chakra-ui/react';

const parseError = (error) => {
  if (!error) return '';
  const errorFragments = [
    (''),
    error?.data?.message,
    error?.data?.err?.message,
  ];
  return errorFragments.filter((f) => f).join(' ');
};

const StatusAlert = ({
  status,
  alert_title = '',
  alert_description = '',
  error = '',
}) => {
  const isLoading = status === 'loading';
  let parsedError = error;

  if (Array.isArray(error)) {
    parsedError = error.map((e) => parseError(e)).join(', ');
  } else {
    parsedError = parseError(error);
  }

  return (
    <Alert status={isLoading ? 'info' : status} variant="subtle">
      {
        isLoading
          ? (
            <CircularProgress
              value={50}
              isIndeterminate
              size="24px"
              style={{ marginRight: '0.65rem' }}
            />
          )
          : <AlertIcon />
      }
      <AlertTitle>{alert_title.toUpperCase()}</AlertTitle>
      <AlertDescription>{alert_description}</AlertDescription>
      {
        error && (
          parsedError ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {parsedError}
            </>
          )
            : 'An unexpected error has occurred'
        )
      }
    </Alert>

  );
};

export default StatusAlert;
