import apiService from '../apiService';

const locationApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    searchLocations: builder.query({
      query: (searchTerm) => ({
        url: 'https://nominatim.openstreetmap.org/search',
        params: {
          q: searchTerm,
          format: 'json',
          addressdetails: 1,
          limit: 5,
          countrycodes: ['US'],
        },
      }),
    }),
    getZipCodeCoordinates: builder.query({
      query: (zipCode) => ({
        url: 'https://nominatim.openstreetmap.org/search',
        params: {
          postalcode: zipCode,
          format: 'json',
          addressdetails: 1,
          limit: 1,
          countrycodes: ['US'],
        },
      }),
      transformResponse: (response) => {
        const isValidZip = response && response.length > 0;
        if (isValidZip) {
          const { lat, lon } = response[0];
          return { lat, lon, isValidZip };
        }
        return { lat: null, lon: null, isValidZip };
      },
    }),
  }),
});

export const {
  useSearchLocationsQuery,
  useGetZipCodeCoordinatesQuery,
} = locationApi;
