/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useTheme, Flex } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { PushPinIcon } from '../../icons/index';
import {
  useCreatePinMutation,
  useDeletePinMutation,
} from '../../services/apis/pinsAPI';
import {
  useGetAppOrderQuery,
  useSetAppOrderMutation,
} from '../../services/apis/appsAPI';
import { moveObjectById } from '../../utils/arrayOperations';
import { setPinnedApps, setYourOtherApps } from '../../slices/appsSlice';

const TogglePinIcon = ({
  is_pinned, create_pin_body_obj, pin_id, customStyle = {}, is_app = false, object_id,
  updatePinState,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [createPin] = useCreatePinMutation();
  const [deletePin] = useDeletePinMutation();
  const pinnedApps = useSelector((state) => state.apps.pinnedApps);
  const yourOtherApps = useSelector((state) => state.apps.yourOtherApps);
  const pinnedAppsInitialState = _.cloneDeep(pinnedApps);
  const yourOtherAppsInitialState = _.cloneDeep(yourOtherApps);

  // Get app order so we can change it on pin or unpin
  const {
    data: appOrder = {},
  } = useGetAppOrderQuery({ order_type: 'user' });
  const appOrderIDs = appOrder?.data?.map((a) => a._id) ?? [];
  const [setAppOrder] = useSetAppOrderMutation();

  const updateAppsArray = (sourceArray, destinationArray, objectIdToMove, isCreatePin = false) => {
    const [updatedSourceArray, updatedDestinationArray] = moveObjectById(
      sourceArray,
      destinationArray,
      objectIdToMove,
    );

    if (isCreatePin) {
      dispatch(setPinnedApps(updatedDestinationArray));
      dispatch(setYourOtherApps(updatedSourceArray));
    } else {
      dispatch(setPinnedApps(updatedSourceArray));
      dispatch(setYourOtherApps(updatedDestinationArray));
    }

    return updatedDestinationArray;
  };
  const updateAppPinId = (updatedPinnedApps, appId, pinId) => {
    const pinnedAppsCopy = _.cloneDeep(updatedPinnedApps);
    const updatedpinnedAppsCopy = pinnedAppsCopy.map((app) => {
      if (app._id === appId) {
        const appCopyObj = _.cloneDeep(app);
        appCopyObj.pin_id = pinId;
        return { ...app, pin_id: pinId };
      }
      return app;
    });
    dispatch(setPinnedApps(updatedpinnedAppsCopy));
  };

  const handleCreatPin = async () => {
    let updatedPinnedApps;
    if (is_app) {
      updatedPinnedApps = updateAppsArray(yourOtherApps, pinnedApps, object_id, true);
    }
    try {
      const createdPin = await createPin(create_pin_body_obj);
      if (is_app) {
        updateAppPinId(updatedPinnedApps, object_id, createdPin.data.data._id);
      }
    } catch (error) {
      console.log(error);
    }
    if (updatePinState) updatePinState();
  };

  const handleDeletePin = async () => {
    if (is_app) {
      updateAppsArray(pinnedApps, yourOtherApps, object_id, false);
    }
    try {
      await deletePin(pin_id).unwrap();
    } catch (error) {
      if (is_app) {
        dispatch(setPinnedApps(pinnedAppsInitialState));
        dispatch(setYourOtherApps(yourOtherAppsInitialState));
      }
    }
    if (updatePinState) updatePinState();
  };

  const moveAppToEnd = async () => {
    if (is_app) {
      const newAppOrderIDs = [...appOrderIDs.filter((a) => a !== object_id), object_id];
      try {
        await setAppOrder({
          order_type: 'user',
          body: { app_order: newAppOrderIDs },
        }).unwrap();
      } catch (error) {
        console.log('Error', error);
      }
    }
  };

  const togglePin = () => {
    if (is_pinned) {
      handleDeletePin();
    } else {
      handleCreatPin();
    }
    moveAppToEnd();
  };

  const onClickPin = (e) => {
    e.stopPropagation();
    togglePin();
  };

  const onKeyDownOnPin = (e) => {
    e.stopPropagation();
    if (e?.key === 'Enter') {
      togglePin();
    }
  };

  return (
    <Flex
      style={{ minWidth: '44px', padding: '4px 0', ...customStyle }}
      justifyContent="center"
      onClick={(e) => onClickPin(e)}
      onKeyDown={(e) => onKeyDownOnPin(e)}
      role="button"
      tabIndex="0"
      aria-label="pin item"
    >
      {
        is_pinned ? (
          <PushPinIcon
            aria-label="Unpin this item"
            cursor="pointer"
            fontSize="small"
            sx={{ color: theme.colors.blue[500] }}
          />
        ) : (
          <PushPinIcon
            aria-label="Pin this item"
            cursor="pointer"
            fontSize="small"
            sx={{ transform: 'rotate(45deg)', width: '100%' }}
          />
        )
      }
    </Flex>
  );
};

export default TogglePinIcon;
