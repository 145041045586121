import React from 'react';
import {
  Box,
  Flex,
  Text,
  useTheme,
} from '@chakra-ui/react';
import AppSubsections from '../components/Sections/AppSubsections';
import AppSettingsModal from '../components/Modals/AppSettingsModal';

const AllApps = ({ guidelines_accepted }) => {
  const theme = useTheme();

  return (
    <Box w="full">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        <Text
          style={{
            ...theme.styles.global.h1,
            color: `${theme.colors.blue[800]}`,
            display: 'inline-block',
          }}
        >
          All Apps
        </Text>
        <AppSettingsModal
          buttonLabel="Create Event"
          title="My Modal"
        />
      </Flex>
      <Box>
        <AppSubsections
          subsection="pinned_apps"
          subsection_title="Pinned Apps"
          guidelines_accepted={guidelines_accepted}
          my_apps
          is_pinned
        />

        <AppSubsections
          subsection="your_other_apps"
          subsection_title="Your Other Apps"
          guidelines_accepted={guidelines_accepted}
          my_apps
          is_pinned={false}
        />

        <AppSubsections
          subsection="other_apps_on_exchange"
          subsection_title="Other Apps on the Exchange"
          my_apps={false}
        />
      </Box>
    </Box>
  );
};

export default AllApps;
