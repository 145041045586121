import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mockCreateReport, mockFetchReports, mockDeleteReport } from '../services/apis/mockReportsAPI';

export const createReport = createAsyncThunk('reports/createReport', mockCreateReport);
export const fetchReports = createAsyncThunk('reports/fetchReports', mockFetchReports);
export const deleteReport = createAsyncThunk('reports/deleteReport', mockDeleteReport);

const reportsSlice = createSlice({
  name: 'reports',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(createReport.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        const index = state.findIndex((report) => report.id === action.meta.arg);
        if (index > -1) {
          state.splice(index, 1);
        }
      });
  },
});

export default reportsSlice.reducer;
