import apiService from '../apiService';

export const pinsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllPins: builder.query({
      query: ({
        page_number, page_size, sort_order, sort_by,
      }) => ({
        url: '/pins/v1/all',
        params: {
          page_number, page_size, sort_order, sort_by,
        },
      }),
      providesTags: ['pins'],
    }),
    fetchPinByType: builder.query({
      query: (course_id) => `courses/v1/${course_id}`,
    }),
    deletePin: builder.mutation({
      query: (pin_id) => ({
        url: `pins/v1/${pin_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['groups', 'posts', 'apps', 'courses', 'updates', 'events'],
    }),
    createPin: builder.mutation({
      query: (newPinData) => ({
        url: 'pins/v1',
        method: 'POST',
        body: newPinData,
      }),
      invalidatesTags: ['groups', 'posts', 'apps', 'courses', 'updates', 'events'],
    }),
    createOnboardingPins: builder.mutation({
      async queryFn(args, queryAPI, extraOptions, baseQuery) {
        const {
          pinAppsIfAny = false,
          numAppsToPin = 3,
          pinGroupsIfAny = false,
          numGroupsToPin = 3,
        } = args ?? {};

        const allUnpinnedApps = await baseQuery({
          url: 'apps/v1',
        });
        if (allUnpinnedApps.error) return allUnpinnedApps;

        const {
          your_other_apps = [],
          pinned_apps = [],
        } = allUnpinnedApps?.data?.data?.[0] ?? {};

        let remainingAppsToPin = numAppsToPin - pinned_apps.length;
        if (pinned_apps.length && !pinAppsIfAny) remainingAppsToPin = 0;

        const appsToPin = your_other_apps
          .filter((a) => a.show_in_app_launcher)
          .slice(0, remainingAppsToPin)
          .map((a) => ({ child_object_id: a._id, child_object_type: 'app' }));

        const pinnedGroups = await baseQuery({
          url: '/groups/v1',
          params: {
            page_size: numGroupsToPin,
            page_number: 1,
            sort_order: 'desc',
            sort_by: 'created_at',
            pin: true,
          },
        });
        if (pinnedGroups.error) return pinnedGroups;
        const pinnedGroupCount = (pinnedGroups?.data?.data ?? []).length;

        const allUnpinnedGroups = await baseQuery({
          url: '/groups/v1',
          params: {
            page_size: numGroupsToPin,
            page_number: 1,
            sort_order: 'desc',
            sort_by: 'created_at',
            pin: false,
          },
        });
        if (allUnpinnedGroups.error) return allUnpinnedGroups;

        let remainingGroupsToPin = numGroupsToPin - pinnedGroupCount;
        if (pinnedGroupCount && !pinGroupsIfAny) remainingGroupsToPin = 0;

        const groupsToPin = (allUnpinnedGroups?.data?.data ?? [])
          .slice(0, remainingGroupsToPin)
          .map((g) => ({ child_object_id: g._id, child_object_type: 'group' }));

        const pinPromise = [...appsToPin, ...groupsToPin].map(async (newPinData) => {
          const result = await baseQuery({
            url: 'pins/v1',
            method: 'POST',
            body: newPinData,
          });
          return result;
        });
        const pinResults = await Promise.all(pinPromise);
        const pinError = pinResults.find((r) => r.error);
        if (pinError) return pinError;

        localStorage.setItem('OnboardPinsSet', 'True');

        return { data: pinResults };
      },
      invalidatesTags: ['groups', 'posts', 'apps', 'courses', 'updates'],
    }),
  }),
});

export const {
  useFetchAllPinsQuery,
  useFetchPinByTypeQuery,
  useDeletePinMutation,
  useCreatePinMutation,
  useCreateOnboardingPinsMutation,
} = pinsAPI;
