import React from 'react';
import { Text, Link, useTheme } from '@chakra-ui/react';

const ExternalLink = ({ link_title, link_url, iconComponent }) => {
  const theme = useTheme();
  return (
    <Link
      key={link_title}
      style={{
        width: 'fit-content',
      }}
      display="inline-flex"
      mt="9px"
      alignItems="center"
      href={link_url}
      target="_blank"
      _hover={{ textDecoration: 'none' }}
    >
      {iconComponent}
      <Text
        as="span"
        ml="9px"
        style={{
          ...theme.styles.global.body2,
          width: 'fit-content',
        }}
      >
        {link_title}
      </Text>
    </Link>
  );
};

export default ExternalLink;
