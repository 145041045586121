import { useTheme, Flex } from '@chakra-ui/react';
import React from 'react';
import UserAvatar from './UserAvatar';
import { LockIcon, BlockIcon } from '../../icons/index';

const FlaggedAuthorName = ({ author_name, author_banned, author_locked }) => {
  const theme = useTheme();
  return (
    <Flex
      style={{
        fontWeight: '700',
        color: author_banned ? theme.colors.red[700] : theme.colors.gray[500],
        fontFamily: 'Archivo Narrow',
        marginRight: '0.5rem',
      }}
    >
      <UserAvatar icon={author_banned ? <BlockIcon /> : <LockIcon />} />
      {author_banned && ' [This user was banned from this group]'}
      {author_locked && `${author_name} [Locked]`}
    </Flex>
  );
};

export default FlaggedAuthorName;
