import { extendTheme } from '@chakra-ui/react';
import { baseTheme } from '@texas-education-exchange/edx-portal-shared';
import chakra_ui_theme from '../styles/chakra_ui_theme';

export const theme = extendTheme({
  ...baseTheme,
  ...chakra_ui_theme,
});

export default theme;
